import { format, parse, startOfDay, endOfDay, differenceInDays, differenceInHours as diffInHours, differenceInMonths as diffInMonths } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'

import _ from 'lodash';

export const toFullDateTime = (value: Date | string) => {
  if (typeof value === 'string' && (!Date.parse(value) || value === '-')) return '-';
  return format(new Date(value), "dd MMM yyyy hh:mm:ss aa");
};

export const toFullDateTimeWithoutSec = (value: Date | string) => {
  if (typeof value === 'string' && (!Date.parse(value) || value === '-')) return '-';
  return format(new Date(value), "dd MMM yyyy, hh:mm aa");
};

export const toFullTimeDateWithoutSec = (value: Date | string) => {
  if (typeof value === 'string' && (!Date.parse(value) || value === '-')) return '-';
  return format(new Date(value), "hh:mm aa, dd MMM yyyy");
};

export const toDayMonthYearTime = (value: Date | string) => {
  if (!value || value === '-') return '-';
  return format(new Date(value), "dd MMM yyyy HH:mm:ss");
};

export const toTimeDayMonthYear = (value: Date | string) => {
  if (!value || value === '-') return '-';
  return format(new Date(value), "HH:mm:ss dd MMM yyyy");
};

export const toDayMonthYear = (value: Date | string) => {
  if (typeof value === 'string' && (!Date.parse(value) || value === '-')) return '-';
  return format(new Date(value), "dd MMM yyyy");
};

export const toDayMonth = (value: Date | string) => {
  if (typeof value === 'string' && (!Date.parse(value) || value === '-')) return '-';
  return format(new Date(value), "dd MMM");
};

export const toMonthYear = (value: Date | string) => {
  if (typeof value === 'string' && (!Date.parse(value) || value === '-')) return '-';
  return format(new Date(value), "MMM yyyy");
};

export const toMonth = (value: Date | string) => {
  if (typeof value === 'string' && (!Date.parse(value) || value === '-')) return '-';
  return format(new Date(value), "MMM");
};

export const toYearMonthDay = (value: Date | string) => {
  if (typeof value === 'string' && (!Date.parse(value) || value === '-')) return '-';
  return format(new Date(value), "yyyyMMdd");
};

export const toFullMonthYear = (value: Date | string) => {
  if (typeof value === 'string' && (!Date.parse(value) || value === '-')) return '-';
  return format(new Date(value), "MMMM yyyy");
};

export const parseYearMonthDay = (value: string) => {
  return parse(value, 'yyyyMMdd', new Date());
};

export const parseYearMonthDayTime = (value: string) => {
  return parse(value, 'yyyyMMdd HHmmss', new Date());
};

export const parseYearMonthDayTimeToDayMonthYearTime = (value: string) => {
  if (!value || value === '-') return '-';
  return toDayMonthYearTime(parseYearMonthDayTime(value))
};

export const parseYearMonthDayTimeToDayMonthYear = (value: string) => {
  if (!value || value === '-') return '-';
  return toDayMonthYear(parseYearMonthDayTime(value))
};

export const parseYearMonthDayToDayMonthYear = (value: string) => {
  if (!value || value === '-') return '-';
  return toDayMonthYear(parseYearMonthDay(value))
};

export const parseYearMonthDayToDayMonth = (value: string) => {
  if (!value || value === '-') return '-';
  return toDayMonth(parseYearMonthDay(value))
};

export const parseYearMonthDayToMonthYear = (value: string) => {
  if (!value || value === '-') return '-';
  return toMonthYear(parseYearMonthDay(value))
};

export const parseYearMonthDayToMonth = (value: string) => {
  if (!value || value === '-') return '-';
  return toMonth(parseYearMonthDay(value));
};

export const isInDateRange = (value: Date, min: Date, max: Date) => startOfDay(min) <= value && endOfDay(max) >= value;

export const isInTimeRange = (value: Date, min: Date, max: Date) => min <= value && max >= value;

export const differenceByDays = (dateA: Date | string, dateB: Date | string) => {
  if ((_.isString(dateA) && (!Date.parse(dateA) || dateA === '-')) || (_.isString(dateB) && (!Date.parse(dateB) || dateB === '-'))) {
    return "-";
  }
  return differenceInDays(new Date(dateA), new Date(dateB));
};

export const differenceInHours = (dateA: Date | string, dateB: Date | string) => {
  if ((_.isString(dateA) && (!Date.parse(dateA) || dateA === '-')) || (_.isString(dateB) && (!Date.parse(dateB) || dateB === '-'))) {
    return "-";
  }
  return diffInHours(new Date(dateA), new Date(dateB));
};

export const differenceInMonths = (dateA: Date | string, dateB: Date | string) => {
  if ((_.isString(dateA) && (!Date.parse(dateA) || dateA === '-')) || (_.isString(dateB) && (!Date.parse(dateB) || dateB === '-'))) {
    return "-";
  }
  return diffInMonths(new Date(dateA), new Date(dateB));
};

export const getMonthDifference = (dateA: Date | string, dateB: Date | string) => {
  if ((_.isString(dateA) && (!Date.parse(dateA) || dateA === '-')) || (_.isString(dateB) && (!Date.parse(dateB) || dateB === '-'))) {
    return "-";
  }
  return new Date(dateA).getMonth() - new Date(dateB).getMonth();
};

export const getMalaysiaDayOfMonth = () => utcToZonedTime(new Date(), "+08:00").getDate();

export const maximizeDay = (date:Date) => endOfDay(date);