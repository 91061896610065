// //default theme
interface ObjectLiteral  {
  [key: string]: string;
}

export const defaultTheme: ObjectLiteral  = { 
  'color-primary1': 'rgb(25, 38, 115)', //containers on white background, title of card on light background
  'color-primary2': 'rgba(25, 38, 115, 0.9)', //hover container on gradient background
  'color-primary3': 'rgba(25, 38, 115, 0.4)', //primary container on gradient background
  'color-primary4': 'rgb(22, 84, 204)', //hover buttons, text of card on light background
  'color-primary5': 'rgb(52, 115, 238)', //header titles, buttons, elements
  'color-primary6': 'rgba(52, 114, 239, 0.4)', //disabled elements
  'color-primary7': 'rgba(19, 35, 129, 0.7)', //card on dark background
  'color-primary8': 'rgb(16, 28, 104)',
  'color-secondary1': 'rgb(27, 225, 252)', //headers, highlights (hyperlinks, notifications etc..)
  'color-secondary2': 'rgba(27, 225, 252, 0.5)',
  'color-secondary3': 'rgba(27, 225, 252, 0.2)',
  'color-secondary4': 'rgb(119, 188, 238)', //navigation indicators
  'color-secondary5': 'rgba(119, 188, 238, 0.5)',
  'color-secondary6': 'rgb(239, 245, 254)', //secondary container on gradient background
  'color-secondary7': 'rgb(248, 252, 254)',
  'color-secondary8': 'rgb(201, 249, 255)',
  'color-secondary9': 'rgba(119, 188, 238, 0.2)',
  'color-neutral1': 'rgb(38, 38, 38)',//text on light background
  'color-neutral2': 'rgb(73, 73, 73)',//icons on light background
  'color-neutral3': 'rgb(146, 146, 146)', //microcopy on light background
  'color-neutral4': 'rgb(166, 166, 166)', //text background
  'color-neutral5': 'rgb(190, 190, 190)', //microcopy on light background
  'color-neutral6': 'rgb(229, 229, 229)', //disabled
  'color-neutral7': 'rgba(229, 229, 229, 0.5)', //text background
  'color-neutral8': 'rgb(255, 255, 255)', //text on dark background
  'color-neutral9': 'rgba(255, 255, 255, 0.5)', //microcopy on dark background
  'color-neutral10': 'rgba(255, 255, 255, 0.3)', //text background, disabled button
  'color-neutral11': 'rgba(255, 255, 255, 0.1)', //text background
  'color-neutral12': 'rgb(214, 229, 252)', //cards on white background
  'color-neutral13': 'rgba(38, 38, 38, 0.4)', //box-shadow color
  'color-neutral14': 'rgb(0, 0, 0)', //icon color
  'color-error-lightBackground': 'rgb(212, 2, 1)', //error states/messages on light background
  'color-error-lightBackground2': 'rgb(255, 80, 66)', //error states on light notification background
  'color-error-darkBackground': 'rgb(255, 105, 96)', //error states/messages on dark background
  'color-warning': 'rgb(242, 129, 23)', //warning states/messages
  'color-positive': 'rgb(32, 189, 76)', //positive states/messages
  'color-notification': 'rgb(255, 249, 194)', //notification background
  'color-notification-border': 'rgb(255, 211, 79)', //notification border
  'color-table-hover': 'rgb(248, 248, 248)', //hover row of table
  'color-chevron-icon': 'rgb(68, 111, 183)', //chevron icon color
  'color-dark-button': 'rgb(26, 52, 153)', //learn more page button
  'background-primaryGradient-0': 'rgb(52, 115, 238)', //primary gradient color's from
  'background-primaryGradient-100': 'rgb(24, 35, 137)', //primary gradient color's to
  'background-primaryGradient-vertical': 'linear-gradient(0deg, rgb(52, 115, 238) 0%, rgb(24, 35, 137) 100%)', //background
  'background-secondaryGradient-0': 'rgb(27, 225, 252)', //secondary gradient color's from
  'background-secondaryGradient-100': 'rgb(119, 188, 238)', //secondary gradient color's to
  'background-secondaryGradient-vertical': 'linear-gradient(0deg, rgb(27, 225, 252) 0%, rgb(119, 188, 238) 100%)' //highlight elements
};