import { Dispatch } from 'redux';
import * as UserService from 'src/services/sessionService';
import * as AdminUserService from 'src/services/admin/sessionService';
import { fetchStart, fetchSuccess, fetchError } from './fetchAction';

export const ACTION_NAME: string = 'SESSION_';

export const LOGIN_SESSION = `${ACTION_NAME}LOGIN_SESSION`;
export const LOGIN_SESSION_FAILED = `${ACTION_NAME}LOGIN_SESSION_FAILED`;
export const LOGIN_SESSION_RESET = `${ACTION_NAME}LOGIN_SESSION_RESET`;
export const loginSession = (username: string, password: string, invitationToken?: string) => {
  return (dispatch: Dispatch, getState: any) => {
    dispatch(fetchStart(LOGIN_SESSION))
    dispatch(fetchStart(LOGIN_SESSION_RESET))
    UserService.login(username, password, invitationToken)
      .then(response =>
        dispatch({
          type: LOGIN_SESSION,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(LOGIN_SESSION)))
      .catch((response) => {
        dispatch({
          type: LOGIN_SESSION_FAILED,
          payload: response
        })
        dispatch(fetchError(LOGIN_SESSION))
      });
  }
};

export const LOGOUT_SESSION = `${ACTION_NAME}LOGOUT_SESSION`;
export const logoutSession = (shouldRedirect?: boolean) => {
  return (dispatch: Dispatch, getState: any) => {
    const { sessionReducer: { refreshToken, isAdmin } } = getState();
    dispatch(fetchStart(LOGOUT_SESSION));
    if (isAdmin) {
      UserService.adminLogout(refreshToken)
        .then(response =>
          dispatch({
            type: LOGOUT_SESSION,
            payload: {
              ...response,
              shouldRedirect: shouldRedirect ?? true,
            }
          })
        )
        .then(() => dispatch(fetchSuccess(LOGOUT_SESSION)))
        .catch(() => dispatch(fetchError(LOGOUT_SESSION)));
    }
    else {
      UserService.logout(refreshToken)
        .then(response =>
          dispatch({
            type: LOGOUT_SESSION,
            payload: {
              ...response,
              shouldRedirect: shouldRedirect ?? true,
            }
          })
        )
        .then(() => dispatch(fetchSuccess(LOGOUT_SESSION)))
        .catch(() => dispatch(fetchError(LOGOUT_SESSION)));
    }
}
};

export const SET_USER_ROLES = `${ACTION_NAME}SET_USER_ROLES`;

export const REFRESH_SESSION = `${ACTION_NAME}REFRESH_SESSION`;
export const refreshSession = () => {
  return (dispatch: Dispatch, getState: any) => {
    const { sessionReducer: { refreshToken, isAdmin } } = getState();
    dispatch(fetchStart(REFRESH_SESSION))
    if (isAdmin) {
      UserService.adminRefreshSession(refreshToken)
        .then(response =>
          dispatch({
            type: REFRESH_SESSION,
            payload: response
          })
        )
        .then(() => dispatch(fetchSuccess(REFRESH_SESSION)))
        .catch(() => dispatch(fetchError(REFRESH_SESSION)));
    }
    else 
      UserService.refreshSession(refreshToken)
        .then(response =>
          dispatch({
            type: REFRESH_SESSION,
            payload: response
          })
        )
        .then(() => dispatch(fetchSuccess(REFRESH_SESSION)))
        .catch(() => dispatch(fetchError(REFRESH_SESSION)));
  }
};

export const CLEAR_SESSION = `${ACTION_NAME}CLEAR_SESSION`;
export const clearSession = () => {
  return (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: CLEAR_SESSION
    })
  }
};

export const adminLoginSession = (username: string, password: string) => {
  return (dispatch: Dispatch, getState: any) => {
    dispatch(fetchStart(LOGIN_SESSION))
    dispatch(fetchStart(LOGIN_SESSION_RESET))
    AdminUserService.login(username, password)
      .then(response =>
        dispatch({
          type: LOGIN_SESSION,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(LOGIN_SESSION)))
      .catch((response) => {
        dispatch({
          type: LOGIN_SESSION_FAILED,
          payload: response
        })
        dispatch(fetchError(LOGIN_SESSION))
      });
  }
};

export const FIRST_TIME_CHANGE_PASSWORD = `${ACTION_NAME}FIRST_TIME_CHANGE_PASSWORD`;
export const firstTimeChangePassword = (newPassword: string) => {
  return (dispatch: Dispatch, getState: any) => {
    dispatch(fetchStart(FIRST_TIME_CHANGE_PASSWORD))
    AdminUserService.firstTimeChangePassword(newPassword)
      .then(() => dispatch(fetchSuccess(FIRST_TIME_CHANGE_PASSWORD)))
      .catch(() => dispatch(fetchError(FIRST_TIME_CHANGE_PASSWORD)));
  }
};

export const SET_IS_PASSWORD_RESET_REQUIRED = `${ACTION_NAME}SET_IS_PASSWORD_RESET_REQUIRED`;
