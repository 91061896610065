import _ from "lodash";
import { Dispatch } from "redux";

import * as accountService from "src/services/accountService";
import * as adminAccountService from "src/services/admin/accountService";
import * as adminSessionService from "src/services/admin/sessionService";
import { fetchStart, fetchSuccess, fetchError } from "../fetchAction";
import * as sessionActions from '../sessionAction';
import * as accountActions from '../accountAction';
import { handleBlobFileDownload } from "src/helpers/downloadHelper";

export const ACTION_NAME: string = "ADMIN_ACCOUNT_";

export const GET_ADMIN_USER_PROFILE_FAILED = `${ACTION_NAME}GET_ADMIN_USER_PROFILE_FAILED`;
export const GET_ADMIN_USER_PROFILE = `${ACTION_NAME}GET_ADMIN_USER_PROFILE`;
export const getAdminUserProfile = (bulkAccountNo?: string) => {
  return (dispatch: Dispatch) => {
    if (!bulkAccountNo) { //Normal Admin Login
      dispatch(fetchStart(GET_ADMIN_USER_PROFILE));
      accountService.getUserProfile()
        .then((response) => {
          dispatch({
            type: GET_ADMIN_USER_PROFILE,
            payload: response,
          });
          dispatch({
            type: sessionActions.SET_USER_ROLES,
            payload: {
              scopes: _.get(response, 'scopes'),
              registrationStatusId: _.get(response, 'registrationStatusId',)
            },
          });
          dispatch({
            type: sessionActions.SET_IS_PASSWORD_RESET_REQUIRED,
            payload: _.get(response, 'isPasswordResetRequired'),
          });
        })
        .then(() => dispatch(fetchSuccess(GET_ADMIN_USER_PROFILE)))
        .catch(() => dispatch(fetchError(GET_ADMIN_USER_PROFILE)));
    }
    else {//Admin login with Impersonate Bulk Account No
      dispatch(fetchStart(GET_ADMIN_USER_PROFILE));
      adminSessionService.getUserProfileByBulkId(bulkAccountNo)
        .then((response) => {
          dispatch({
            type: accountActions.SET_USER_PROFILE_BY_ADMIN,
            payload: response,
          });
          accountActions._getCompanyNameForBulkId(dispatch, bulkAccountNo);
          accountActions._getBusinessTypeForBulkId(dispatch, bulkAccountNo);
        })
        .then(() => {
          dispatch({
            type: UPDATE_IMPERSONATE_BULK_ACCOUNT_NO,
            payload: bulkAccountNo,
          });
          dispatch(fetchSuccess(GET_ADMIN_USER_PROFILE));
        })
        .catch((response) => {
          dispatch({
            type: GET_ADMIN_USER_PROFILE_FAILED,
            payload: response,
          });
          dispatch(fetchError(GET_ADMIN_USER_PROFILE));
        });
    }
  };
};

export const UPDATE_IMPERSONATE_BULK_ACCOUNT_NO = `${ACTION_NAME}UPDATE_IMPERSONATE_BULK_ACCOUNT_NO`;
export const updateImpersonateBulkAccountNo = (impersonateBulkAccountNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_IMPERSONATE_BULK_ACCOUNT_NO,
      payload: impersonateBulkAccountNo,
    });
  };
};

export const SEARCH_ACCOUNT = `${ACTION_NAME}SEARCH_ACCOUNT`;
export const searchAccount = (searchBy: string, searchValue: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(SEARCH_ACCOUNT));
    adminAccountService.searchAccount(searchBy, searchValue, recordCount)
      .then(response =>
        dispatch({
          type: SEARCH_ACCOUNT,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(SEARCH_ACCOUNT)))
      .catch(() => dispatch(fetchError(SEARCH_ACCOUNT)));
  };
};

export const GET_BULK_ACCOUNTS_CARDS = `${ACTION_NAME}GET_BULK_ACCOUNTS_CARDS`;
export const getBulkAccountsCards = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_BULK_ACCOUNTS_CARDS));
    adminAccountService.getBulkAccountsCards()
      .then(response =>
        dispatch({
          type: GET_BULK_ACCOUNTS_CARDS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_BULK_ACCOUNTS_CARDS)))
      .catch(() => dispatch(fetchError(GET_BULK_ACCOUNTS_CARDS)));
  };
};

export const GET_BULK_ACCOUNTS_TABLE = `${ACTION_NAME}GET_BULK_ACCOUNTS_TABLE`;
export const getBulkAccountsTable = (recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_BULK_ACCOUNTS_TABLE));
    adminAccountService.getBulkAccountsTable(recordCount)
      .then(response =>
        dispatch({
          type: GET_BULK_ACCOUNTS_TABLE,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_BULK_ACCOUNTS_TABLE)))
      .catch(() => dispatch(fetchError(GET_BULK_ACCOUNTS_TABLE)));
  };
};

export const ADMIN_USER_TUTORIAL_COMPLETED = `${ACTION_NAME}ADMIN_USER_TUTORIAL_COMPLETED`;

export const GET_USERS = `${ACTION_NAME}GET_USERS`;
export const getUsers = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_USERS));
    adminAccountService.getUsers()
      .then((response) => {
        dispatch({
          type: GET_USERS,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_USERS)))
      .catch(() => dispatch(fetchError(GET_USERS)));
  };
};

export const GET_REGISTRATION_TABLE = `${ACTION_NAME}GET_REGISTRATION_TABLE`;
export const getRegistrationTable = (recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_REGISTRATION_TABLE));
    adminAccountService.getRegistrationTable(recordCount)
      .then((response) => {
        dispatch({
          type: GET_REGISTRATION_TABLE,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_REGISTRATION_TABLE)))
      .catch(() => dispatch(fetchError(GET_REGISTRATION_TABLE)));
  };
};

export const GET_CUSTOMER_REGISTRATION_DATA_FAILED = `${ACTION_NAME}GET_CUSTOMER_REGISTRATION_DATA_FAILED`;
export const GET_CUSTOMER_REGISTRATION_DATA = `${ACTION_NAME}GET_CUSTOMER_REGISTRATION_DATA`;
export const getCustomerRegistrationData = (registrationId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_CUSTOMER_REGISTRATION_DATA));
    adminAccountService.getCustomerRegistrationData(registrationId)
      .then((response) => {
        dispatch({
          type: GET_CUSTOMER_REGISTRATION_DATA,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_CUSTOMER_REGISTRATION_DATA)))
      .catch((response) => {
        dispatch({
          type: GET_CUSTOMER_REGISTRATION_DATA_FAILED,
          payload: response,
        });
        dispatch(fetchError(GET_CUSTOMER_REGISTRATION_DATA));
      });
  };
};

export const GET_CUSTOMER_REGISTRATION_RECORD = `${ACTION_NAME}GET_CUSTOMER_REGISTRATION_RECORD`;
export const getCustomerRegistrationRecord = (registrationId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_CUSTOMER_REGISTRATION_RECORD));
    adminAccountService.getCustomerRegistrationRecord(registrationId)
      .then((response) => {
        dispatch({
          type: GET_CUSTOMER_REGISTRATION_RECORD,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_CUSTOMER_REGISTRATION_RECORD)))
      .catch(() => dispatch(fetchError(GET_CUSTOMER_REGISTRATION_RECORD)));
  };
};

export const GET_REGISTRATION_ACTION_LOG_TABLE = `${ACTION_NAME}GET_REGISTRATION_ACTION_LOG_TABLE`;
export const getRegistrationActionLogTable = (registrationId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_REGISTRATION_ACTION_LOG_TABLE));
    adminAccountService.getRegistrationActionLogTable(registrationId)
      .then((response) => {
        dispatch({
          type: GET_REGISTRATION_ACTION_LOG_TABLE,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_REGISTRATION_ACTION_LOG_TABLE)))
      .catch(() => dispatch(fetchError(GET_REGISTRATION_ACTION_LOG_TABLE)));
  };
};

export const GET_ELECTRICITY_ACCOUNTS_CARD = `${ACTION_NAME}GET_ELECTRICITY_ACCOUNTS_CARD`;
export const getElectricityAccountsCard = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ELECTRICITY_ACCOUNTS_CARD));
    adminAccountService.getElectricityAccountsCard(bulkId)
      .then((response) => {
        dispatch({
          type: GET_ELECTRICITY_ACCOUNTS_CARD,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_ELECTRICITY_ACCOUNTS_CARD)))
      .catch(() => dispatch(fetchError(GET_ELECTRICITY_ACCOUNTS_CARD)));
  };
};

export const GET_PROFILE_FOR_BULK_ID = `${ACTION_NAME}GET_PROFILE_FOR_BULK_ID`;
export const getPofileForBulkId = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PROFILE_FOR_BULK_ID));
    accountService.getProfileForBulkId(bulkId)
      .then((response) => {
        dispatch({
          type: GET_PROFILE_FOR_BULK_ID,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_PROFILE_FOR_BULK_ID)))
      .catch(() => dispatch(fetchError(GET_PROFILE_FOR_BULK_ID)));
  };
};

export const EDIT_BULK_ACCOUNT_FAILED = `${ACTION_NAME}EDIT_BULK_ACCOUNT_FAILED`;
export const EDIT_BULK_ACCOUNT = `${ACTION_NAME}EDIT_BULK_ACCOUNT`;
export const editBulkAccount = (accountObject: object) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(EDIT_BULK_ACCOUNT));
    accountService.editBulkAccount(accountObject)
      .then((response) => {
        dispatch({
          type: EDIT_BULK_ACCOUNT,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(EDIT_BULK_ACCOUNT)))
      .catch((response) => {
        dispatch({
          type: EDIT_BULK_ACCOUNT_FAILED,
          payload: response,
        });
        dispatch(fetchError(EDIT_BULK_ACCOUNT));
      });
  };
};

export const GET_ACCOUNT_FOR_BULK_ID = `${ACTION_NAME}GET_ACCOUNT_FOR_BULK_ID`;
export const getAccountForBulkId = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ACCOUNT_FOR_BULK_ID));
    accountService.getAccountsForBulkId(bulkId)
      .then((response) => {
        dispatch({
          type: GET_ACCOUNT_FOR_BULK_ID,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_ACCOUNT_FOR_BULK_ID)))
      .catch(() => dispatch(fetchError(GET_ACCOUNT_FOR_BULK_ID)));
  };
};

export const REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT_FAILED = `${ACTION_NAME}REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT_FAILED`;
export const REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT = `${ACTION_NAME}REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT`;
export const removeChildAccountFromBulkAccount = (bulkId: string, accountNo: string[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT));
    accountService.removeChildAccountFromBulkAccount(bulkId, accountNo)
      .then(() => {
        dispatch({
          type: REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT,
          payload: accountNo.length,
        });
      })
      .then(() => dispatch(fetchSuccess(REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT)))
      .catch((response) => {
        dispatch({
          type: REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT_FAILED,
          payload: response,
        });
        dispatch(fetchError(REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT));
      });
  };
};

export const EDIT_ELECTRICITY_ACCOUNTS_FAILED = `${ACTION_NAME}EDIT_ELECTRICITY_ACCOUNTS_FAILED`;
export const EDIT_ELECTRICITY_ACCOUNTS = `${ACTION_NAME}EDIT_ELECTRICITY_ACCOUNTS`;
export const editElectricityAccounts = (bulkId: string, childAccountsObject: object[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(EDIT_ELECTRICITY_ACCOUNTS));
    accountService.editElectricityAccounts(bulkId, childAccountsObject)
    .then(() => dispatch(fetchSuccess(EDIT_ELECTRICITY_ACCOUNTS)))
    .catch((response) => {
      dispatch({
        type: EDIT_ELECTRICITY_ACCOUNTS_FAILED,
        payload: response,
      });
      dispatch(fetchError(EDIT_ELECTRICITY_ACCOUNTS));
    });
  };
};

export const GET_ELECTRICITY_ACCOUNT_DETAILS = `${ACTION_NAME}GET_ELECTRICITY_ACCOUNT_DETAILS`;
export const getElectricityAccountDetails = (accountNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ELECTRICITY_ACCOUNT_DETAILS));
    accountService.getElectricityAccountDetails(accountNo)
      .then((response) => {
        dispatch({
          type: GET_ELECTRICITY_ACCOUNT_DETAILS,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_ELECTRICITY_ACCOUNT_DETAILS)))
      .catch(() => dispatch(fetchError(GET_ELECTRICITY_ACCOUNT_DETAILS)));
  };
};

export const UPDATE_MANUAL_ACCOUNT_IN_ACCOUNT = `${ACTION_NAME}UPDATE_MANUAL_ACCOUNT_IN_ACCOUNT`;
export const updateManualAccountInAccount = (accounts: object[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_MANUAL_ACCOUNT_IN_ACCOUNT,
      payload: accounts,
    })
  };
};

export const CLEAR_MANUAL_ACCOUNTS_IN_ACCOUNT  = `${ACTION_NAME}CLEAR_MANUAL_ACCOUNTS_IN_ACCOUNT`;
export const clearManualAccountsInAccount = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_MANUAL_ACCOUNTS_IN_ACCOUNT,
    });
  }
};

export const CLEAR_AUTO_ACCOUNTS_IN_ACCOUNT  = `${ACTION_NAME}CLEAR_AUTO_ACCOUNTS_IN_ACCOUNT`;
export const clearAutoAccountsInAccount = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_AUTO_ACCOUNTS_IN_ACCOUNT,
    });
  }
};

export const SET_SELECTED_AUTO_DETECTED_ACCOUNT = `${ACTION_NAME}SET_SELECTED_AUTO_DETECTED_ACCOUNT`;
export const setSelectedAutoDetectedAccount = (accountNo: string, isSelect: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_AUTO_DETECTED_ACCOUNT,
      payload: {
        accountNo,
        isSelect: isSelect
      }
    })
  };
};

export const UPDATE_AUTO_DETECTED_TABLE  = `${ACTION_NAME}UPDATE_AUTO_DETECTED_TABLE`;
export const updateAutoDetectedTable = (accountNo: string, referenceId: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_AUTO_DETECTED_TABLE,
      payload: {
        accountNo,
        referenceId,
      }
    });
  }
};

export const UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT  = `${ACTION_NAME}UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT`;
export const uploadManualAccountsInAccount = (file: File) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT));
    accountService.uploadAddAccountExcel(file)
      .then((response) => {
        dispatch({
          type: UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT)))
      .catch(() => dispatch(fetchError(UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT)));
  };
};

export const DOWNLOAD_DECLARATION_FORM = `${ACTION_NAME}DOWNLOAD_DECLARATION_FORM`;
export const downloadDeclarationForm = (registrationId: string, fileName: string = 'Declaration Form.pdf') => {
  return (dispatch: Dispatch) => {
    adminAccountService.downloadDeclarationForm(registrationId)
      .then((response: any) => {
        handleBlobFileDownload(response, fileName);
      })
      .then(() => dispatch(fetchSuccess(DOWNLOAD_DECLARATION_FORM)))
      .catch(() => dispatch(fetchError(DOWNLOAD_DECLARATION_FORM)));
  };
};

export const UPDATE_BULK_REGISTRATION_STATUS_FAILED = `${ACTION_NAME}UPDATE_BULK_REGISTRATION_STATUS_FAILED`;
export const UPDATE_BULK_REGISTRATION_STATUS = `${ACTION_NAME}UPDATE_BULK_REGISTRATION_STATUS`;
export const updateBulkRegistrationStatus = (registrationId: string, remark: string, action: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPDATE_BULK_REGISTRATION_STATUS));
    adminAccountService.updateBulkRegistrationStatus(registrationId, remark, action)
      .then((response) => {
        dispatch({
          type: UPDATE_BULK_REGISTRATION_STATUS,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(UPDATE_BULK_REGISTRATION_STATUS)))
      .catch((response) => {
        dispatch({
          type: UPDATE_BULK_REGISTRATION_STATUS_FAILED,
          payload: response,
        });
        dispatch(fetchError(UPDATE_BULK_REGISTRATION_STATUS));
      });
  };
};

export const VALIDATE_BULK_CHILD_ACCOUNT  = `${ACTION_NAME}VALIDATE_BULK_CHILD_ACCOUNT`;
export const validateBulkChildAccount = (accountNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(VALIDATE_BULK_CHILD_ACCOUNT));
    accountService.validateBulkChildAccount(accountNo)
      .then((response) => {
        dispatch({
          type: VALIDATE_BULK_CHILD_ACCOUNT,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(VALIDATE_BULK_CHILD_ACCOUNT)))
      .catch(() => dispatch(fetchError(VALIDATE_BULK_CHILD_ACCOUNT)));
  };
};

export const ADD_ELECTRICITY_ACCOUNTS_FAILED = `${ACTION_NAME}ADD_ELECTRICITY_ACCOUNTS_FAILED`;
export const ADD_ELECTRICITY_ACCOUNTS = `${ACTION_NAME}ADD_ELECTRICITY_ACCOUNTS`;
export const addElectricityAccounts = (bulkId: string, childAccountsObject: object[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(ADD_ELECTRICITY_ACCOUNTS));
    accountService.addElectricityAccounts(bulkId, childAccountsObject)
      .then(() => {
        dispatch({
          type: ADD_ELECTRICITY_ACCOUNTS,
          payload: childAccountsObject.length,
        });
      })
      .then(() => dispatch(fetchSuccess(ADD_ELECTRICITY_ACCOUNTS)))
      .catch((response) => {
        dispatch({
          type: ADD_ELECTRICITY_ACCOUNTS_FAILED,
          payload: response,
        });
        dispatch(fetchError(ADD_ELECTRICITY_ACCOUNTS));
      });
  };
};

export const GET_PENDING_CA = `${ACTION_NAME}GET_PENDING_CA`;
export const getPendingCA = (bulkId: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PENDING_CA));
    accountService.getPendingCA(bulkId, recordCount)
      .then((response) => {
        dispatch({
          type: GET_PENDING_CA,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_PENDING_CA)))
      .catch(() => dispatch(fetchError(GET_PENDING_CA)));
  };
};

export const UPDATE_ERRORACCOUNT  = `${ACTION_NAME}UPDATE_ERRORACCOUNT`;
export const updateErrorAccount = (uniqueId: string, propertyName: string = "", propertyValue: string = "") => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ERRORACCOUNT,
      payload: {
        uniqueId,
        propertyName,
        propertyValue
      }
    });
  };
};

export const SET_SELECTED_ERRORACCOUNT = `${ACTION_NAME}SET_SELECTED_ERRORACCOUNT`;
export const setSelectedErrorAccount = (uniqueId: string, isSelect: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_ERRORACCOUNT,
      payload: {
        uniqueId,
        isSelect: isSelect
      }
    })
  };
};

export const REMOVE_ACCOUNT_FROM_PENDING_CA_FAILED = `${ACTION_NAME}REMOVE_ACCOUNT_FROM_PENDING_CA_FAILED`;
export const REMOVE_ACCOUNT_FROM_PENDING_CA = `${ACTION_NAME}REMOVE_ACCOUNT_FROM_PENDING_CA`;
export const removeAccountFromPendingCA = (bulkId: string, selectedAccounts: string[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(REMOVE_ACCOUNT_FROM_PENDING_CA));
    accountService.removeAccountFromPendingCA(bulkId, selectedAccounts)
      .then(() => dispatch(fetchSuccess(REMOVE_ACCOUNT_FROM_PENDING_CA)))
      .catch((response) => {
        dispatch({
          type: REMOVE_ACCOUNT_FROM_PENDING_CA_FAILED,
          payload: response,
        });
        dispatch(fetchError(REMOVE_ACCOUNT_FROM_PENDING_CA));
      });
  };
};

export const SUBMIT_PENDING_CA_FAILED = `${ACTION_NAME}SUBMIT_PENDING_CA_FAILED`;
export const SUBMIT_PENDING_CA = `${ACTION_NAME}SUBMIT_PENDING_CA`;
export const submitPendingCA = (bulkId: string, selectedAccounts: object[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(SUBMIT_PENDING_CA));
    accountService.submitPendingCA(bulkId, selectedAccounts)
      .then(() => {
        dispatch({
          type: SUBMIT_PENDING_CA,
          payload: selectedAccounts.length,
        });
      })
      .then(() => dispatch(fetchSuccess(SUBMIT_PENDING_CA)))
      .catch((response) => {
        dispatch({
          type: SUBMIT_PENDING_CA_FAILED,
          payload: response,
        });
        dispatch(fetchError(SUBMIT_PENDING_CA));
      });
  };
};

export const VALIDATE_BULK_CA_IN_PENDING_CA  = `${ACTION_NAME}VALIDATE_BULK_CA_IN_PENDING_CA`;
export const validateBulkCAInPendingCA = (accountNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(VALIDATE_BULK_CA_IN_PENDING_CA));
    accountService.validateBulkChildAccount(accountNo)
      .then((response) => {
        dispatch({
          type: VALIDATE_BULK_CA_IN_PENDING_CA,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(VALIDATE_BULK_CA_IN_PENDING_CA)))
      .catch(() => dispatch(fetchError(VALIDATE_BULK_CA_IN_PENDING_CA)));
  };
};

export const GET_AUTO_SUGGESTIVE_IN_ACCOUNT = `${ACTION_NAME}GET_AUTO_SUGGESTIVE_IN_ACCOUNT`;
export const getAutoSuggestiveInAccount = (roc: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_AUTO_SUGGESTIVE_IN_ACCOUNT));
    accountService.getAutoSuggestiveAccounts(roc)
      .then((response) => {
        dispatch({
          type: GET_AUTO_SUGGESTIVE_IN_ACCOUNT,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_AUTO_SUGGESTIVE_IN_ACCOUNT)))
      .catch(() => dispatch(fetchSuccess(GET_AUTO_SUGGESTIVE_IN_ACCOUNT)));
  };
};

export const UPDATE_USER_PROFILE_FAILED = `${ACTION_NAME}UPDATE_USER_PROFILE_FAILED`;
export const UPDATE_USER_PROFILE = `${ACTION_NAME}UPDATE_USER_PROFILE`;
export const updateUserProfile = (name: string, mobileNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPDATE_USER_PROFILE))
    accountService.updateUserProfile(name, mobileNo)
      .then(() => dispatch(fetchSuccess(UPDATE_USER_PROFILE)))
      .catch((response) => {
        dispatch({
          type: UPDATE_USER_PROFILE_FAILED,
          payload: response,
        });
        dispatch(fetchError(UPDATE_USER_PROFILE));
      });
  };
};

export const GET_BULKCHILDACCOUNTERROR = `${ACTION_NAME}GET_BULKCHILDACCOUNTERROR`;
export const getBulkChildAccountError = (recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_BULKCHILDACCOUNTERROR));
    adminAccountService.getBulkChildAccountError(recordCount)
      .then((response) => {
        dispatch({
          type: GET_BULKCHILDACCOUNTERROR,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_BULKCHILDACCOUNTERROR)))
      .catch(() => dispatch(fetchError(GET_BULKCHILDACCOUNTERROR)));
  };
};