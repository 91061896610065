
//#region System Roles

//#region ADMIN
const ADMIN_CUSTOMER_REGISTRATION_VIEW: string = 'Admin.ManageBulkAccounts.Add';
const ADMIN_CUSTOMER_REGISTRATION_EDIT: string = 'Admin.ManageBulkAccounts.Add';
const ADMIN_BULK_ACCOUNTS_CUSTOMER_VIEW_MODE_VIEW: string = 'Admin.BulkAccounts.CustomerViewMode.View';
const ADMIN_BULK_ACCOUNTS_MANAGE_BULK_ACCOUNTS_VIEW: string = 'Admin.ManageBulkAccounts.View';
const ADMIN_BULK_ACCOUNTS_MANAGE_BULK_ACCOUNTS_EDIT: string = 'Admin.ManageBulkAccounts.Update';
const ADMIN_BULK_ACCOUNTS_MANAGE_BULK_ACCOUNTS_ADD: string = 'Admin.ManageBulkAccounts.Add';
const ADMIN_BULK_ACCOUNTS_MANAGE_BULK_ACCOUNTS_DELETE: string = 'Admin.ManageBulkAccounts.Delete';
const ADMIN_BULK_ACCOUNTS_SEARCH_ELECTRICTY_ACCOUNT_VIEW: string = 'Admin.BulkAccounts.SearchElectricityAccounts.View';
const ADMIN_PAYMENTS_PENDING_APPROVAL_VIEW: string = 'Admin.Payments.PendingApproval.View';
const ADMIN_PAYMENTS_PENDING_APPROVAL_EDIT: string = 'Admin.Payments.PendingApproval.Update';
const ADMIN_PAYMENTS_ALL_PAYMENTS_VIEW: string = 'Admin.Payments.AllPayments.View';
const ADMIN_MANAGE_USERS_VIEW: string = 'Admin.ManageTNBUsers.View';
const ADMIN_MANAGE_USERS_EDIT: string = 'Admin.ManageTNBUsers.Update';
const ADMIN_MANAGE_USERS_ADD: string = 'Admin.ManageTNBUsers.Add';
const ADMIN_MANAGE_USERS_DELETE: string = 'Admin.ManageTNBUsers.Delete';
const ADMIN_MANAGE_ADMINS_VIEW: string = 'Admin.ManageTNBAdmins.View';
const ADMIN_MANAGE_ADMINS_EDIT: string = 'Admin.ManageTNBAdmins.Update';
const ADMIN_MANAGE_ADMINS_ADD: string = 'Admin.ManageTNBAdmins.Add';
const ADMIN_MANAGE_ADMINS_DELETE: string = 'Admin.ManageTNBAdmins.Delete';
const ADMIN_FPX_MANUAL_REQUERY_VIEW: string = 'Admin.FpxManualRequery.View';
const ADMIN_FPX_MANUAL_REQUERY_UPDATE: string = 'Admin.FpxManualRequery.Update';
const ADMIN_BULKCAERROR_VIEW:string = 'Admin.BulkChildAccountError.View';
//#endregion ADMIN

//#region USER
const USER_BILL_VIEW: string = 'User.Bill.View';
const USER_BILL_UPDATE: string = 'User.Bill.Update';
const USER_BILL_DELETE: string = 'User.Bill.Delete';
const USER_BILL_ADD: string = 'User.Bill.Add';
const USER_PAYMENT_VIEW: string = 'User.Payment.View';
const USER_PAYMENT_UPDATE: string = 'User.Payment.Update';
const USER_PAYMENT_DELETE: string = 'User.Payment.Delete';
const USER_PAYMENT_ADD: string = 'User.Payment.Add';
const USER_ACCOUNT_VIEW: string = 'User.Account.View';
const USER_ACCOUNT_UPDATE: string = 'User.Account.Update';
const USER_ACCOUNT_DELETE: string = 'User.Account.Delete';
const USER_ACCOUNT_ADD: string = 'User.Account.Add';
const USER_MANAGE_USER_VIEW: string = 'User.ManageUser.View';
const USER_MANAGE_USER_UPDATE: string = 'User.ManageUser.Update';
const USER_MANAGE_USER_DELETE: string = 'User.ManageUser.Delete';
const USER_MANAGE_USER_ADD: string = 'User.ManageUser.Add';
//#endregion USER
//#endregion of System Roles

export const ACCESS_ROLES = {
  ADMIN: {
    OVERALL_VIEW: [
      ADMIN_CUSTOMER_REGISTRATION_VIEW,
      ADMIN_BULK_ACCOUNTS_CUSTOMER_VIEW_MODE_VIEW,
      ADMIN_BULK_ACCOUNTS_MANAGE_BULK_ACCOUNTS_VIEW,
      ADMIN_BULK_ACCOUNTS_SEARCH_ELECTRICTY_ACCOUNT_VIEW,
      ADMIN_PAYMENTS_PENDING_APPROVAL_VIEW,
      ADMIN_PAYMENTS_ALL_PAYMENTS_VIEW,
      ADMIN_MANAGE_USERS_VIEW,
      ADMIN_MANAGE_ADMINS_VIEW,
      ADMIN_FPX_MANUAL_REQUERY_VIEW,
      ADMIN_BULKCAERROR_VIEW,

    ],
    CUSTOMER_REGISTRATION: {
      OVERALL_VIEW: [ADMIN_CUSTOMER_REGISTRATION_VIEW],
      OVERALL_EDIT: [ADMIN_CUSTOMER_REGISTRATION_EDIT],
    },
    BULK_ACCOUNS: {
      OVERALL_VIEW: [
        ADMIN_BULK_ACCOUNTS_CUSTOMER_VIEW_MODE_VIEW,
        ADMIN_BULK_ACCOUNTS_MANAGE_BULK_ACCOUNTS_VIEW,
        ADMIN_BULK_ACCOUNTS_SEARCH_ELECTRICTY_ACCOUNT_VIEW,
      ],
      CUSTOMER_VIEW_MODE_VIEW: [ADMIN_BULK_ACCOUNTS_CUSTOMER_VIEW_MODE_VIEW],
      MANAGE_BULK_ACCOUNTS_VIEW: [ADMIN_BULK_ACCOUNTS_MANAGE_BULK_ACCOUNTS_VIEW],
      MANAGE_BULK_ACCOUNTS_EDIT: [
        ADMIN_BULK_ACCOUNTS_MANAGE_BULK_ACCOUNTS_EDIT,
        ADMIN_BULK_ACCOUNTS_MANAGE_BULK_ACCOUNTS_ADD,
        ADMIN_BULK_ACCOUNTS_MANAGE_BULK_ACCOUNTS_DELETE],
      SEARCH_ELECTRICITY_VIEW: [ADMIN_BULK_ACCOUNTS_SEARCH_ELECTRICTY_ACCOUNT_VIEW],
    },
    PAYMENTS: {
      OVERALL_VIEW: [
        ADMIN_PAYMENTS_PENDING_APPROVAL_VIEW,
        ADMIN_PAYMENTS_ALL_PAYMENTS_VIEW,
      ],
      PENDING_APPROVAL_VIEW: [ADMIN_PAYMENTS_PENDING_APPROVAL_VIEW],
      PENDING_APPROVAL_EDIT: [ADMIN_PAYMENTS_PENDING_APPROVAL_EDIT],
      ALL_PAYMENTS_VIEW: [ADMIN_PAYMENTS_ALL_PAYMENTS_VIEW],
    },
    MANAGER_TNB_USERS: {
      OVERALL_VIEW: [ADMIN_MANAGE_USERS_VIEW],
      OVERALL_EDIT: [
        ADMIN_MANAGE_USERS_EDIT,
        ADMIN_MANAGE_USERS_ADD,
        ADMIN_MANAGE_USERS_DELETE
      ],
    },
    MANAGER_TNB_ADMINS: {
      OVERALL_VIEW: [ADMIN_MANAGE_ADMINS_VIEW],
      OVERALL_EDIT: [
        ADMIN_MANAGE_ADMINS_EDIT,
        ADMIN_MANAGE_ADMINS_ADD,
        ADMIN_MANAGE_ADMINS_DELETE
      ],
    },
    BULKCAERROR: {
      OVERALL_VIEW: [ADMIN_BULKCAERROR_VIEW]
    },
    FPX_MANUAL_REQUERY: {
      OVERALL_VIEW: [ADMIN_FPX_MANUAL_REQUERY_VIEW],
      OVERALL_UPDATE: [ADMIN_FPX_MANUAL_REQUERY_UPDATE],
    }
  },
  USER: {
    OVERALL_VIEW: [
      USER_BILL_VIEW,
      USER_PAYMENT_VIEW,
      USER_ACCOUNT_VIEW,
      USER_MANAGE_USER_VIEW
    ],
    BILL_PAYMENT_VIEW: [
      USER_BILL_VIEW,
      USER_PAYMENT_VIEW,
    ],
    BILL: {
      VIEW: [USER_BILL_VIEW],
      UPDATE: [USER_BILL_UPDATE],
      DELETE: [USER_BILL_DELETE],
      ADD: [USER_BILL_ADD]
    },
    PAYMENT: {
      VIEW: [USER_PAYMENT_VIEW],
      UPDATE: [USER_PAYMENT_UPDATE],
      DELETE: [USER_PAYMENT_DELETE],
      ADD: [USER_PAYMENT_ADD]
    },
    ACCOUNT: {
      VIEW: [USER_ACCOUNT_VIEW],
      UPDATE: [USER_ACCOUNT_UPDATE],
      DELETE: [USER_ACCOUNT_DELETE],
      ADD: [USER_ACCOUNT_ADD]
    },
    MANAGE_USER: {
      VIEW: [USER_MANAGE_USER_VIEW],
      UPDATE: [USER_MANAGE_USER_UPDATE],
      DELETE: [USER_MANAGE_USER_DELETE],
      ADD: [USER_MANAGE_USER_ADD]
    }
  }
};
