import React, { useState, useEffect, useMemo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { createBrowserHistory } from "history";
//Components
import { TimeoutModal } from 'src/components/modals/timeoutModal';
import CommonModal from 'src/components/modals/commonModal';
//Redux
import * as sessionActions from 'src/redux/actions/sessionAction';
import { useStateWithPaths } from 'src/hooks/useConnect';
import { useFetchSuccessEffect, useFetchErrorEffect } from 'src/hooks/useFetchState';

//handle refresh user session
const SESSION_TIMEOUT_IN_MINUTES = 15;
const SESSION_TIMEOUT_COUNTER_IN_MINUTES = 1;
const SESSION_TIMEOUT_PROMPT_IN_MS = 1000 * 60 * (SESSION_TIMEOUT_IN_MINUTES - SESSION_TIMEOUT_COUNTER_IN_MINUTES);
const REFRESH_ACCESS_TOKEN_IN_MS = 1000 * 60 * 2.25;//2 mins 15 seconds

const UserSessionHandler: React.FC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [showTimeoutWarningModal, setShowTimeoutWarningModal] = useState<boolean>(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState<boolean>(false);

  const [userAccessToken, isAdmin, impersonateBulkAccountNo] = useStateWithPaths([
    'sessionReducer.accessToken',
    'sessionReducer.isAdmin',
    'adminAccountReducer.impersonateBulkAccountNo'
  ]);
  const isUserLogon = useMemo(() => !_.isEmpty(userAccessToken), [userAccessToken]);
  const adminImpersonateBulkAccountNo = impersonateBulkAccountNo || searchParams.get("qsImpersonateBulkAccountNo");
  const isCVM = !!adminImpersonateBulkAccountNo; // is customer view mode

  const handleOnIdle = (event: any) => {
    if (!isUserLogon || showTimeoutWarningModal || showTimeoutModal)
      return;

    const isInActiveMoreThanTimeoutTime = moment(getLastActiveTime()).add(SESSION_TIMEOUT_IN_MINUTES, 'minute').isBefore(moment());
    if (isInActiveMoreThanTimeoutTime) {
      dispatch(sessionActions.logoutSession(false));
      setShowTimeoutModal(true);
    }
    else {
      //to be removed
      setShowTimeoutWarningModal(true);
    }
  };

  const sessionTimeoutCallback = () => {
    setShowTimeoutWarningModal(false);
    dispatch(sessionActions.logoutSession(false));
    setShowTimeoutModal(true);
  }

  const onClickLoggedOut = () => {
    let history = createBrowserHistory();
    history.replace(isAdmin || isCVM ? '/admin/login' : '/');
    window.location.reload();
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: SESSION_TIMEOUT_PROMPT_IN_MS,
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: {
      type: 'localStorage',
      emitOnAllTabs: true
    },
  });

  useEffect(() => {
    dispatch(sessionActions.refreshSession());
  }, [dispatch]);

  useFetchSuccessEffect(() => {
    if (!(showTimeoutModal || !isUserLogon || getRemainingTime() <= 0)) {
      setTimeout(() => {
        if (showTimeoutModal || !isUserLogon || getRemainingTime() <= 0)
          return;

        dispatch(sessionActions.refreshSession())

      }, REFRESH_ACCESS_TOKEN_IN_MS)
    }

    return true;
  }, sessionActions.REFRESH_SESSION);

  useFetchErrorEffect(() => {
    if (!(showTimeoutModal || !isUserLogon || getRemainingTime() <= 0)) {
      setTimeout(() => {
        if (showTimeoutModal || !isUserLogon || getRemainingTime() <= 0)
          return;

        dispatch(sessionActions.refreshSession())

      }, REFRESH_ACCESS_TOKEN_IN_MS)
    }

    return true;
  }, sessionActions.REFRESH_SESSION);

  return (
    <>
      <TimeoutModal
        allowedIdleMinutes={SESSION_TIMEOUT_IN_MINUTES}
        leftTimeoutMinutes={SESSION_TIMEOUT_COUNTER_IN_MINUTES}
        showModal={showTimeoutWarningModal}
        setShowModal={setShowTimeoutWarningModal}
        timeoutCallback={() => sessionTimeoutCallback()}
        buttons={[
          {
            actionName: "refresh",
            label: t('components.timeoutModal.stayLogggedIn'),
            callback: () => {
              setShowTimeoutWarningModal(false);
            },
            mode: "primary",
          },
        ]}
      />
      <CommonModal
        show={showTimeoutModal}
        setShow={setShowTimeoutModal}
        modalTitle={t("components.timeoutModal.stayLogggedIn")}
        iconType="warning"
        modalDescription={t("components.timeoutModal.youHaveBeenLoggedOut")}
        buttons={[
          {
            label: t("components.timeoutModal.ok"),
            mode: "primary",
            callback: () => onClickLoggedOut(),
          },
        ]}
      />
    </>
  )
}

export default UserSessionHandler;