import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { setDefaultTranslations, setDefaultLanguage } from 'react-multi-lang';
import { Spinner } from "react-bootstrap";

import 'src/assets/scss/main.scss';
import { ROUTES } from './routes';
import { ReduxProvider } from 'src/redux/redux';

import en from "src/languages/en.json";
import ms from "src/languages/ms.json";

const App = () => {

  const AppRoutes = () => {
    return useRoutes(ROUTES);
  }

  useEffect(() => {
    setDefaultTranslations({ en, ms });
    setDefaultLanguage('en');
  }, []);

  return (
    <ReduxProvider>
      <div className="app">
        <BrowserRouter>
          <Suspense fallback={<div className='pad-left-0_5 pad-top-0_5'><Spinner animation="border" /></div>}>
            <AppRoutes />
          </Suspense>
        </BrowserRouter>
      </div>
    </ReduxProvider>
  );
}

export default App;
