import FileSaver from 'file-saver';

export const handleBlobFileDownload = (response: any, fileName: string): void => {
  // const url = window.URL.createObjectURL(new Blob([response]));
  // const link = document.createElement('a');
  // link.href = url;
  // link.setAttribute('download', fileName); //or any other extension
  // document.body.appendChild(link);
  // link.click();

  const fileType = getFileTypeByFileExtension(fileName);
  const blob = new Blob([response], { type: fileType });
  FileSaver.saveAs(blob, fileName, { autoBom: true });
}

const getFileTypeByFileExtension = (fileName: string) => {
  const fileSplitArray = fileName.split('.');
  const fileExtension = fileSplitArray && fileSplitArray.length > 1 ? fileSplitArray[1] : '';

  switch (fileExtension) {
    case 'pdf':
      return 'application/pdf';
    case 'zip':
      return 'application/zip';//octet-stream;charset=UTF-8';//'application/zip';
    case 'xls':
    case 'xlsx':
    case 'csv':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    default:
      return ''
  }
}