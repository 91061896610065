import React from "react";
import { useTranslation } from "react-multi-lang";
import _ from "lodash";

import Button from "./../button";
import Modals from "./../modals";
import Notification from "./../notification";

import { ReactComponent as EXCLAMATION_ICON } from "src/assets/images/icons/icon-exclamation.svg";

interface IButton {
  label: string;
  callback: () => void;
  mode: "primary" | "secondary" | "ghost";
  colorTheme?: "light" | "dark";
  size?: "small" | "large";
  className?: string;
  hasLoadingSpinner?: boolean;
  disabled?: boolean;
  title?: string;
}

interface ICommonModal {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  showModalErrorMessage?: boolean;
  setShowModalErrorMessage?: React.Dispatch<React.SetStateAction<boolean>>;
  modalTitle: string;
  modalDescription: string | JSX.Element | JSX.Element[];
  buttons?: Array<IButton>;
  iconType?: "warning" | "positive" | JSX.Element;
  imageCenter?: string;
  imageWidth?: string;
  customClassName?: string;
  closeButton?: boolean;
}

const CommonModal = ({
  show,
  setShow,
  showModalErrorMessage,
  setShowModalErrorMessage,
  modalTitle,
  modalDescription,
  buttons,
  closeButton,
  iconType = undefined,
  imageCenter = undefined,
  imageWidth = undefined,
  customClassName = "",
}: ICommonModal) => {
  const t = useTranslation();

  const onClickValueHandler = (button: any) => {
    button.callback && button.callback();
  };

  const customIcon = _.isString(iconType) ? (
    <EXCLAMATION_ICON className={`commonModal__icon commonModal__icon--${iconType}`} />
  ) : iconType;

  const renderButtons = () => {
    let buttonsToRender = buttons;
    return (
      buttonsToRender && (
        <div className="commonModal__buttonContainer flex justify-center">
          {_.map(buttonsToRender, (button, i) => {
            return (
              <div
                className={`commonModal__buttons ${i !== 0 ? "commonModal__secondBtn" : ""} ${button.className}`}
                key={i}
              >
                <Button
                  label={button.label}
                  size={button.size ?? "large"}
                  mode={button.mode}
                  colorTheme={button.colorTheme ?? "light"}
                  onClick={() => onClickValueHandler(button)}
                  hasLoadingSpinner={button.hasLoadingSpinner ?? false}
                  disabled={button.disabled}
                  title={button.title}
                />
              </div>
            );
          })}
        </div>
      )
    );
  };

  return (
    <Modals
      show={show}
      setShow={setShow}
      closeButton={closeButton}
      modalsSize="large"
      modalsTitle={modalTitle}
      verticalCenter={true}
      customModalImage={customIcon}
      imageCenter={imageCenter}
      imageWidth={imageWidth}
      customClassName={customClassName}
      childBody={
        <>
          {!_.isUndefined(showModalErrorMessage) && setShowModalErrorMessage && (
            <div className="commonModal__notification">
              <Notification
                show={showModalErrorMessage}
                setShow={setShowModalErrorMessage}
                icon="exclamation"
                textValue={t("common.systemError")}
                closeButton={true}
                isOverlay={true}
                overlayTop={-135}
              />
            </div>
          )}
          <div className="pad-bottom-1_5">{modalDescription}</div>
          {renderButtons()}
        </>
      }
    />
  );
};

export default CommonModal;
