import { B2B_API_INSTANCE } from "./index";

const SERVICE_VERSION: number = 1;
const CONTROLLER_NAME: string = `/user-svc/api/v${SERVICE_VERSION}/User`;
const ADMIN_CONTROLLER_NAME: string = `/user-svc/api/v${SERVICE_VERSION}/Admin`;

export const login = (username: string, password: string, invitationToken?: string) => {
  const postBody = {
    username,
    password,
    invitationToken,
  };
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/Login`, postBody);
};

export const logout = (refreshToken: string) => {
  const postBody = {
    "refreshToken": refreshToken
  };
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/Logout`, postBody);
};

export const refreshSession = (refreshToken: string) => {
  const postBody = {
    "refreshToken": refreshToken
  };
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/RefreshToken`, postBody);
};

// admin session
export const adminLogout = (refreshToken: string) => {
  return B2B_API_INSTANCE.post(`${ADMIN_CONTROLLER_NAME}/Logout`, { refreshToken });
};

export const adminRefreshSession = (refreshToken: string) => {
  return B2B_API_INSTANCE.post(`${ADMIN_CONTROLLER_NAME}/RefreshToken`, { refreshToken });
};
