import { B2B_API_INSTANCE } from "../index";

const SERVICE_VERSION: number = 1;
const ADMIN_CONTROLLER_NAME: string = `/user-svc/api/v${SERVICE_VERSION}/Admin`;

export const login = (username: string, password: string) => {
  const postBody = {
    username,
    password,
  };
  return B2B_API_INSTANCE.post(`${ADMIN_CONTROLLER_NAME}/Login`, postBody);
};

export const getUserProfileByBulkId = (bulkAcccountNo: string) => {
  return B2B_API_INSTANCE.get(`${ADMIN_CONTROLLER_NAME}/CustomerProfile?bulkId=${bulkAcccountNo}`);
};

export const firstTimeChangePassword = (newPassword: string) => {
  return B2B_API_INSTANCE.patch(`${ADMIN_CONTROLLER_NAME}/FirstTimeChangePassword`, { newPassword });
};
