import _ from "lodash";

export const RegistrationUserTypeName = {
  BULK_CUSTOMER: "bulkCustomer",
  BUSINESS_COMMERCE: "businessCommerce", //TO BE CONTINUE
  CONTRACTOR: "contractor", //TO BE CONTINUE
  DEVELOPER: "developer", //TO BE CONTINUE
}

export const RegistrationUserType = {
  bulkCustomer: {
    id: 17,
    key: RegistrationUserTypeName.BULK_CUSTOMER,
    value: "modules.admin.customerRegistration.viewDetails.userTypeList.bulkCustomer",
  },
};

export const RegistrationUserTypeAsKey = _.mapKeys(RegistrationUserType, (value:any) => value.key);

export const getRegistrationUserType = (RegistrationUserTypeId: number) => {
  if (!RegistrationUserTypeId) return "-";
  if (RegistrationUserTypeId === RegistrationUserTypeAsKey[RegistrationUserTypeName.BULK_CUSTOMER].id)
    return RegistrationUserTypeName.BULK_CUSTOMER;
  return "-";
};
