import _ from "lodash";

export const REGISTRATION_STATUS_NAME = {
  UNREGISTERED: "unregistered",
  PENDING_APPROVAL: "pendingApproval",
  APPROVED: "approved",
  ACCEPTED_WITH_ERROR: "acceptedWithError",
  REJECTED: "rejected",
  REQUEST_FOR_INFO: "requestForInfo",
  POST_PROCESSING: "postProcessing"
}

export const REGISTRATION_STATUS_TYPE = {
  unregistered: {
    backendCodes: [1],
    backendStatus: ["unregistered"],
    name: REGISTRATION_STATUS_NAME.UNREGISTERED,
    label: "components.registrationStatus.unregistered",
  },
  pendingApproval: {
    backendCodes: [2],
    backendStatus: ["pendingApproval"],
    name: REGISTRATION_STATUS_NAME.PENDING_APPROVAL,
    label: "components.registrationStatus.pendingApproval",
  },
  acceptedWithError: {
    backendCodes: [6],
    backendStatus: ["acceptedWithError"],
    name: REGISTRATION_STATUS_NAME.ACCEPTED_WITH_ERROR,
    label: "components.registrationStatus.approved",
  },
  approved: {
    backendCodes: [7],
    backendStatus: ["successfullyRegistered"],
    name: REGISTRATION_STATUS_NAME.APPROVED,
    label: "components.registrationStatus.approved",
  },
  rejected: {
    backendCodes: [3],
    backendStatus: ["rejectedByAdmin"],
    name: REGISTRATION_STATUS_NAME.REJECTED,
    label: "components.registrationStatus.rejected",
  },
  requestForInfo: {
    backendCodes: [4],
    backendStatus: ["actionRequired"],
    name: REGISTRATION_STATUS_NAME.REQUEST_FOR_INFO,
    label: "components.registrationStatus.requestForInfo",
  },
  postProcessing: {
    backendCodes: [5],
    backendStatus: ["bcrmProcessing"],
    name: REGISTRATION_STATUS_NAME.POST_PROCESSING,
    label: "components.registrationStatus.postProcessing",
  },
};

export const REGISTRATION_STATUS_AS_NAME = _.mapKeys(REGISTRATION_STATUS_TYPE, (value: any) => value.name);

export const getRegistrationStatus = (status: number) => {
  if (!status) return "-";
  if (_.includes(REGISTRATION_STATUS_TYPE.pendingApproval.backendCodes, status))
    return REGISTRATION_STATUS_NAME.PENDING_APPROVAL;
  else if (_.includes(REGISTRATION_STATUS_TYPE.approved.backendCodes, status))
    return REGISTRATION_STATUS_NAME.APPROVED;
  else if (_.includes(REGISTRATION_STATUS_TYPE.rejected.backendCodes, status))
    return REGISTRATION_STATUS_NAME.REJECTED;
  else if (_.includes(REGISTRATION_STATUS_TYPE.requestForInfo.backendCodes, status))
    return REGISTRATION_STATUS_NAME.REQUEST_FOR_INFO;
  else if (_.includes(REGISTRATION_STATUS_TYPE.postProcessing.backendCodes, status))
    return REGISTRATION_STATUS_NAME.POST_PROCESSING;
  else if (_.includes(REGISTRATION_STATUS_TYPE.acceptedWithError.backendCodes, status))
    return REGISTRATION_STATUS_NAME.ACCEPTED_WITH_ERROR;
  else if (_.includes(REGISTRATION_STATUS_TYPE.unregistered.backendCodes, status))
    return REGISTRATION_STATUS_NAME.UNREGISTERED;
  return "-";
};
