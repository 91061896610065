import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useStateWithPaths } from './useConnect';
import { fetchReset } from '../redux/actions/fetchAction';
import { isSuccess, isFetching, isError } from '../redux/reducers/fetchReducer';

export const useFetchStart = (id: string) => {
  const [state] = useStateWithPaths(['fetchReducer']);
  return isFetching({ state, id });
};

export const useFetchSuccess = (id: string) => {
  const [state] = useStateWithPaths(['fetchReducer']);
  return isSuccess({ state, id });
};

export const useFetchError = (id: string) => {
  const [state] = useStateWithPaths(['fetchReducer']);
  return isError({ state, id });
};

export const useFetchSuccessEffect = (callback: () => any, id: string) => {
  const success: boolean = useFetchSuccess(id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (success) {
      const reset = callback();
      if (reset) {
        dispatch(fetchReset(id));
      }
    }
  }, [success, callback, dispatch, id]);
};

export const useFetchErrorEffect = (callback: () => any, id: string) => {
  const error = useFetchError(id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (error) {
      const reset = callback();
      if (reset) {
        dispatch(fetchReset(id));
      }
    }
  }, [error, callback, dispatch, id]);
};

export const useFetchEffect = (callback: (props: any) => any, id: string) => {
  const { success, fetching, error } = useFetchState(id);
  useEffect(() => {
    callback({ success, fetching, error });
  }, [success, fetching, error, callback]);
};

const useFetchState = (id: string) => {
  const success = useFetchSuccess(id);
  const fetching = useFetchStart(id);
  const error = useFetchError(id);
  return {
    success,
    fetching,
    error
  };
};

export default useFetchState