export const FETCH_START: string = '@@redux-fetch/FETCH_START'
export const FETCH_SUCCESS: string = '@@redux-fetch/FETCH_SUCCESS'
export const FETCH_ERROR: string = '@@redux-fetch/FETCH_ERROR'
export const FETCH_RESET: string = '@@redux-fetch/FETCH_RESET'

interface IFetchResponse {
  type: string,
  payload: string,
  error?: string | object,
}

export const fetchStart = (id: string): IFetchResponse => ({
  type: FETCH_START,
  payload: id
})

export const fetchSuccess = (id: string): IFetchResponse => ({
  type: FETCH_SUCCESS,
  payload: id
})

export const fetchError = (id: string, error?: string | object): IFetchResponse => ({
  type: FETCH_ERROR,
  payload: id,
  error
})

export const fetchReset = (id: string): IFetchResponse => ({
  type: FETCH_RESET,
  payload: id
})
