import _ from 'lodash';
import * as logActions from 'src/redux/actions/logAction';
import { IReducerAction, IReducer, IPayloadObj } from './_commonInterface';

interface ILogReducer {
  liteNotifications: {
    unreadCount: number;
    items: object[];
  },
  notifications: object[];
  noMoreNotification: boolean;
  activityLogs: object[];
  noMoreActivityLog: boolean;
}

const defaultState: ILogReducer = {
  liteNotifications: {
    unreadCount: 0,
    items: []
  },
  notifications: [],
  noMoreNotification: false,
  activityLogs: [],
  noMoreActivityLog: false,
}

const REDUCERS: IReducer = {
  [logActions.GET_NOTIFICATIONS]: (state: ILogReducer, { payload }: IPayloadObj) => {
    const { lastId, items } = payload;
    const processedItems = _.map(items ?? [], (d:any) => ({
      ...d,
      createdDate: new Date(d.createdDate + "Z")
    }));
    
    return {
      ...state,
      notifications: lastId ? _.concat(state.notifications, processedItems): processedItems,
      noMoreNotification: lastId && processedItems.length === 0 
    }
  },
  [logActions.READ_NOTIFICATIONS]: (state: ILogReducer, { payload }: IPayloadObj) => {
    _.forEach(state.notifications, (n:any) => {
      if (_.findIndex(payload, p => n.id === p) > -1) {
        n.hasRead = true;
      }
    });
    
    return {
      ...state,
      notifications: state.notifications
    }
  },
  [logActions.DELETE_NOTIFICATIONS]: (state: ILogReducer, { payload }: IPayloadObj) => {
    const list = _.filter(state.notifications, (n:any) => _.findIndex(payload, p => n.id === p) < 0);
    
    return {
      ...state,
      notifications: list
    }
  },
  [logActions.GET_LITENOTIFICATIONS]: (state: ILogReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      liteNotifications: {
        unreadCount: _.get(payload, "unreadCount", 0),
        items: _.map(payload.notifications ?? [], (d:any) => ({
          createdDate: new Date(d.createdDate + "Z"),
          hasRead: d.hasRead,
          content: d.title,
          id: d.id,
        }))
      }
    }
  },
  [logActions.GET_ACTIVITYLOGS]: (state: ILogReducer, { payload }: IPayloadObj) => {
    const { lastId, items } = payload;
    const processedItems = _.map(items ?? [], (d:any) => ({
      ...d,
      createdDate: new Date(d.createdDate + "Z")
    }));
    
    return {
      ...state,
      activityLogs: lastId ? _.concat(state.activityLogs, processedItems): processedItems,
      noMoreActivityLog: lastId && processedItems.length === 0 
    }
  },
};

const logReducer = (state: ILogReducer = defaultState, { type, payload, error }: IReducerAction = { type: '' }) => {
  if (!type)
    return state;

  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
}
export default logReducer;
export const REDUCER_LOG = 'logReducer';
