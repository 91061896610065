import { B2B_API_INSTANCE, B2B_API_UNOBSTRUSIVE_INSTANCE } from "./index";

const SERVICE_VERSION: number = 1;

const NOTIFICATION_CONTROLLER_NAME: string = `/notification-svc/api/v${SERVICE_VERSION}/NotificationLog`;
const ACTIVITYLOG_CONTROLLER_NAME: string = `/notification-svc/api/v${SERVICE_VERSION}/ActivityLog`;

export const getNotifications = (startDate: string, endDate: string, transactionNo?: string, accountNo?: string, lastId?: string, requestCount?: number) => {
  const postBody = {
    lastId,
    requestCount,
    startDate,
    endDate,
    filter: {
      transactionNo,
      accountNo
    }
  };

  return B2B_API_INSTANCE.post(`${NOTIFICATION_CONTROLLER_NAME}/GetNotificationLogs`, postBody);
};

export const readNotifications = (ids: number[]) => {
  return B2B_API_INSTANCE.post(`${NOTIFICATION_CONTROLLER_NAME}/ReadNotificationLogs`, { list: ids });
};

export const deleteNotifications = (ids: number[]) => {
  return B2B_API_INSTANCE.post(`${NOTIFICATION_CONTROLLER_NAME}/DeleteNotificationLogs`, { list: ids });
};

export const getLiteNotifications = (count: number) => {
  return B2B_API_UNOBSTRUSIVE_INSTANCE.get(`${NOTIFICATION_CONTROLLER_NAME}/GetLiteNotificationLogs/${count}`);
};

export const getActivityLogs = (startDate: string, endDate: string, transactionNo?: string, username?: string, lastId?: string, requestCount: number = 100) => {
  const postBody = {
    lastId,
    requestCount,
    startDate,
    endDate,
    filter: {
      transactionNo,
      username
    }
  };

  return B2B_API_INSTANCE.post(`${ACTIVITYLOG_CONTROLLER_NAME}/GetActivityLogs`, postBody);
};