import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ACCESS_ROLES } from 'src/constants/userRoles';
import WithAuthRole from 'src/helpers/hoc/withAuthRole';
import WithSessionHandler from 'src/helpers/hoc/withSessionHandler';

//#region Pages
import UnauthorizedPage from '../views/_common/unauthorized';
const AppMasterLayout = React.lazy(() => import('../views/_layout/appMasterLayout'));
const MasterLogonLayout = React.lazy(() => import('../views/_layout/masterLogonLayout'));
//Public
const LearnMorePage = React.lazy(() => import('../views/public/learnMore'));
//User
const MasterUserLayout = React.lazy(() => import('../views/_layout/user/masterUserLayout'));
const NotFoundPage = React.lazy(() => import('../views/_common/notFound'));
const Login = React.lazy(() => import('../views/user/account/login/login'));
const ForgotPassword = React.lazy(() => import('../views/user/account/forgotPassword/forgotPassword'));
const ForgotPasswordSent = React.lazy(() => import('../views/user/account/forgotPasswordSent/forgotPasswordSent'));
const ResetPassword = React.lazy(() => import('../views/user/account/resetPassword/resetPassword'));
const ChangePassword = React.lazy(() => import('../views/user/account/changePassword/changePassword'));
const AccountRegister = React.lazy(() => import('../views/user/account/register'));
const AccountRegisterDetails = React.lazy(() => import('../views/user/account/register/registerDetails'));
const ActivateAccount = React.lazy(() => import('../views/user/account/register/activateAccount'));
const ExpiredLink = React.lazy(() => import('../views/user/account/register/activateAccount/expiredLink'));
const ActivateLinkSent = React.lazy(() => import('../views/user/account/register/activateAccount/linkSent'));
const HomeUser = React.lazy(() => import('../views/user/home/index'));
const BillPayment = React.lazy(() => import('../views/user/billPayment/index'));
const ManageAccount = React.lazy(() => import('../views/user/manageAccount/index'));
const Profile = React.lazy(() => import('../views/user/account/profile/profile'));
const EditProfile = React.lazy(() => import('../views/user/account/profile/editProfile'));
const ActivityLog = React.lazy(() => import('../views/user/account/profile/activityLog'));
const TransferMasterRoleSuccess = React.lazy(() => import('../views/user/account/profile/transferMasterRoleModal/success'));
const Notifications = React.lazy(() => import('../views/user/account/notifications/notifications'));
const BulkAccount = React.lazy(() => import('../views/user/bulkAccount'));
const UserManagement = React.lazy(() => import('../views/user/manageUsers'));
//Admin
const MasterAdminLayout = React.lazy(() => import('../views/_layout/admin/masterAdminLayout'));
const AdminLogin = React.lazy(() => import('../views/admin/account/login/index'));
const AdminFirstTimePasswordReset = React.lazy(() => import('../views/admin/account/firstTimePasswordReset/index'));
const AdminForgotPassword = React.lazy(() => import('../views/admin/account/forgotPassword/index'));
const AdminForgotPasswordSent = React.lazy(() => import('../views/admin/account/forgotPasswordSent/index'));
const AdminResetPassword = React.lazy(() => import('../views/admin/account/resetPassword/index'));
const AdminVerifyActivationLink = React.lazy(() => import('../views/admin/account/verifyActivationLink/index'));
const AdminMyProfile = React.lazy(() => import('../views/admin/account/profile'));
const AdminEditMyProfile = React.lazy(() => import('../views/admin/account/profile/editProfile'));
const AdminActivityLog = React.lazy(() => import('../views/admin/account/profile/activityLog'));
const AdminHomeRedirecting = React.lazy(() => import('../views/admin/homeRedirecting'));
const AdminBulkAccounts = React.lazy(() => import('../views/admin/bulkAccount/index'));
const AdminPayments = React.lazy(() => import('../views/admin/payment/index'));
const AdminManageRegistrations = React.lazy(() => import('../views/admin/customerRegistration/index'));
const AdminManageRegistrationsDetails = React.lazy(() => import('../views/admin/customerRegistration/viewDetails/index'));
// const AdminManageTnbUsers = React.lazy(() => import('../views/admin/manageTnbAdmin/index'));
const AdminManageTnbUsers = React.lazy(() => import('../views/admin/manageTnbUser/index'));
const AdminManageTnbAdmins = React.lazy(() => import('../views/admin/manageTnbAdmin/index'));
const AdminManageEA = React.lazy(() => import('../views/admin/bulkAccount/manageElectricityAccount/index'));
const AdminPGQuery = React.lazy(() => import('../views/admin/pgQuery/index'));
const AdminNotifications = React.lazy(() => import('../views/admin/account/notifications/index'));

//#endregion

const MasterLogonLayoutWithAuth = WithAuthRole(MasterLogonLayout);
//User
const MasterUserLayoutWithAuth = WithAuthRole(MasterUserLayout);
const HomeUserWithAuth = WithAuthRole(HomeUser, [...ACCESS_ROLES.USER.OVERALL_VIEW, ...ACCESS_ROLES.ADMIN.BULK_ACCOUNS.CUSTOMER_VIEW_MODE_VIEW]);
const BillPaymentWithAuth = WithAuthRole(BillPayment, [...ACCESS_ROLES.USER.BILL_PAYMENT_VIEW, ...ACCESS_ROLES.ADMIN.BULK_ACCOUNS.CUSTOMER_VIEW_MODE_VIEW]);
const ManageAccountWithAuth = WithAuthRole(ManageAccount, [...ACCESS_ROLES.USER.ACCOUNT.VIEW, ...ACCESS_ROLES.ADMIN.BULK_ACCOUNS.CUSTOMER_VIEW_MODE_VIEW]);
const ProfileWithAuth = WithAuthRole(Profile, [...ACCESS_ROLES.USER.OVERALL_VIEW, ...ACCESS_ROLES.ADMIN.BULK_ACCOUNS.CUSTOMER_VIEW_MODE_VIEW]);
const EditProfileWithAuth = WithAuthRole(EditProfile, [...ACCESS_ROLES.USER.OVERALL_VIEW]);
const ActivityLogWithAuth = WithAuthRole(ActivityLog, [...ACCESS_ROLES.USER.OVERALL_VIEW, ...ACCESS_ROLES.ADMIN.BULK_ACCOUNS.CUSTOMER_VIEW_MODE_VIEW]);
const NotificationsWithAuth = WithAuthRole(Notifications, [...ACCESS_ROLES.USER.OVERALL_VIEW, ...ACCESS_ROLES.ADMIN.BULK_ACCOUNS.CUSTOMER_VIEW_MODE_VIEW]);
const BulkAccountWithAuth = WithAuthRole(BulkAccount);
const UserManagementWithAuth = WithAuthRole(UserManagement, [...ACCESS_ROLES.USER.MANAGE_USER.VIEW, ...ACCESS_ROLES.ADMIN.BULK_ACCOUNS.CUSTOMER_VIEW_MODE_VIEW]);
//Admin
const MasterAdminLayoutWithAuth = WithAuthRole(MasterAdminLayout, ACCESS_ROLES.ADMIN.OVERALL_VIEW);
const AdminMyProfileWithAuth = WithAuthRole(AdminMyProfile, ACCESS_ROLES.ADMIN.OVERALL_VIEW);
const AdminEditMyProfileWithAuth = WithAuthRole(AdminEditMyProfile, ACCESS_ROLES.ADMIN.OVERALL_VIEW);
const AdminActivityLogWithAuth = WithAuthRole(AdminActivityLog, ACCESS_ROLES.ADMIN.OVERALL_VIEW);
const AdminBulkAccountsWithAuth = WithAuthRole(AdminBulkAccounts, ACCESS_ROLES.ADMIN.BULK_ACCOUNS.OVERALL_VIEW);
const AdminPaymentsWithAuth = WithAuthRole(AdminPayments, ACCESS_ROLES.ADMIN.PAYMENTS.OVERALL_VIEW);
const AdminManageRegistrationsWithAuth = WithAuthRole(AdminManageRegistrations, ACCESS_ROLES.ADMIN.CUSTOMER_REGISTRATION.OVERALL_VIEW);
const AdminManageRegistrationsDetailsWithAuth = WithAuthRole(AdminManageRegistrationsDetails, ACCESS_ROLES.ADMIN.CUSTOMER_REGISTRATION.OVERALL_VIEW);
const AdminManageTnbAdminsWithAuth = WithAuthRole(AdminManageTnbAdmins, ACCESS_ROLES.ADMIN.MANAGER_TNB_ADMINS.OVERALL_VIEW);
const AdminManageTnbUsersWithAuth = WithAuthRole(AdminManageTnbUsers, ACCESS_ROLES.ADMIN.MANAGER_TNB_USERS.OVERALL_VIEW);
const AdminManageEAWithAuth = WithAuthRole(AdminManageEA, ACCESS_ROLES.ADMIN.BULKCAERROR.OVERALL_VIEW);
const AdminPGQueryWithAuth = WithAuthRole(AdminPGQuery, ACCESS_ROLES.ADMIN.FPX_MANUAL_REQUERY.OVERALL_VIEW);
const AdminNotificationsWithAuth = WithAuthRole(AdminNotifications, ACCESS_ROLES.ADMIN.OVERALL_VIEW);
//#region Public Accessible
const LearnMorePageWithSessionHandler = WithSessionHandler(LearnMorePage);
const ChangePasswordWithSessionHandler = WithSessionHandler(ChangePassword);
//#endregion

export const ROUTES: RouteObject[] = [
  {
    path: '',
    element: <AppMasterLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
      {
        path: 'learnMore',
        element: <LearnMorePageWithSessionHandler />
      },
      {//User without Login
        path: 'account',
        children: [
          {
            path: 'changePassword',
            element: <ChangePasswordWithSessionHandler />,
          },
          {
            path: 'forgotPassword',
            element: <ForgotPassword />,
          },
          {
            path: 'forgotPasswordSent',
            element: <ForgotPasswordSent />,
          },
          {
            path: 'resetPassword',
            element: <ResetPassword />,
          },
          {
            path: 'register',
            element: <AccountRegister />,
          },
          {
            path: 'registerDetails',
            element: <AccountRegisterDetails />,
          },
          {
            path: 'activate',
            element: <ActivateAccount />,
          },
          {
            path: 'expiredLink',
            element: <ExpiredLink />,
          },
          {
            path: 'activateLinkSent',
            element: <ActivateLinkSent />,
          },
          {
            path: 'transferMasterRoleSuccess',
            element: <TransferMasterRoleSuccess />,
          },
          {
            path: '*',
            element: <Navigate to="/404" />
          }
        ]
      },
      {//Admin without login
        path: 'admin',
        children: [
          {
            path: 'login',
            element: <AdminLogin />
          },
          {
            path: 'verifyActivation',
            element: <AdminVerifyActivationLink />
          },
          {
            path: 'forgotPassword',
            element: <AdminForgotPassword />,
          },
          {
            path: 'forgotPasswordSent',
            element: <AdminForgotPasswordSent />,
          },
          {
            path: 'resetPassword',
            element: <AdminResetPassword />,
          },
        ]
      },
      {//With login
        path: '',
        element: <MasterLogonLayoutWithAuth />,
        children: [
          {
            path: '',
            element: <MasterUserLayoutWithAuth />,
            children: [
              {
                path: 'home',
                element: <HomeUserWithAuth />,
              },
              {
                path: 'billPayment/*',
                element: <BillPaymentWithAuth />,
              },
              {
                path: 'manageAccount/*',
                element: <ManageAccountWithAuth />,
              },
              {
                path: 'myProfile',
                element: <ProfileWithAuth />,
              },
              {
                path: 'myProfile/edit',
                element: <EditProfileWithAuth />,
              },
              {
                path: 'activityLog',
                element: <ActivityLogWithAuth />,
              },
              {
                path: 'bulkAccount/*',
                element: <BulkAccountWithAuth />,
              },
              {
                path: 'notifications',
                element: <NotificationsWithAuth />,
              },
              {
                path: 'manageUsers',
                element: <UserManagementWithAuth />,
              },
              {
                path: '*',
                element: <Navigate to="/404" />
              }
            ]
          },
          {
            path: 'admin',
            element: <MasterAdminLayoutWithAuth />,
            children: [
              {
                path: '',
                element: <AdminHomeRedirecting />
              },
              {
                path: 'firstTimeLogin',
                element: <AdminFirstTimePasswordReset />
              },
              {
                path: 'myProfile',
                element: <AdminMyProfileWithAuth />,
              },
              {
                path: 'myProfile/edit',
                element: <AdminEditMyProfileWithAuth />,
              },
              {
                path: 'activityLog',
                element: <AdminActivityLogWithAuth />,
              },
              {
                path: 'bulkAccounts/*',
                element: <AdminBulkAccountsWithAuth />,
              },
              {
                path: 'bulkAccounts/pendingBulkCA',
                element: <AdminManageEAWithAuth/>,
              },
              {
                path: 'payments/*',
                element: <AdminPaymentsWithAuth />,
              },
              {
                path: 'manageRegistrations',
                element: <AdminManageRegistrationsWithAuth />,
              },
              {
                path: 'manageRegistrations/:registrationId',
                element: <AdminManageRegistrationsDetailsWithAuth />,
              },
              {
                path: 'manageTnbAdmins',
                element: <AdminManageTnbAdminsWithAuth />,
              },
              {
                path: 'manageTnbUsers',
                element: <AdminManageTnbUsersWithAuth />,
              },
              {
                path: 'paymentGatewayQuery',
                element: <AdminPGQueryWithAuth />,
              },
              {
                path: 'notifications',
                element: <AdminNotificationsWithAuth />,
              },
              {
                path: '*',
                element: <Navigate to="/404" />
              }
            ]
          },
        ]
      },
      {
        path: 'unauthorized',
        element: <UnauthorizedPage />
      },
      {
        path: '404',
        element: <NotFoundPage />
      },
      {
        path: '*',
        element: <Navigate to="404" />
      }
    ]
  }
];
