import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';

export const useStateWithPaths = (statePaths: string[] = []) => {
  const paths = useMemo(() => statePaths, [statePaths]);
  const selector = useCallback((state) => {
    return _.map(paths, (path) => {
      return _.get(state, path);
    })
  }, [paths]);
  return useSelector(selector);
};

export const useActions = (actionCreators: {}) => {
  const dispatch = useDispatch();
  return useMemo(() => {
    return bindActionCreators(actionCreators, dispatch);
    //}, [_.values(actionCreators), dispatch]);
  }, [actionCreators, dispatch]);
};

const defaultFunction: object = {};
export default defaultFunction;