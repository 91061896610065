import { B2B_API_INSTANCE, B2B_API_BLOB_INSTANCE } from "../index";

const SERVICE_VERSION: number = 1;

const BILL_PAYMENT_CONTROLLER_NAME: string = `/billpayment-svc/api/v${SERVICE_VERSION}/Admin`;
const PAYMENT_CONTROLLER_NAME: string = `/payments-svc/api/v${SERVICE_VERSION}/Admin`;

export const getPendingApprovalCards = () => {
  return B2B_API_INSTANCE.post(`${PAYMENT_CONTROLLER_NAME}/GetAdminPendingPaymentsCards`);
};

export const getPendingApprovalTable = (recordCount: number) => {
  return B2B_API_INSTANCE.post(`${PAYMENT_CONTROLLER_NAME}/GetAdminPendingPayments`, { recordCount });
};


export const getAllPaymentsCards = () => {
  return B2B_API_INSTANCE.post(`${PAYMENT_CONTROLLER_NAME}/GetAdminAllPaymentsCards`);
};

export const getAllPaymentsTable = (recordCount: number) => {
  return B2B_API_INSTANCE.post(`${PAYMENT_CONTROLLER_NAME}/GetAdminAllPayments`, { recordCount });
};

export const getPaymentDetails = (transactionNo: string) => {
  return B2B_API_INSTANCE.get(`${PAYMENT_CONTROLLER_NAME}/GetAdminPaymentDetails?transactionNo=${transactionNo}`);
};

export const reconcilePayment = (eBulkPaymentID: string, reconcileAmount: number, reconcileType: number) => {
  const postBody = {
    eBulkPaymentID,
    reconcileAmount,
    reconcileType,
  };
  return B2B_API_INSTANCE.post(`${BILL_PAYMENT_CONTROLLER_NAME}/RequestReconciliation`, postBody);
};

export const approvePayment = (eBulkPaymentID: string) => {
  return B2B_API_INSTANCE.post(`${BILL_PAYMENT_CONTROLLER_NAME}/PaymentAccept`, { eBulkPaymentID });
};

export const declinePayment = (eBulkPaymentID: string) => {
  return B2B_API_INSTANCE.post(`${BILL_PAYMENT_CONTROLLER_NAME}/PaymentReject`, { eBulkPaymentID });
};

export const getAdminPaymentDetailsExcel = (transactionNo: string) => {
  return B2B_API_BLOB_INSTANCE.get(`${PAYMENT_CONTROLLER_NAME}/GetAdminPaymentDetailsExcel?transactionNo=${transactionNo}`);
};

export const getFPXRequeryData = (merchantTranId: string) => {
  return B2B_API_INSTANCE.get(`${PAYMENT_CONTROLLER_NAME}/GetFpxRequeryData?merchantTranId=${merchantTranId}`);
};

export const performManualRequest = (merchantTranId: string) => {
  return B2B_API_INSTANCE.post(`${PAYMENT_CONTROLLER_NAME}/AdminFPXManualRequery?merchantTranId=${merchantTranId}`);
};
