import Axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { createBrowserHistory } from "history";

import { API_URL } from '../constants/envVariables';
import { store } from "../redux/redux";
import { clearSession } from '../redux/actions/sessionAction';

export const serviceUnauthorized = (): void => {
  store.dispatch(clearSession() as any);
  const currentPathname = _.get(document, 'location.pathname');
  const isAdminRoute = _.startsWith(currentPathname, '/admin');
  
  let history = createBrowserHistory();
  history.replace(isAdminRoute ? '/admin/login' : '/');
  window.location.reload();
};

const getJwtAccessToken = async () => {
  return Cookies.get('access_token');
}

const apiRequestSuccessInterceptor = async (config: AxiosRequestConfig) => {
  const token = await getJwtAccessToken();
  if (token) _.set(config, 'headers.Authorization', `Bearer ${token}`);

  return config;
}

const apiResponseErrorInterceptor = (error: any): any => {
  if (Axios.isCancel(error)) {
    return Promise.reject(error);
  }

  const errorResponse = error.response;

  if (errorResponse) {

    // Get latest access token and retry api when 401
    // Max retry will redirect to logout
    if (errorResponse.status === 401 || _.isEqual(_.get(errorResponse.data.Error, "errorCode"), "401")) {
      serviceUnauthorized()
    } else {
      return Promise.reject(_.get(errorResponse, 'data.Error'));
    }

  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an API_INSTANCE of XMLHttpRequest in the browser and an API_INSTANCE of
    // http.ClientRequest in node.js
    return Promise.reject(error);
  } else {
    // Something happened in setting up the request that triggered an Error
    throw Error(
      "Sorry! Something went wrong, please contact our administrator."
    );
  }
}

const apiResponseErrorUnobstrusiveInterceptor = (error: any): any => {
  if (Axios.isCancel(error)) {
    return Promise.reject(error);
  }

  const errorResponse = error.response;

  if (errorResponse) {
    // Get latest access token and retry api when 401
    // Max retry will redirect to logout
    return Promise.reject(_.get(errorResponse, 'data.Error'));

  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an API_INSTANCE of XMLHttpRequest in the browser and an API_INSTANCE of
    // http.ClientRequest in node.js
    return Promise.reject(error);
  } else {
    // Something happened in setting up the request that triggered an Error
    throw Error(
      "Sorry! Something went wrong, please contact our administrator."
    );
  }
}

//#region B2B_API_INSTANCE
export const B2B_API_INSTANCE = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

B2B_API_INSTANCE.interceptors.request.use(
  apiRequestSuccessInterceptor,
  function (error) {
    return Promise.reject(error);
  }
);

B2B_API_INSTANCE.interceptors.response.use(
  function (response) {
    return _.get(response, 'data.Data');
  },
  apiResponseErrorInterceptor
);
//#endregion

//#region B2B_API_BLOB_INSTANCE
export const B2B_API_BLOB_INSTANCE = Axios.create({
  baseURL: API_URL,
  responseType: 'blob',
});

B2B_API_BLOB_INSTANCE.interceptors.request.use(
  apiRequestSuccessInterceptor,
  function (error) {
    return Promise.reject(error);
  }
);

B2B_API_BLOB_INSTANCE.interceptors.response.use(
  function (response) {
    return _.get(response, 'data');
  },
  apiResponseErrorInterceptor
);

//#region B2B_API_INSTANCE
export const B2B_API_UPLOAD_INSTANCE = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

B2B_API_UPLOAD_INSTANCE.interceptors.request.use(
  apiRequestSuccessInterceptor,
  function (error) {
    return Promise.reject(error);
  }
);

B2B_API_UPLOAD_INSTANCE.interceptors.response.use(
  function (response) {
    return _.get(response, 'data.Data');
  },
  apiResponseErrorInterceptor
);
//#endregion

//#region B2B_API_UNOBSTRUSIVE_INSTANCE
export const B2B_API_UNOBSTRUSIVE_INSTANCE = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

B2B_API_UNOBSTRUSIVE_INSTANCE.interceptors.request.use(
  apiRequestSuccessInterceptor,
  function (error) {
    return Promise.reject(error);
  }
);

B2B_API_UNOBSTRUSIVE_INSTANCE.interceptors.response.use(
  function (response) {
    return _.get(response, 'data.Data');
  },
  apiResponseErrorUnobstrusiveInterceptor
);
//#endregion