import _ from 'lodash';

import * as userActions from '../actions/userAction';
import { IReducerAction, IReducer, IPayloadObj } from './_commonInterface';

type NullableNumberString = number | string | null;

interface IManageUsers {
  totalUsers: NullableNumberString,
  activeUsers: NullableNumberString,
  inactiveUsers: NullableNumberString,
  invitesPending: NullableNumberString
  manageUsersTable: object[],
  manageUsersCount: number,
  maxUserLimit: number,
}

interface IUserReducer {
  manageUsers: IManageUsers,
  transferMasterRole: object,
}

const defaultState: IUserReducer = {
  manageUsers: {
    totalUsers: "-",
    activeUsers: "-",
    inactiveUsers: "-",
    invitesPending: "-",
    manageUsersTable: [],
    manageUsersCount: 0,
    maxUserLimit: 0,
  },
  transferMasterRole: {},
}

const REDUCERS: IReducer = {
  [userActions.GET_MANAGE_USERS_CARDS]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageUsers: {
        ...state.manageUsers,
        totalUsers: _.get(payload, 'totalUser') ?? '-',
        activeUsers: _.get(payload, 'activeUser') ?? '-',
        inactiveUsers: _.get(payload, 'inactiveUser') ?? '-',
        invitesPending: _.get(payload, 'invitesPending') ?? '-',
      }
    };
  },
  [userActions.ADD_NEW_USER]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageUsers: {
        ...state.manageUsers,
        manageUsersTable: state.manageUsers.manageUsersTable.concat({
          ...payload,
          lastLoggedIn: _.get(payload, "lastLoggedIn") ? payload.lastLoggedIn + "Z" : "-",
        }),
        manageUsersCount: state.manageUsers.manageUsersCount + 1,
        newUserEmail: _.get(payload, "emailAddress"),
      }
    };
  },
  [userActions.ADD_NEW_USER_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      addUserErrorTitle: _.get(payload, 'errorTopic'),
      addUserErrorMessage: _.get(payload, 'errorMessage'),
    }
  },
  [userActions.SET_ADD_USER_ERROR]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      addUserErrorTitle: _.get(payload, "errorTopic"),
      addUserErrorMessage: _.get(payload, "errorMessage"),
    };
  },
  [userActions.GET_MANAGEUSERS]: (state: IUserReducer, { payload }: IPayloadObj) => {
    const { data, count, maxUserLimit } = payload;
    _.forEach(data, b => {
      b.username ?? _.set(b, "username", "-");
      b.emailAddress ?? _.set(b, "emailAddress", "-");
      _.set(b, "lastLoggedIn", b.lastLoggedIn ? b.lastLoggedIn + "Z" : "-");
    });
    return {
      ...state,
      manageUsers: {
        ...state.manageUsers,
        manageUsersTable: [...data],
        manageUsersCount: _.isNull(count) ? _.get(data, 'length') : count,
        maxUserLimit,
      }
    };
  },
  [userActions.UPDATE_BULK_USER]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageUsers: {
        ...state.manageUsers,
        manageUsersTable: state.manageUsers.manageUsersTable.map(
          (content: any) => content.userId === payload.userId ?
            {
              userId: payload.userId,
              username: payload.username ?? "-",
              emailAddress: payload.emailAddress ?? "-",
              nickname: payload.nickname,
              role: payload.role,
              status: payload.status,
              lastLoggedIn: payload.lastLoggedIn ? payload.lastLoggedIn + "Z" : "-",
            } : content)
      }
    };
  },
  [userActions.UPDATE_BULK_USER_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      updateUserErrorTitle: _.get(payload, 'errorTopic'),
      updateUserErrorMessage: _.get(payload, 'errorMessage'),
    }
  },
  [userActions.DELETE_BULK_USER]: (state: IUserReducer, { payload }: IPayloadObj) => {
    const result = state.manageUsers.manageUsersTable.filter((item: any) => item.userId !== payload.userId);
    return {
      ...state,
      manageUsers: {
        ...state.manageUsers,
        manageUsersTable: result,
        manageUsersCount: result.length,
      }
    };
  },
  [userActions.DELETE_BULK_USER_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      removeUserErrorTitle: _.get(payload, 'errorTopic'),
      removeUserErrorMessage: _.get(payload, 'errorMessage'),
    }
  },
  [userActions.TRANSFER_MASTER_ROLE]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      transferMasterRole: {
        ...state.transferMasterRole,
        newMasterEmail: _.get(payload, 'newMasterEmail') ?? '-',
      }
    };
  },
  [userActions.TRANSFER_MASTER_ROLE_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      transferMasterRole: {
        ...state.transferMasterRole,
        errorTopic: _.get(payload, 'errorTopic'),
        errorMessage: _.get(payload, 'errorMessage'),
      }
    };
  },
}

const userReducer = (state: IUserReducer = defaultState, { type, payload, error }: IReducerAction = { type: '' }) => {
  if (!type)
    return state;

  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
}
export default userReducer;
export const REDUCER_ACOUNT = 'userReducer'
