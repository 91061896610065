import _ from "lodash";
import { Dispatch } from "redux";

import IRegisterObject from "src/interfaces/IRegisterObject";

import { ACCESS_ROLES } from "src/constants/userRoles";

import * as AccountService from "src/services/accountService";
import { fetchStart, fetchSuccess, fetchError } from "./fetchAction";
import * as sessionAction from './sessionAction';
import * as adminAccountActions from './admin/accountAction';

export const ACTION_NAME: string = "ACCOUNT_";

export const SEND_FORGOT_PASSWORD_FAILED = `${ACTION_NAME}SEND_FORGOT_PASSWORD_FAILED`;
export const SEND_FORGOT_PASSWORD = `${ACTION_NAME}SEND_FORGOT_PASSWORD`;
export const sendForgotPassword = (email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(SEND_FORGOT_PASSWORD));
    AccountService.forgotPassword(email)
      .then(() => dispatch(fetchSuccess(SEND_FORGOT_PASSWORD)))
      .catch((response) => {
        dispatch({
          type: SEND_FORGOT_PASSWORD_FAILED,
          payload: response,
        });
        dispatch(fetchError(SEND_FORGOT_PASSWORD));
      });
  };
};

export const SET_IS_VALID_USER = `${ACTION_NAME}SET_IS_VALID_USER`;
export const setIsValidUser = (isValidUser: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_IS_VALID_USER,
      payload: isValidUser,
    });
  };
};

export const VALIDATE_RESET_TOKEN_FAILED = `${ACTION_NAME}VALIDATE_RESET_TOKEN_FAILED`;
export const VALIDATE_RESET_TOKEN = `${ACTION_NAME}VALIDATE_RESET_TOKEN`;
export const validateResetToken = (token: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(VALIDATE_RESET_TOKEN));
    AccountService.validateResetToken(token)
      .then(() => dispatch(fetchSuccess(VALIDATE_RESET_TOKEN)))
      .catch((response) => {
        dispatch({
          type: VALIDATE_RESET_TOKEN_FAILED,
          payload: response,
        });
        dispatch(fetchError(VALIDATE_RESET_TOKEN));
      });
  };
};

export const RESET_PASSWORD_FAILED = `${ACTION_NAME}RESET_PASSWORD_FAILED`;
export const RESET_PASSWORD = `${ACTION_NAME}RESET_PASSWORD`;
export const resetPassword = (token: string, password: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(RESET_PASSWORD));
    AccountService.resetPassword(token, password)
      .then(() => dispatch(fetchSuccess(RESET_PASSWORD)))
      .catch((response) => {
        dispatch({
          type: RESET_PASSWORD_FAILED,
          payload: response,
        });
        dispatch(fetchError(RESET_PASSWORD));
      });
  };
};

export const GET_COMPANY_NAME_FOR_BULK_ID = `${ACTION_NAME}GET_COMPANY_NAME_FOR_BULK_ID`;
export const _getCompanyNameForBulkId = (dispatch: Dispatch, bulkId: string) => {
  dispatch(fetchStart(GET_COMPANY_NAME_FOR_BULK_ID));
  AccountService.getCompanyNameForBulkId(bulkId)
    .then((response) => {
      dispatch({
        type: GET_COMPANY_NAME_FOR_BULK_ID,
        payload: response,
      });
    })
    .then(() => dispatch(fetchSuccess(GET_COMPANY_NAME_FOR_BULK_ID)))
    .catch(() => dispatch(fetchError(GET_COMPANY_NAME_FOR_BULK_ID)));
};
export const getCompanyNameForBulkId = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    _getCompanyNameForBulkId(dispatch, bulkId);
  };
};

export const GET_BUSINESS_TYPE_FOR_BULK_ID = `${ACTION_NAME}GET_BUSINESS_TYPE_FOR_BULK_ID`;
export const _getBusinessTypeForBulkId = (dispatch: Dispatch, bulkId: string) => {
  dispatch(fetchStart(GET_BUSINESS_TYPE_FOR_BULK_ID));
  AccountService.getBusinessTypeForBulkId(bulkId)
    .then((response) => {
      dispatch({
        type: GET_BUSINESS_TYPE_FOR_BULK_ID,
        payload: response,
      });
    })
    .then(() => dispatch(fetchSuccess(GET_BUSINESS_TYPE_FOR_BULK_ID)))
    .catch(() => dispatch(fetchError(GET_BUSINESS_TYPE_FOR_BULK_ID)));
};
export const getBusinessTypeForBulkId = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    _getBusinessTypeForBulkId(dispatch, bulkId);
  };
};

export const GET_USER_PROFILE = `${ACTION_NAME}GET_USER_PROFILE`;
export const getUserProfile = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_USER_PROFILE));
    AccountService.getUserProfile()
      .then((response) => {
        const isAdmin = !!_.intersection(ACCESS_ROLES.ADMIN.OVERALL_VIEW, _.get(response, 'scopes')).length
        if (isAdmin) {
          dispatch({
            type: adminAccountActions.GET_ADMIN_USER_PROFILE,
            payload: response,
          });
        }
        else {
          dispatch({
            type: GET_USER_PROFILE,
            payload: response,
          });
          if (_.get(response, "bulkId")) {
            _getCompanyNameForBulkId(dispatch, _.get(response, "bulkId"));
            _getBusinessTypeForBulkId(dispatch, _.get(response, "bulkId"));
          }
        }
        dispatch({
          type: sessionAction.SET_USER_ROLES,
          payload: {
            scopes: _.get(response, 'scopes'),
            registrationStatusId: _.get(response, 'registrationStatusId'),
          },
        });
      })
      .then(() => dispatch(fetchSuccess(GET_USER_PROFILE)))
      .catch(() => dispatch(fetchError(GET_USER_PROFILE)));
  };
};

export const SET_USER_PROFILE_BY_ADMIN = `${ACTION_NAME}SET_USER_PROFILE_BY_ADMIN`;
export const setUserProfileByAdmin = (response: object) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_USER_PROFILE_BY_ADMIN,
      payload: response,
    });
  };
};

export const CHANGE_PASSWORD_FAILED = `${ACTION_NAME}CHANGE_PASSWORD_FAILED`;
export const CHANGE_PASSWORD = `${ACTION_NAME}CHANGE_PASSWORD`;
export const changePassword = (oldPassword: string, newPassword: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(CHANGE_PASSWORD));
    AccountService.changePassword(oldPassword, newPassword)
      .then(() =>
        dispatch({
          type: CHANGE_PASSWORD,
        })
      )
      .then(() => dispatch(fetchSuccess(CHANGE_PASSWORD)))
      .catch((response) => {
        dispatch({
          type: CHANGE_PASSWORD_FAILED,
          payload: response,
        });
        dispatch(fetchError(CHANGE_PASSWORD));
      });
  };
};

export const GET_PROFILE_FOR_BULK_ID = `${ACTION_NAME}GET_PROFILE_FOR_BULK_ID`;
export const getPofileForBulkId = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PROFILE_FOR_BULK_ID));
    AccountService.getProfileForBulkId(bulkId)
      .then((response) => {
        dispatch({
          type: GET_PROFILE_FOR_BULK_ID,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_PROFILE_FOR_BULK_ID)))
      .catch(() => dispatch(fetchError(GET_PROFILE_FOR_BULK_ID)));
  };
};

export const GET_ACCOUNT_FOR_BULK_ID = `${ACTION_NAME}GET_ACCOUNT_FOR_BULK_ID`;
export const getAccountForBulkId = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ACCOUNT_FOR_BULK_ID));
    AccountService.getAccountsForBulkId(bulkId)
      .then((response) => {
        dispatch({
          type: GET_ACCOUNT_FOR_BULK_ID,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_ACCOUNT_FOR_BULK_ID)))
      .catch(() => dispatch(fetchError(GET_ACCOUNT_FOR_BULK_ID)));
  };
};

export const ACCOUNT_STATEMENT_FOR_CA = `${ACTION_NAME}ACCOUNT_STATEMENT_FOR_CA`;
export const accountStatementForCA = (
  accountNo: string,
  statementPeriod: string,
  email: string,
  name: string,
  bulkId: string,
  referenceId: string,
  companyName: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(ACCOUNT_STATEMENT_FOR_CA));
    AccountService.accountStatementForCA(accountNo, statementPeriod, email, name, bulkId, referenceId, companyName)
      .then(() => dispatch(fetchSuccess(ACCOUNT_STATEMENT_FOR_CA)))
      .catch(() => dispatch(fetchError(ACCOUNT_STATEMENT_FOR_CA)));
  };
};

export const SET_REGISTER_STATE = `${ACTION_NAME}SET_REGISTER_STATE`;
export const setRegisterState = (email: string, password: string, invitationToken?: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_REGISTER_STATE,
      payload: {
        email,
        password,
        invitationToken,
      }
    });
  }
};

export const REGISTER_STATE_RESET = `${ACTION_NAME}REGISTER_STATE_RESET`;
export const registerStateReset = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: REGISTER_STATE_RESET });
  }
};

export const SET_REGISTER_ANNOUNCEMENT = `${ACTION_NAME}SET_REGISTER_ANNOUNCEMENT`;
export const setRegisterAnnouncement = (showAnnouncement: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_REGISTER_ANNOUNCEMENT,
      payload: {
        showAnnouncement,
      }
    });
  }
};

export const SET_NOTIFICATION_REQUEST = `${ACTION_NAME}SET_NOTIFICATION_REQUEST`;
export const setNotificationRequest = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(SET_NOTIFICATION_REQUEST));
    AccountService.setNotificationRequest()
      .then(() => dispatch(fetchSuccess(SET_NOTIFICATION_REQUEST)))
      .catch(() => dispatch(fetchError(SET_NOTIFICATION_REQUEST)));
  }
};

export const SET_BULKACCOUNTREGISTRATION_FORM = `${ACTION_NAME}SET_BULKACCOUNTREGISTRATION_FORM`;
export const setBulkAccountRegistrationForm = (form: object) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_BULKACCOUNTREGISTRATION_FORM,
      payload: {
        form,
      }
    });
  }
};

export const IS_NEW_EMAIL_VALID_FAILED = `${ACTION_NAME}IS_NEW_EMAIL_VALID_FAILED`;
export const IS_NEW_EMAIL_VALID = `${ACTION_NAME}IS_NEW_EMAIL_VALID`;
export const isNewEmailValid = (email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(IS_NEW_EMAIL_VALID));
    AccountService.isNewEmailValid(email)
      .then((response) => {
        dispatch({
          type: IS_NEW_EMAIL_VALID,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(IS_NEW_EMAIL_VALID)))
      .catch((response) => {
        if(response){
          dispatch({
            type: IS_NEW_EMAIL_VALID_FAILED,
            payload: response,
          });
      }
        dispatch(fetchError(IS_NEW_EMAIL_VALID));
      });
  };
};

export const USER_TUTORIAL_COMPLETED = `${ACTION_NAME}USER_TUTORIAL_COMPLETED`;
export const userTutorialCompleted = (userTutorialId: string) => {
  return (dispatch: Dispatch, getState: any) => {
    const { sessionReducer: { isAdmin } } = getState();
    dispatch(fetchStart(USER_TUTORIAL_COMPLETED));
    AccountService.userTutorialCompleted(userTutorialId)
      .then(() => {
        if (isAdmin) {
          dispatch({
            type: adminAccountActions.ADMIN_USER_TUTORIAL_COMPLETED,
            payload: userTutorialId,
          });
        } else {
          dispatch({
            type: USER_TUTORIAL_COMPLETED,
            payload: userTutorialId,
          });
        }
      })
      .then(() => dispatch(fetchSuccess(USER_TUTORIAL_COMPLETED)))
      .catch(() => dispatch(fetchError(USER_TUTORIAL_COMPLETED)));
  };
};

export const VALIDATE_ACTIVATE_ACCOUNT_TOKEN_FAILED = `${ACTION_NAME}VALIDATE_ACTIVATE_ACCOUNT_TOKEN_FAILED`;
export const VALIDATE_ACTIVATE_ACCOUNT_TOKEN = `${ACTION_NAME}VALIDATE_ACTIVATE_ACCOUNT_TOKEN`;
export const validateAcvtivateAccountToken = (token: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(VALIDATE_ACTIVATE_ACCOUNT_TOKEN));
    AccountService.validateActivateAccountToken(token)
      .then(() => dispatch(fetchSuccess(VALIDATE_ACTIVATE_ACCOUNT_TOKEN)))
      .catch((response) => {
        dispatch({
          type: VALIDATE_ACTIVATE_ACCOUNT_TOKEN_FAILED,
          payload: response,
        });
        dispatch(fetchError(VALIDATE_ACTIVATE_ACCOUNT_TOKEN));
      });
  };
};

export const RESEND_ACTIVATE_Link_FAILED = `${ACTION_NAME}RESEND_ACTIVATE_Link_FAILED`;
export const RESEND_ACTIVATE_Link = `${ACTION_NAME}RESEND_ACTIVATE_Link`;
export const resendActivateLink = (token: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(RESEND_ACTIVATE_Link));
    AccountService.resendActivateLink(token)
      .then(() => dispatch(fetchSuccess(RESEND_ACTIVATE_Link)))
      .catch((response) => {
        dispatch({
          type: RESEND_ACTIVATE_Link_FAILED,
          payload: response,
        });
        dispatch(fetchError(RESEND_ACTIVATE_Link));
      });
  };
};

export const REGISTER_FAILED = `${ACTION_NAME}REGISTER_FAILED`;
export const REGISTER = `${ACTION_NAME}REGISTER`;
export const register = (email: string, password: string, name: string, identificationType: string, identificationNo: string, mobileNo: string, invitationToken?: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(REGISTER));
    AccountService.register(email, password, name, identificationType, identificationNo, mobileNo, invitationToken)
      .then(() => dispatch(fetchSuccess(REGISTER)))
      .catch((response) => {
        dispatch({
          type: REGISTER_FAILED,
          payload: response,
        });
        dispatch(fetchError(REGISTER));
      });
  };
};

export const SET_USERPREFERENCE = `${ACTION_NAME}SET_USERPREFERENCE`;
export const setUserPreference = (preferenceCode: number, configValue: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(SET_USERPREFERENCE));
    AccountService.setUserPreference(preferenceCode, configValue)
      .then(() => dispatch(fetchSuccess(SET_USERPREFERENCE)))
      .catch(() => dispatch(fetchError(SET_USERPREFERENCE)));
  };
};

export const EDIT_BULK_ACCOUNT_FAILED = `${ACTION_NAME}EDIT_BULK_ACCOUNT_FAILED`;
export const EDIT_BULK_ACCOUNT = `${ACTION_NAME}EDIT_BULK_ACCOUNT`;
export const editBulkAccount = (accountObject: object) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(EDIT_BULK_ACCOUNT));
    AccountService.editBulkAccount(accountObject)
      .then((response) => {
        dispatch({
          type: EDIT_BULK_ACCOUNT,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(EDIT_BULK_ACCOUNT)))
      .catch((response) => {
        dispatch({
          type: EDIT_BULK_ACCOUNT_FAILED,
          payload: response,
        });
        dispatch(fetchError(EDIT_BULK_ACCOUNT));
      });
  };
};

export const ASSIGN_GROUPS_FAILED = `${ACTION_NAME}ASSIGN_GROUPS_FAILED`;
export const ASSIGN_GROUPS = `${ACTION_NAME}ASSIGN_GROUPS`;
export const assignGroups = (accountsNo: string[], groupId: string, groupName: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(ASSIGN_GROUPS));
    AccountService.assignGroups(accountsNo, groupId)
    .then(() => {
      dispatch({
        type: ASSIGN_GROUPS,
        payload: {
          noOfAccounts: accountsNo.length,
          groupName: groupName,
        },
      });
    })
    .then(() => dispatch(fetchSuccess(ASSIGN_GROUPS)))
    .catch((response) => {
      dispatch({
        type: ASSIGN_GROUPS_FAILED,
        payload: response,
      });
      dispatch(fetchError(ASSIGN_GROUPS));
    });
  };
};

export const EDIT_ELECTRICITY_ACCOUNTS_FAILED = `${ACTION_NAME}EDIT_ELECTRICITY_ACCOUNTS_FAILED`;
export const EDIT_ELECTRICITY_ACCOUNTS = `${ACTION_NAME}EDIT_ELECTRICITY_ACCOUNTS`;
export const editElectricityAccounts = (bulkId: string, childAccountsObject: object[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(EDIT_ELECTRICITY_ACCOUNTS));
    AccountService.editElectricityAccounts(bulkId, childAccountsObject)
    .then(() => dispatch(fetchSuccess(EDIT_ELECTRICITY_ACCOUNTS)))
    .catch((response) => {
      dispatch({
        type: EDIT_ELECTRICITY_ACCOUNTS_FAILED,
        payload: response,
      });
      dispatch(fetchError(EDIT_ELECTRICITY_ACCOUNTS));
    });
  };
};

export const ADD_ELECTRICITY_ACCOUNTS_FAILED = `${ACTION_NAME}ADD_ELECTRICITY_ACCOUNTS_FAILED`;
export const ADD_ELECTRICITY_ACCOUNTS = `${ACTION_NAME}ADD_ELECTRICITY_ACCOUNTS`;
export const addElectricityAccounts = (bulkId: string, childAccountsObject: object[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(ADD_ELECTRICITY_ACCOUNTS));
    AccountService.addElectricityAccounts(bulkId, childAccountsObject)
      .then(() => {
        dispatch({
          type: ADD_ELECTRICITY_ACCOUNTS,
          payload: childAccountsObject.length,
        });
      })
      .then(() => dispatch(fetchSuccess(ADD_ELECTRICITY_ACCOUNTS)))
      .catch((response) => {
        dispatch({
          type: ADD_ELECTRICITY_ACCOUNTS_FAILED,
          payload: response,
        });
        dispatch(fetchError(ADD_ELECTRICITY_ACCOUNTS));
      });
  };
};

export const CREATE_GROUP_FAILED = `${ACTION_NAME}CREATE_GROUP_FAILED`;
export const CREATE_GROUP = `${ACTION_NAME}CREATE_GROUP`;
export const createGroup = (bulkId: string, groupName: string, accountNo: string[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(CREATE_GROUP));
    AccountService.createGroup(bulkId, groupName, accountNo)
      .then((response) => {
        dispatch({
          type: CREATE_GROUP,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(CREATE_GROUP)))
      .catch((response) => {
        dispatch({
          type: CREATE_GROUP_FAILED,
          payload: response,
        });
        dispatch(fetchError(CREATE_GROUP));
      });
  };
};

export const DELETE_GROUP_FAILED = `${ACTION_NAME}DELETE_GROUP_FAILED`;
export const DELETE_GROUP = `${ACTION_NAME}DELETE_GROUP`;
export const deleteGroup = (groupId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(DELETE_GROUP));
    AccountService.deleteGroup(groupId)
      .then((response) => {
        dispatch({
          type: DELETE_GROUP,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(DELETE_GROUP)))
      .catch((response) => {
        dispatch({
          type: DELETE_GROUP_FAILED,
          payload: response,
        });
        dispatch(fetchError(DELETE_GROUP));
      });
  };
};

export const REMOVE_ACCOUNT_FROM_GROUP_FAILED = `${ACTION_NAME}REMOVE_ACCOUNT_FROM_GROUP_FAILED`;
export const REMOVE_ACCOUNT_FROM_GROUP = `${ACTION_NAME}REMOVE_ACCOUNT_FROM_GROUP`;
export const removeAccountFromGroup = (accountNo: string[], groupId: string, groupName: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(REMOVE_ACCOUNT_FROM_GROUP));
    AccountService.removeAccountFromGroup(accountNo, groupId)
      .then(() => {
        dispatch({
          type: REMOVE_ACCOUNT_FROM_GROUP,
          payload: {
            noOfAccounts: accountNo.length,
            groupName: groupName,
          },
        });
      })
      .then(() => dispatch(fetchSuccess(REMOVE_ACCOUNT_FROM_GROUP)))
      .catch((response) => {
        dispatch({
          type: REMOVE_ACCOUNT_FROM_GROUP_FAILED,
          payload: response,
        });
        dispatch(fetchError(REMOVE_ACCOUNT_FROM_GROUP));
      });
  };
};

export const REASSIGN_GROUPS_FAILED = `${ACTION_NAME}REASSIGN_GROUPS_FAILED`;
export const REASSIGN_GROUPS = `${ACTION_NAME}REASSIGN_GROUPS`;
export const reassignGroups = (accountsNo: string[], groupId: string, groupName: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(REASSIGN_GROUPS));
    AccountService.reassignGroups(accountsNo, groupId)
      .then(() => {
        dispatch({
          type: REASSIGN_GROUPS,
          payload: {
            noOfAccounts: accountsNo.length,
            groupName: groupName,
          },
        });
      })
      .then(() => dispatch(fetchSuccess(REASSIGN_GROUPS)))
      .catch((response) => {
        dispatch({
          type: REASSIGN_GROUPS_FAILED,
          payload: response,
        });
        dispatch(fetchError(REASSIGN_GROUPS));
      });
  };
};

export const ASSIGN_ACCOUNTS_TO_GROUP_FAILED = `${ACTION_NAME}ASSIGN_ACCOUNTS_TO_GROUP_FAILED`;
export const ASSIGN_ACCOUNTS_TO_GROUP = `${ACTION_NAME}ASSIGN_ACCOUNTS_TO_GROUP`;
export const assignAccountsToGroup = (accountsNo: string[], groupId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(ASSIGN_ACCOUNTS_TO_GROUP));
    AccountService.assignAccountsToGroup(accountsNo, groupId)
    .then(() => {
      dispatch({
        type: ASSIGN_ACCOUNTS_TO_GROUP,
        payload: {
          noOfAccounts: accountsNo.length,
        },
      });
    })
    .then(() => dispatch(fetchSuccess(ASSIGN_ACCOUNTS_TO_GROUP)))
    .catch((response) => {
      dispatch({
        type: ASSIGN_ACCOUNTS_TO_GROUP_FAILED,
        payload: response,
      });
      dispatch(fetchError(ASSIGN_ACCOUNTS_TO_GROUP));
    });
  };
};

export const REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT_FAILED = `${ACTION_NAME}REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT_FAILED`;
export const REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT = `${ACTION_NAME}REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT`;
export const removeChildAccountFromBulkAccount = (bulkId: string, accountNo: string[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT));
    AccountService.removeChildAccountFromBulkAccount(bulkId, accountNo)
      .then(() => {
        dispatch({
          type: REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT,
          payload: accountNo.length,
        });
      })
      .then(() => dispatch(fetchSuccess(REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT)))
      .catch((response) => {
        dispatch({
          type: REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT_FAILED,
          payload: response,
        });
        dispatch(fetchError(REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT));
      });
  };
};

export const GET_ELECTRICITY_ACCOUNT_DETAILS = `${ACTION_NAME}GET_ELECTRICITY_ACCOUNT_DETAILS`;
export const getElectricityAccountDetails = (accountNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ELECTRICITY_ACCOUNT_DETAILS));
    AccountService.getElectricityAccountDetails(accountNo)
      .then((response) => {
        dispatch({
          type: GET_ELECTRICITY_ACCOUNT_DETAILS,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_ELECTRICITY_ACCOUNT_DETAILS)))
      .catch(() => dispatch(fetchError(GET_ELECTRICITY_ACCOUNT_DETAILS)));
  };
};

export const GET_EMAIL_BY_INVITATION_TOKEN_FAILED = `${ACTION_NAME}GET_EMAIL_BY_INVITATION_TOKEN_FAILED`;
export const GET_EMAIL_BY_INVITATION_TOKEN = `${ACTION_NAME}GET_EMAIL_BY_INVITATION_TOKEN`;
export const getEmailByInvitationToken = (token: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_EMAIL_BY_INVITATION_TOKEN));
    AccountService.getEmailByInvitationToken(token)
      .then((response) => {
        dispatch({
          type: GET_EMAIL_BY_INVITATION_TOKEN,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_EMAIL_BY_INVITATION_TOKEN)))
      .catch(() => dispatch(fetchError(GET_EMAIL_BY_INVITATION_TOKEN)));
  };
};

export const GET_ELECTRICITY_ACCOUNTS_CARD = `${ACTION_NAME}GET_ELECTRICITY_ACCOUNTS_CARD`;
export const getElectricityAccountsCard = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ELECTRICITY_ACCOUNTS_CARD));
    AccountService.getElectricityAccountsCard(bulkId)
      .then((response) => {
        dispatch({
          type: GET_ELECTRICITY_ACCOUNTS_CARD,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_ELECTRICITY_ACCOUNTS_CARD)))
      .catch(() => dispatch(fetchError(GET_ELECTRICITY_ACCOUNTS_CARD)));
  };
};

export const ADD_MANUALACCOUNTS  = `${ACTION_NAME}ADD_MANUALACCOUNTS`;
export const addManualAccounts = (accountNo: string = "", referenceId: string = "") => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ADD_MANUALACCOUNTS,
      payload: {
        accountNo,
        referenceId
      }
    });
  }
};

export const UPDATE_MANUALACCOUNT  = `${ACTION_NAME}UPDATE_MANUALACCOUNT`;
export const updateManualAccount = (uniqueId: string, propertyName: string = "", propertyValue: string = "") => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPDATE_MANUALACCOUNT));
    AccountService.validateBulkChildAccount(propertyValue)
      .then((response) => {
        dispatch({
          type: UPDATE_MANUALACCOUNT,
          payload: {
              uniqueId,
              propertyName,
              propertyValue,
              ...response
            },
        });
      })
      .then(() => dispatch(fetchSuccess(UPDATE_MANUALACCOUNT)))
      .catch(() => dispatch(fetchError(UPDATE_MANUALACCOUNT)));
  };
};

export const UPDATE_MANUALACCOUNT_NOVALIDATION  = `${ACTION_NAME}UPDATE_MANUALACCOUNT_NOVALIDATION`;
export const updateManualAccountNoValidation = (uniqueId: string, propertyName: string = "", propertyValue: string = "", errorTypes?: string[], shouldLoad?: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_MANUALACCOUNT_NOVALIDATION,
      payload: {
        uniqueId,
        propertyName,
        propertyValue,
        errorTypes,
        shouldLoad
      }
    });
  }
};

export const UPLOAD_MANUALACCOUNTS  = `${ACTION_NAME}UPLOAD_MANUALACCOUNTS`;
export const uploadManualAccounts = (file: File) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPLOAD_MANUALACCOUNTS));
    AccountService.uploadAddAccountExcel(file)
      .then((response) => {
        dispatch({
          type: UPLOAD_MANUALACCOUNTS,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(UPLOAD_MANUALACCOUNTS)))
      .catch(() => dispatch(fetchError(UPLOAD_MANUALACCOUNTS)));
  };
};

export const CLEAR_MANUALACCOUNTS  = `${ACTION_NAME}CLEAR_MANUALACCOUNTS`;
export const clearManualAccounts = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_MANUALACCOUNTS
    });
  }
};

export const REMOVE_MANUALACCOUNTS  = `${ACTION_NAME}REMOVE_MANUALACCOUNTS`;
export const removeManualAccounts = (uniqueId: number) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: REMOVE_MANUALACCOUNTS,
      payload: {
        uniqueId
      }
    });
  }
};

export const SAVE_MANUALACCOUNTS  = `${ACTION_NAME}SAVE_MANUALACCOUNTS`;
export const saveManualAccounts = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SAVE_MANUALACCOUNTS
    });
  }
};

export const UPDATE_AUTOACCOUNT  = `${ACTION_NAME}UPDATE_AUTOACCOUNT`;
export const updateAutoAccount = (accountNo: string, propertyName: string = "", propertyValue: string = "", hasError: boolean = false) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_AUTOACCOUNT,
      payload: {
        accountNo,
        propertyName,
        propertyValue,
        hasError
      }
    });
  }
};

export const SET_SELECTED_AUTOACCOUNT = `${ACTION_NAME}SET_SELECTED_AUTOACCOUNT`;
export const setSelectedAutoAccount = (accountNo: string, isSelect: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_AUTOACCOUNT,
      payload: {
        accountNo,
        isSelect: isSelect
      }
    })
  };
};

export const UPLOAD_DECLARATIONFORM  = `${ACTION_NAME}UPLOAD_DECLARATIONFORM`;
export const uploadDeclarationForm = (file: File, oldFile?: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPLOAD_DECLARATIONFORM));
    AccountService.uploadDeclarationForm(file, oldFile)
      .then((response) => {
        dispatch({
          type: UPLOAD_DECLARATIONFORM,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(UPLOAD_DECLARATIONFORM)))
      .catch(() => dispatch(fetchError(UPLOAD_DECLARATIONFORM)));
  };
};

export const REGISTER_BULKACCOUNT = `${ACTION_NAME}REGISTER_BULKACCOUNT`;
export const REGISTER_BULKACCOUNT_FAILED = `${ACTION_NAME}REGISTER_BULKACCOUNT_FAILED`;
export const registerBulkAccount = (registerObject: IRegisterObject) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(REGISTER_BULKACCOUNT));
    AccountService.registerBulkAccount(registerObject)
      .then((response) => {
        dispatch({
          type: REGISTER_BULKACCOUNT,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(REGISTER_BULKACCOUNT)))
      .catch((response) => {
        dispatch({
          type: REGISTER_BULKACCOUNT_FAILED,
          payload: response,
        });
        dispatch(fetchError(REGISTER_BULKACCOUNT));
      });
  };
};

export const UPDATE_REGISTRATION = `${ACTION_NAME}UPDATE_REGISTRATION`;
export const UPDATE_REGISTRATION_FAILED = `${ACTION_NAME}UPDATE_REGISTRATION_FAILED`;
export const updateRegistrationRecord = (registerObject: IRegisterObject) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPDATE_REGISTRATION));
    AccountService.updateRegistrationRecord(registerObject)
      .then((response) => {
        dispatch({
          type: UPDATE_REGISTRATION,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(UPDATE_REGISTRATION)))
      .catch((response) => {
        dispatch({
          type: UPDATE_REGISTRATION_FAILED,
          payload: response,
        });
        dispatch(fetchError(UPDATE_REGISTRATION));
      });
  };
};

export const GET_REGISTRATIONAPPLICATION = `${ACTION_NAME}GET_REGISTRATIONAPPLICATION`;
export const getRegistrationApplication = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_REGISTRATIONAPPLICATION));
    AccountService.getRegistrationApplication()
      .then((response) => {
        dispatch({
          type: GET_REGISTRATIONAPPLICATION,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_REGISTRATIONAPPLICATION)))
      .catch(() => dispatch(fetchError(GET_REGISTRATIONAPPLICATION)));
  };
};

export const GET_REGISTRATIONRECORD = `${ACTION_NAME}GET_REGISTRATIONRECORD`;
export const getRegistrationRecord = (registrationId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_REGISTRATIONRECORD));
    AccountService.getRegistrationRecord(registrationId)
      .then((response) => {
        dispatch({
          type: GET_REGISTRATIONRECORD,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_REGISTRATIONRECORD)))
      .catch(() => dispatch(fetchError(GET_REGISTRATIONRECORD)));
  };
};

export const GET_BULKREGISTRATIONSTATUS = `${ACTION_NAME}GET_BULKREGISTRATIONSTATUS`;
export const getBulkRegistrationStatus = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_BULKREGISTRATIONSTATUS));
    AccountService.getBulkRegistrationStatus()
      .then((response) => {
        dispatch({
          type: GET_BULKREGISTRATIONSTATUS,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_BULKREGISTRATIONSTATUS)))
      .catch(() => dispatch(fetchError(GET_BULKREGISTRATIONSTATUS)));
  };
};

export const UPDATE_ERRORACCOUNT  = `${ACTION_NAME}UPDATE_ERRORACCOUNT`;
export const updateErrorAccount = (uniqueId: string, propertyName: string = "", propertyValue: string = "") => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ERRORACCOUNT,
      payload: {
        uniqueId,
        propertyName,
        propertyValue
      }
    });
  };
};

export const SET_SELECTED_ERRORACCOUNT = `${ACTION_NAME}SET_SELECTED_ERRORACCOUNT`;
export const setSelectedErrorAccount = (uniqueId: string, isSelect: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_ERRORACCOUNT,
      payload: {
        uniqueId,
        isSelect: isSelect
      }
    })
  };
};
export const GET_GROUPS_TABLE_FOR_BULK_ID = `${ACTION_NAME}GET_GROUPS_TABLE_FOR_BULK_ID`;
export const getGroupsTableForBulkId = (bulkId: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_GROUPS_TABLE_FOR_BULK_ID));
    AccountService.getGroupsTableForBulkId(bulkId, recordCount)
      .then((response) => {
        dispatch({
          type: GET_GROUPS_TABLE_FOR_BULK_ID,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_GROUPS_TABLE_FOR_BULK_ID)))
      .catch(() => dispatch(fetchError(GET_GROUPS_TABLE_FOR_BULK_ID)));
  };
};

export const SET_SELECTED_GROUPS = `${ACTION_NAME}SET_SELECTED_GROUPS`;
export const setSelectedGroups = (groupId: string, isSelect: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_GROUPS,
      payload: {
        groupId, 
        isSelect,
      }
    })
  };
};

export const GET_ACCOUNTS_NOT_IN_GROUP = `${ACTION_NAME}GET_ACCOUNTS_NOT_IN_GROUP`;
export const getAccountsNotInGroup = (bulkId: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ACCOUNTS_NOT_IN_GROUP));
    AccountService.getAccountsNotInGroupTable(bulkId, recordCount)
      .then((response) => {
        dispatch({
          type: GET_ACCOUNTS_NOT_IN_GROUP,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_ACCOUNTS_NOT_IN_GROUP)))
      .catch(() => dispatch(fetchError(GET_ACCOUNTS_NOT_IN_GROUP)));
  };
};

export const SET_SELECTED_ACCOUNTS = `${ACTION_NAME}SET_SELECTED_ACCOUNTS`;
export const setSelectedAccounts = (accountNo: string, isSelect: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_ACCOUNTS,
      payload: {
        accountNo, 
        isSelect,
      }
    })
  };
};

export const GET_GROUP_DETAILS_FAILED = `${ACTION_NAME}GET_GROUP_DETAILS_FAILED`;
export const GET_GROUP_DETAILS = `${ACTION_NAME}GET_GROUP_DETAILS`;
export const getGroupDetails = (groupId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_GROUP_DETAILS));
    AccountService.getGroupDetails(groupId)
      .then((response) => {
        dispatch({
          type: GET_GROUP_DETAILS,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_GROUP_DETAILS)))
      .catch((response) => {
        dispatch({
          type: GET_GROUP_DETAILS_FAILED,
          payload: response,
        });
        dispatch(fetchError(GET_GROUP_DETAILS));
      });
  };
};

export const GET_ACCOUNTS_IN_GROUP_TABLE = `${ACTION_NAME}GET_ACCOUNTS_IN_GROUP_TABLE`;
export const getAccountsInGroupTable = (groupId: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ACCOUNTS_IN_GROUP_TABLE));
    AccountService.getAccountsInGroupTable(groupId, recordCount)
      .then((response) => {
        dispatch({
          type: GET_ACCOUNTS_IN_GROUP_TABLE,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_ACCOUNTS_IN_GROUP_TABLE)))
      .catch(() => dispatch(fetchError(GET_ACCOUNTS_IN_GROUP_TABLE)));
  };
};

export const SET_SELECTED_ACCOUNTS_IN_GROUP = `${ACTION_NAME}SET_SELECTED_ACCOUNTS_IN_GROUP`;
export const setSelectedAccountsInGroup = (accountNo: string, isSelect: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_ACCOUNTS_IN_GROUP,
      payload: {
        accountNo, 
        isSelect,
      }
    })
  };
};

export const UPDATE_GROUP_NAME_FAILED = `${ACTION_NAME}UPDATE_GROUP_NAME_FAILED`;
export const UPDATE_GROUP_NAME = `${ACTION_NAME}UPDATE_GROUP_NAME`;
export const updateGroupName = (groupId: string, groupName: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPDATE_GROUP_NAME));
    AccountService.updateGroupName(groupId, groupName)
      .then(() => dispatch(fetchSuccess(UPDATE_GROUP_NAME)))
      .catch((response) => {
        dispatch({
          type: UPDATE_GROUP_NAME_FAILED,
          payload: response,
        });
        dispatch(fetchError(UPDATE_GROUP_NAME));
      });
  };
};

export const GET_BULKIDBYROC = `${ACTION_NAME}GET_BULKIDBYROC`;
export const getBulkIdByROC = (roc: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_BULKIDBYROC));
    AccountService.getBulkIdByROC(roc)
      .then((response) => {
        dispatch({
          type: GET_BULKIDBYROC,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_BULKIDBYROC)))
      .catch(() => dispatch(fetchError(GET_BULKIDBYROC)));
  };
};

export const UPDATE_MANUAL_ACCOUNT_IN_ACCOUNT = `${ACTION_NAME}UPDATE_MANUAL_ACCOUNT_IN_ACCOUNT`;
export const updateManualAccountInAccount = (accounts: object[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_MANUAL_ACCOUNT_IN_ACCOUNT,
      payload: accounts,
    })
  };
};

export const CLEAR_MANUAL_ACCOUNTS_IN_ACCOUNT  = `${ACTION_NAME}CLEAR_MANUAL_ACCOUNTS_IN_ACCOUNT`;
export const clearManualAccountsInAccount = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_MANUAL_ACCOUNTS_IN_ACCOUNT,
    });
  }
};

export const CLEAR_AUTO_ACCOUNTS_IN_ACCOUNT  = `${ACTION_NAME}CLEAR_AUTO_ACCOUNTS_IN_ACCOUNT`;
export const clearAutoAccountsInAccount = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_AUTO_ACCOUNTS_IN_ACCOUNT,
    });
  }
};

export const SET_SELECTED_AUTO_DETECTED_ACCOUNT = `${ACTION_NAME}SET_SELECTED_AUTO_DETECTED_ACCOUNT`;
export const setSelectedAutoDetectedAccount = (accountNo: string, isSelect: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_AUTO_DETECTED_ACCOUNT,
      payload: {
        accountNo,
        isSelect: isSelect
      }
    })
  };
};

export const UPDATE_AUTO_DETECTED_TABLE  = `${ACTION_NAME}UPDATE_AUTO_DETECTED_TABLE`;
export const updateAutoDetectedTable = (accountNo: string, referenceId: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_AUTO_DETECTED_TABLE,
      payload: {
        accountNo,
        referenceId,
      }
    });
  }
};

export const UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT  = `${ACTION_NAME}UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT`;
export const uploadManualAccountsInAccount = (file: File) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT));
    AccountService.uploadAddAccountExcel(file)
      .then((response) => {
        dispatch({
          type: UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT)))
      .catch(() => dispatch(fetchError(UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT)));
  };
};

export const VALIDATE_BULK_CHILD_ACCOUNT  = `${ACTION_NAME}VALIDATE_BULK_CHILD_ACCOUNT`;
export const validateBulkChildAccount = (accountNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(VALIDATE_BULK_CHILD_ACCOUNT));
    AccountService.validateBulkChildAccount(accountNo)
      .then((response) => {
        dispatch({
          type: VALIDATE_BULK_CHILD_ACCOUNT,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(VALIDATE_BULK_CHILD_ACCOUNT)))
      .catch(() => dispatch(fetchError(VALIDATE_BULK_CHILD_ACCOUNT)));
  };
};

export const GET_PENDING_CA = `${ACTION_NAME}GET_PENDING_CA`;
export const getPendingCA = (bulkId: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PENDING_CA));
    AccountService.getPendingCA(bulkId, recordCount)
      .then((response) => {
        dispatch({
          type: GET_PENDING_CA,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_PENDING_CA)))
      .catch(() => dispatch(fetchError(GET_PENDING_CA)));
  };
};

export const REMOVE_ACCOUNT_FROM_PENDING_CA_FAILED = `${ACTION_NAME}REMOVE_ACCOUNT_FROM_PENDING_CA_FAILED`;
export const REMOVE_ACCOUNT_FROM_PENDING_CA = `${ACTION_NAME}REMOVE_ACCOUNT_FROM_PENDING_CA`;
export const removeAccountFromPendingCA = (bulkId: string, selectedAccounts: string[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(REMOVE_ACCOUNT_FROM_PENDING_CA));
    AccountService.removeAccountFromPendingCA(bulkId, selectedAccounts)
      .then(() => dispatch(fetchSuccess(REMOVE_ACCOUNT_FROM_PENDING_CA)))
      .catch((response) => {
        dispatch({
          type: REMOVE_ACCOUNT_FROM_PENDING_CA_FAILED,
          payload: response,
        });
        dispatch(fetchError(REMOVE_ACCOUNT_FROM_PENDING_CA));
      });
  };
};

export const SUBMIT_PENDING_CA_FAILED = `${ACTION_NAME}SUBMIT_PENDING_CA_FAILED`;
export const SUBMIT_PENDING_CA = `${ACTION_NAME}SUBMIT_PENDING_CA`;
export const submitPendingCA = (bulkId: string, selectedAccounts: object[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(SUBMIT_PENDING_CA));
    AccountService.submitPendingCA(bulkId, selectedAccounts)
      .then(() => {
        dispatch({
          type: SUBMIT_PENDING_CA,
          payload: selectedAccounts.length,
        });
      })
      .then(() => dispatch(fetchSuccess(SUBMIT_PENDING_CA)))
      .catch((response) => {
        dispatch({
          type: SUBMIT_PENDING_CA_FAILED,
          payload: response,
        });
        dispatch(fetchError(SUBMIT_PENDING_CA));
      });
  };
};

export const VALIDATE_BULK_CA_IN_PENDING_CA  = `${ACTION_NAME}VALIDATE_BULK_CA_IN_PENDING_CA`;
export const validateBulkCAInPendingCA = (accountNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(VALIDATE_BULK_CA_IN_PENDING_CA));
    AccountService.validateBulkChildAccount(accountNo)
      .then((response) => {
        dispatch({
          type: VALIDATE_BULK_CA_IN_PENDING_CA,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(VALIDATE_BULK_CA_IN_PENDING_CA)))
      .catch(() => dispatch(fetchError(VALIDATE_BULK_CA_IN_PENDING_CA)));
  };
};

export const GET_AUTO_SUGGESTIVE_ACCOUNTS = `${ACTION_NAME}GET_AUTO_SUGGESTIVE_ACCOUNTS`;
export const getAutoSuggestiveAccounts = (roc: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_AUTO_SUGGESTIVE_ACCOUNTS));
    AccountService.getAutoSuggestiveAccounts(roc)
      .then((response) => {
        dispatch({
          type: GET_AUTO_SUGGESTIVE_ACCOUNTS,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_AUTO_SUGGESTIVE_ACCOUNTS)))
      .catch(() => dispatch(fetchSuccess(GET_AUTO_SUGGESTIVE_ACCOUNTS)));
  };
};

export const GET_AUTO_SUGGESTIVE_IN_ACCOUNT = `${ACTION_NAME}GET_AUTO_SUGGESTIVE_IN_ACCOUNT`;
export const getAutoSuggestiveInAccount = (roc: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_AUTO_SUGGESTIVE_IN_ACCOUNT));
    AccountService.getAutoSuggestiveAccounts(roc)
      .then((response) => {
        dispatch({
          type: GET_AUTO_SUGGESTIVE_IN_ACCOUNT,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_AUTO_SUGGESTIVE_IN_ACCOUNT)))
      .catch(() => dispatch(fetchSuccess(GET_AUTO_SUGGESTIVE_IN_ACCOUNT)));
  };
};

export const SET_REGISTRATION_USER_TYPE  = `${ACTION_NAME}SET_REGISTRATION_USER_TYPE`;
export const setRegistrationUserType = (accountType: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_REGISTRATION_USER_TYPE,
      payload: {
        accountType,
      }
    });
  }
};

export const UPDATE_USER_PROFILE_FAILED = `${ACTION_NAME}UPDATE_USER_PROFILE_FAILED`;
export const UPDATE_USER_PROFILE = `${ACTION_NAME}UPDATE_USER_PROFILE`;
export const updateUserProfile = (name: string, mobileNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPDATE_USER_PROFILE))
    AccountService.updateUserProfile(name, mobileNo)
      .then(() => dispatch(fetchSuccess(UPDATE_USER_PROFILE)))
      .catch((response) => {
        dispatch({
          type: UPDATE_USER_PROFILE_FAILED,
          payload: response,
        });
        dispatch(fetchError(UPDATE_USER_PROFILE));
      });
  };
};

export const GET_REGISTRATION_DEFAULT_VALUES_FAILED = `${ACTION_NAME}GET_REGISTRATION_DEFAULT_VALUES_FAILED`;
export const GET_REGISTRATION_DEFAULT_VALUES = `${ACTION_NAME}GET_REGISTRATION_DEFAULT_VALUES`;
export const getRegistrationDefaultValues = (rocNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_REGISTRATION_DEFAULT_VALUES));
    AccountService.getRegistrationDefaultValues(rocNo)
      .then((response) => {
        dispatch({
          type: GET_REGISTRATION_DEFAULT_VALUES,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_REGISTRATION_DEFAULT_VALUES)))
      .catch((response) => {
        dispatch({
          type: GET_REGISTRATION_DEFAULT_VALUES_FAILED,
          payload: response,
        });
        dispatch(fetchError(GET_REGISTRATION_DEFAULT_VALUES));
      });
  };
};
