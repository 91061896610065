import { Dispatch } from "redux";

import * as adminUserService from "src/services/admin/userService";
import { fetchStart, fetchSuccess, fetchError } from "../fetchAction";

export const ACTION_NAME: string = "ADMIN_USER_";

export const SEARCH_CVM = `${ACTION_NAME}SEARCH_CVM`;
export const searchCVM = (searchBy: string, searchValue: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(SEARCH_CVM));
    adminUserService.searchCVM(searchBy, searchValue)
      .then(response =>
        dispatch({
          type: SEARCH_CVM,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(SEARCH_CVM)))
      .catch(() => dispatch(fetchError(SEARCH_CVM)));
  };
};

export const GET_USERS = `${ACTION_NAME}GET_USERS`;
export const getUsers = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_USERS));
    adminUserService.getUsers()
      .then((response) => {
        dispatch({
          type: GET_USERS,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_USERS)))
      .catch(() => dispatch(fetchError(GET_USERS)));
  };
};

export const GET_MANAGE_USERS_CARDS = `${ACTION_NAME}GET_MANAGE_USERS_CARDS`;
export const getManageUsersCards = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_MANAGE_USERS_CARDS))
    adminUserService.getManageUsersCards(bulkId)
      .then(response =>
        dispatch({
          type: GET_MANAGE_USERS_CARDS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_MANAGE_USERS_CARDS)))
      .catch(() => dispatch(fetchError(GET_MANAGE_USERS_CARDS)));
  };
};

export const GET_MANAGE_USERS = `${ACTION_NAME}GET_MANAGE_USERS`;
export const getManageUsersForBulkId = (bulkId: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_MANAGE_USERS))
    adminUserService.getManageUsersForBulkId(bulkId, recordCount)
      .then(response =>
        dispatch({
          type: GET_MANAGE_USERS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_MANAGE_USERS)))
      .catch(() => dispatch(fetchError(GET_MANAGE_USERS)));
  };
};

export const ADD_NEW_USER_FAILED = `${ACTION_NAME}ADD_NEW_USER_FAILED`;
export const ADD_NEW_USER = `${ACTION_NAME}ADD_NEW_USER`;
export const addNewUser = (bulkId: string, newUser: object) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(ADD_NEW_USER))
    adminUserService.addNewUser(bulkId, newUser)
      .then(response =>
        dispatch({
          type: ADD_NEW_USER,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(ADD_NEW_USER)))
      .catch((response) => {
        dispatch({
          type: ADD_NEW_USER_FAILED,
          payload: response,
        });
        dispatch(fetchError(ADD_NEW_USER));
      });
  };
};

export const SET_ADD_USER_ERROR = `${ACTION_NAME}SET_ADD_USER_ERROR`;
export const setAddUserError = (errorTopic: string, errorMessage: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_ADD_USER_ERROR,
      payload: {
        errorTopic,
        errorMessage,
      }
    });
  };
};

export const UPDATE_BULK_USER_FAILED = `${ACTION_NAME}UPDATE_BULK_USER_FAILED`;
export const UPDATE_BULK_USER = `${ACTION_NAME}UPDATE_BULK_USER`;
export const updateBulkUser = (userId: string, nickname: string, role: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPDATE_BULK_USER))
    adminUserService.updateBulkUser(userId, nickname, role)
      .then(response =>
        dispatch({
          type: UPDATE_BULK_USER,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(UPDATE_BULK_USER)))
      .catch((response) => {
        dispatch({
          type: UPDATE_BULK_USER_FAILED,
          payload: response,
        });
        dispatch(fetchError(UPDATE_BULK_USER));
      });
  };
};

export const DELETE_BULK_USER_FAILED = `${ACTION_NAME}DELETE_BULK_USER_FAILED`;
export const DELETE_BULK_USER = `${ACTION_NAME}DELETE_BULK_USER`;
export const deleteBulkUser = (userId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(DELETE_BULK_USER))
    adminUserService.deleteBulkUser(userId)
      .then(response =>
        dispatch({
          type: DELETE_BULK_USER,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_BULK_USER)))
      .catch((response) => {
        dispatch({
          type: DELETE_BULK_USER_FAILED,
          payload: response,
        });
        dispatch(fetchError(DELETE_BULK_USER));
      });
  };
};

export const TRANSFER_MASTER_ROLE_FAILED = `${ACTION_NAME}TRANSFER_MASTER_ROLE_FAILED`;
export const TRANSFER_MASTER_ROLE = `${ACTION_NAME}TRANSFER_MASTER_ROLE`;
export const transferMasterRole = (userId: string, nickname: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(TRANSFER_MASTER_ROLE))
    adminUserService.transferMasterRole(userId, nickname)
      .then(() => dispatch(fetchSuccess(TRANSFER_MASTER_ROLE)))
      .catch((response) => {
        dispatch({
          type: TRANSFER_MASTER_ROLE_FAILED,
          payload: response,
        });
        dispatch(fetchError(TRANSFER_MASTER_ROLE));
      });
  };
};

export const GET_TNBADMINSCARDS = `${ACTION_NAME}GET_TNBADMINSCARDS`;
export const getTNBAdminsCards = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_TNBADMINSCARDS));
    adminUserService.getTNBAdminsCards()
      .then(response =>
        dispatch({
          type: GET_TNBADMINSCARDS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_TNBADMINSCARDS)))
      .catch(() => dispatch(fetchError(GET_TNBADMINSCARDS)));
  };
};

export const GET_TNBADMINSTABLE = `${ACTION_NAME}GET_TNBADMINSTABLE`;
export const getTNBAdminsTable = (recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_TNBADMINSTABLE));
    adminUserService.getTNBAdminsTable(recordCount)
      .then(response =>
        dispatch({
          type: GET_TNBADMINSTABLE,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_TNBADMINSTABLE)))
      .catch(() => dispatch(fetchError(GET_TNBADMINSTABLE)));
  };
};

export const ADD_TNBADMIN = `${ACTION_NAME}ADD_TNBADMIN`;
export const ADD_TNBADMIN_FAILED = `${ACTION_NAME}ADD_TNBADMIN_FAILED`;
export const addTNBAdmin = (emailAddress: string, username: string, nickname: string, mobileNo: string, role: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(ADD_TNBADMIN));
    adminUserService.addTNBAdmin(emailAddress, username, nickname, mobileNo, role)
      .then(response =>
        dispatch({
          type: ADD_TNBADMIN,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(ADD_TNBADMIN)))
      .catch((response) => {
        dispatch({
          type: ADD_TNBADMIN_FAILED,
          payload: response,
        });
        dispatch(fetchError(ADD_TNBADMIN));
      });
  };
};

export const UPDATE_TNBADMIN = `${ACTION_NAME}UPDATE_TNBADMIN`;
export const UPDATE_TNBADMIN_FAILED = `${ACTION_NAME}UPDATE_TNBADMIN_FAILED`;
export const updateTNBAdmin = (userId: string, username: string, nickname: string, mobileNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPDATE_TNBADMIN));
    adminUserService.updateTNBAdmin(userId, username, nickname, mobileNo)
      .then(response =>
        dispatch({
          type: UPDATE_TNBADMIN,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(UPDATE_TNBADMIN)))
      .catch((response) => {
        dispatch({
          type: UPDATE_TNBADMIN_FAILED,
          payload: response,
        });
        dispatch(fetchError(UPDATE_TNBADMIN));
      });
  };
};

export const DELETE_TNBADMIN = `${ACTION_NAME}DELETE_TNBADMIN`;
export const DELETE_TNBADMIN_FAILED = `${ACTION_NAME}DELETE_TNBADMIN_FAILED`;
export const deleteTNBAdmin = (userId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(DELETE_TNBADMIN));
    adminUserService.deleteTNBAdmin(userId)
      .then(response =>
        dispatch({
          type: DELETE_TNBADMIN,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_TNBADMIN)))
      .catch((response) => {
        dispatch({
          type: DELETE_TNBADMIN_FAILED,
          payload: response,
        });
        dispatch(fetchError(DELETE_TNBADMIN));
      });
  };
};

export const RESEND_ACTIVATIONTNBADMIN = `${ACTION_NAME}RESEND_ACTIVATIONTNBADMIN`;
export const RESEND_ACTIVATIONTNBADMIN_FAILED = `${ACTION_NAME}RESEND_ACTIVATIONTNBADMIN_FAILED`;
export const resendActivationTNBAdmin = (userId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(RESEND_ACTIVATIONTNBADMIN));
    adminUserService.resendActivationTNBAdmin(userId)
      .then(response =>
        dispatch({
          type: RESEND_ACTIVATIONTNBADMIN,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(RESEND_ACTIVATIONTNBADMIN)))
      .catch((response) => {
        dispatch({
          type: RESEND_ACTIVATIONTNBADMIN_FAILED,
          payload: response,
        });
        dispatch(fetchError(RESEND_ACTIVATIONTNBADMIN));
      });
  };
};

export const UPDATE_CVM_SEARCH_RESULT = `${ACTION_NAME}UPDATE_CVM_SEARCH_RESULT`;
export const updateCVMSearchResult = (searchBy: string, searchResultValue: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CVM_SEARCH_RESULT,
      payload: {
        searchBy,
        searchResultValue,
      },
    });
  };
};

export const GET_TNBUSERSCARDS = `${ACTION_NAME}GET_TNBUSERSCARDS`;
export const getTNBUsersCards = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_TNBUSERSCARDS));
    adminUserService.getTNBUsersCards()
      .then(response =>
        dispatch({
          type: GET_TNBUSERSCARDS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_TNBUSERSCARDS)))
      .catch(() => dispatch(fetchError(GET_TNBUSERSCARDS)));
  };
};

export const GET_TNBUSERSTABLE = `${ACTION_NAME}GET_TNBUSERSTABLE`;
export const getTNBUsersTable = (recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_TNBUSERSTABLE));
    adminUserService.getTNBUsersTable(recordCount)
      .then(response =>
        dispatch({
          type: GET_TNBUSERSTABLE,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_TNBUSERSTABLE)))
      .catch(() => dispatch(fetchError(GET_TNBUSERSTABLE)));
  };
};

export const DELETE_TNBUSER = `${ACTION_NAME}DELETE_TNBUSER`;
export const deleteTNBUser = (userId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(DELETE_TNBUSER));
    adminUserService.deleteTNBUser(userId)
      .then(response =>
        dispatch({
          type: DELETE_TNBUSER,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_TNBUSER)))
      .catch(() => dispatch(fetchError(DELETE_TNBUSER)));
  };
};

export const RESEND_ACTIVATIONTNBUSER = `${ACTION_NAME}RESEND_ACTIVATIONTNBUSER`;
export const resendActivationTNBUser = (userId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(RESEND_ACTIVATIONTNBUSER));
    adminUserService.resendActivationTNBUser(userId)
      .then(response =>
        dispatch({
          type: RESEND_ACTIVATIONTNBUSER,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(RESEND_ACTIVATIONTNBUSER)))
      .catch(() => dispatch(fetchError(RESEND_ACTIVATIONTNBUSER)));
  };
};