import _ from 'lodash';
import * as homeActions from '../actions/homeAction';
import { IReducerAction, IReducer, IPayloadObj } from './_commonInterface';

interface IBillDataForGraph {
  billMonthYear: string | null;
  billInvoicesIssued: string | null;
  totalBill: string | null;
  latestBillDate: string | null;
}

interface IPaymentDataForGraph {
  paidMonthYear: string | null;
  paidInvoices: string | null;
  totalPaid: string | null;
  latestPaymentDate: string | null;
}

interface IAccountByState {
  state: string | null,
  count: number | null,
}

type NullableNumberString = number | string | null;

interface IHomeReducer {
  paymentsRejected: NullableNumberString;
  unpaidAccounts: NullableNumberString;
  unpaidInvoices: NullableNumberString;
  totalOutstandingCharges: NullableNumberString;
  billDataForGraph: Array<IBillDataForGraph> | null;
  paymentDataForGraph: Array<IPaymentDataForGraph> | null;
  extractionDate: NullableNumberString,
  collectiveInvoiceNo: NullableNumberString,
  currentCharge: NullableNumberString,
  outstandingAmount: NullableNumberString,
  totalAmountPayable: NullableNumberString,
  totalPayableCharges: NullableNumberString,
  collectiveInvoiceDueDate: NullableNumberString,
  totalChildAccounts: number | null,
  inactiveAccounts: number | null,
  accountByState: Array<IAccountByState> | null,
  guidance: {
    [guidanceId: string]: {
      currentStep: number,
      maxStep: number
    }
  }
}

const defaultState: IHomeReducer = {
  paymentsRejected: '-',
  unpaidAccounts: '-',
  unpaidInvoices: '-',
  totalOutstandingCharges: '-',
  billDataForGraph: [],
  paymentDataForGraph: [],
  extractionDate: '-',
  collectiveInvoiceNo: '-',
  currentCharge: '-',
  outstandingAmount: '-',
  totalAmountPayable: '-',
  totalPayableCharges: '-',
  collectiveInvoiceDueDate: '-',
  totalChildAccounts: null,
  inactiveAccounts: null,
  accountByState: null,
  guidance: {
    home: {
      currentStep: 0,
      maxStep: 9
    },
    billPayment: {
      currentStep: 0,
      maxStep: 5
    },
    payments: {
      currentStep: 0,
      maxStep: 3
    },
    account: {
      currentStep: 0,
      maxStep: 1
    },
    masterAccount: {
      currentStep: 0,
      maxStep: 6
    },
    viewerAccount: {
      currentStep: 0,
      maxStep: 3
    },
    addAccount: {
      currentStep: 0,
      maxStep: 1
    },
    editAccount: {
      currentStep: 0,
      maxStep: 3
    },
    adminPayment: {
      currentStep: 0,
      maxStep: 4
    },
    adminBulkAccounts: {
      currentStep: 0,
      maxStep: 2
    },
    adminManageBulkAccounts: {
      currentStep: 0,
      maxStep: 3
    },
    adminManageElectricityAccTutorialPCCR: {
      currentStep: 0,
      maxStep: 6
    },
    adminManageElectricityAccTutorialGLB: {
      currentStep: 0,
      maxStep: 4
    },
    manageUserPCCR: {
      currentStep: 0,
      maxStep: 4
    },
    manageUserGLB: {
      currentStep: 0,
      maxStep: 2
    },
    adminManageTNBUser: {
      currentStep: 0,
      maxStep: 3
    },
    masterManageUser: {
      currentStep: 0,
      maxStep: 5
    },
    nonMasterManageUser: {
      currentStep: 0,
      maxStep: 2
    },
    masterAccountGroup: {
      currentStep: 0,
      maxStep: 3
    },
    accountMemberAccountGroup: {
      currentStep: 0,
      maxStep: 2
    },
    paymentMemberAccountGroup: {
      currentStep: 0,
      maxStep: 2
    },
    viewerAccountGroup: {
      currentStep: 0,
      maxStep: 1
    },
    profile: {
      currentStep: 0,
      maxStep: 1
    },
    adminProfile: {
      currentStep: 0,
      maxStep: 1
    },
  }
}

const REDUCERS: IReducer = {
  [homeActions.GET_TOP_3_CARDS]: (state: IHomeReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      paymentsRejected: _.get(payload, 'paymentsRejected') ?? '-',
      unpaidAccounts: _.get(payload, 'unpaidAccounts') ?? '-',
      totalAmountPayable: _.get(payload, 'totalAmountPayable') ?? '-',
      unpaidInvoices: _.get(payload, 'unpaidInvoices', '-'),
    }
  },
  [homeActions.GET_BILL_DATA_FOR_GRAPH]: (state: IHomeReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      billDataForGraph: payload,
    }
  },
  [homeActions.GET_PAYMENT_DATA_FOR_GRAPH]: (state: IHomeReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      paymentDataForGraph: payload,
    }
  },
  [homeActions.GET_LATEST_BILL_SUMMARY]: (state: IHomeReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      extractionDate: _.get(payload, "extractionDateTime") && !_.isNull(payload.extractionDateTime) ? payload.extractionDateTime + "Z" : "-",
      collectiveInvoiceNo: _.get(payload, 'collectiveInvoiceNo') ?? '-',
      currentCharge: _.get(payload, 'currentCharge') ?? '-',
      outstandingAmount: _.get(payload, 'outstandingAmount') ?? '-',
      totalPayableCharges: _.get(payload, 'totalAmountPayable') ?? '-',
      collectiveInvoiceDueDate: _.get(payload, 'collectiveInvoiceDueDate') ?? '-',
    }
  },
  [homeActions.GET_ACCOUNT_CARDS]: (state: IHomeReducer, { payload }: IPayloadObj) => {
    const { totalChildAccounts, inactiveAccounts, accountByState } = payload;
    return {
      ...state,
      totalChildAccounts,
      inactiveAccounts,
      accountByState,
    }
  },
  [homeActions.SET_GUIDANCESTEP]: (state: IHomeReducer, { payload }: IPayloadObj) => {
    const { id, isIncrease, isReset } = payload;
    const selectedGuidance = state.guidance[id];

    if (isReset)
      selectedGuidance.currentStep = 0;
    else
      selectedGuidance.currentStep = isIncrease ? ++selectedGuidance.currentStep : --selectedGuidance.currentStep;
    return {
      ...state,
      guidance: {
        ...state.guidance,
        selectedGuidance
      } 
    }
  }
};

const homeReducer = (state: IHomeReducer = defaultState, { type, payload, error }: IReducerAction = { type: '' }) => {
  if (!type)
    return state;

  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
}
export default homeReducer;
export const REDUCER_HOME = 'homeReducer';
