import { B2B_API_INSTANCE } from "../index";

const SERVICE_VERSION: number = 1;
const CONTROLLER_NAME: string = `/user-svc/api/v${SERVICE_VERSION}/Admin`;
const BULK_ACCOUNT_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/BulkAccount`;

export const searchCVM = (searchBy: string, searchValue: string) => {
  const postBody = {
    criteria: searchBy,
    searchString: searchValue,
  };
  return B2B_API_INSTANCE.post(`${BULK_ACCOUNT_CONTROLLER_NAME}/SearchCustomerViewMode`, postBody);
};

export const getUsers = () => {
  return B2B_API_INSTANCE.get(`${CONTROLLER_NAME}/getUsers`);
};

export const getManageUsersForBulkId = (bulkId: string, recordCount: number) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/GetManageUsersTableByBulkId/${bulkId}`, { recordCount });
};

export const getManageUsersCards = (bulkId: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/GetManageUsersCardsByBulkId/${bulkId}`);
};

export const addNewUser = (bulkId: string, newUser: object) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/AddBulkUserByBulkId/${bulkId}`, newUser);
};

export const updateBulkUser = (userId: string, nickname: string, role: string) => {
  const postBody = {
    userId,
    nickname,
    role,
  };
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/UpdateBulkUser`, postBody);
};

export const deleteBulkUser = (userId: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/DeleteBulkUser`, { userId });
};

export const transferMasterRole = (userId: string, nickname: string) => {
  const postBody = {
    userId,
    nickname,
  };
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/TransferMasterBulkUser`, postBody);
};

export const getTNBAdminsCards = () => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/GetTNBUsersCards`);
};

export const getTNBAdminsTable = (recordCount: number) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/GetTNBUsersTable`, { recordCount });
};

export const addTNBAdmin = (emailAddress: string, username: string, nickname: string, mobileNo: string, role: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/AddTNBUser`, { 
    emailAddress,
    username,
    nickname,
    mobileNo,
    role
   });
};

export const updateTNBAdmin = (userId: string, username: string, nickname: string, mobileNo: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/UpdateTNBUser`, { 
    userId,
    username,
    nickname,
    mobileNo
   });
};

export const deleteTNBAdmin = (userId: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/DeleteTNBUser`, { userId });
};

export const resendActivationTNBAdmin = (userId: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/ResendActivationTNBUser`, { userId });
};

export const getTNBUsersCards = () => {
  return B2B_API_INSTANCE.get(`${CONTROLLER_NAME}/GetUsersCards`);
};

export const getTNBUsersTable = (recordCount: number) => {
  return B2B_API_INSTANCE.get(`${CONTROLLER_NAME}/GetUserTable?count=${recordCount}`);
};

export const deleteTNBUser = (userId: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/DeleteUser?userId=${userId}`);
};

export const resendActivationTNBUser = (userId: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/SendNewActivationLink?userId=${userId}`);
};