import _ from "lodash";

export const STATUS_NAME = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  INVITEPENDING: "invitePending"
}

export const STATUS_TYPE = {
  "1": {
    code: "1",
    name: STATUS_NAME.ACTIVE,
    label: "components.filter.active",
  },
  "2": {
    code: "2",
    name: STATUS_NAME.INACTIVE,
    label: "components.filter.inactive",
  },
  "3": {
    code: "3",
    name: STATUS_NAME.INVITEPENDING,
    label: "components.filter.invitePending",
  }
};

export const STATUSTYPE_AS_NAME = _.mapKeys(STATUS_TYPE, (value:any) => value.name);