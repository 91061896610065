import { Dispatch } from "redux";

import * as PaymentService from "src/services/admin/paymentService";
import { fetchStart, fetchSuccess, fetchError } from "../fetchAction";
//Helpers
import { handleBlobFileDownload } from "src/helpers/downloadHelper";

export const ACTION_NAME: string = "ADMIN_PAYMENT_";

export const GET_PENDING_APPROVAL_CARDS = `${ACTION_NAME}GET_PENDING_APPROVAL_CARDS`;
export const getPendingApprovalCards = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PENDING_APPROVAL_CARDS))
    PaymentService.getPendingApprovalCards()
      .then(response =>
        dispatch({
          type: GET_PENDING_APPROVAL_CARDS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_PENDING_APPROVAL_CARDS)))
      .catch(() => dispatch(fetchError(GET_PENDING_APPROVAL_CARDS)));
  };
};

export const GET_PENDING_APPROVAL_TABLE = `${ACTION_NAME}GET_PENDING_APPROVAL_TABLE`;
export const getPendingApprovalTable = (recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PENDING_APPROVAL_TABLE))
    PaymentService.getPendingApprovalTable(recordCount)
      .then(response =>
        dispatch({
          type: GET_PENDING_APPROVAL_TABLE,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_PENDING_APPROVAL_TABLE)))
      .catch(() => dispatch(fetchError(GET_PENDING_APPROVAL_TABLE)));
  };
};

export const GET_ALL_PAYMENTS_CARDS = `${ACTION_NAME}GET_ALL_PAYMENTS_CARDS`;
export const getAllPaymentsCards = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ALL_PAYMENTS_CARDS))
    PaymentService.getAllPaymentsCards()
      .then(response =>
        dispatch({
          type: GET_ALL_PAYMENTS_CARDS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_ALL_PAYMENTS_CARDS)))
      .catch(() => dispatch(fetchError(GET_ALL_PAYMENTS_CARDS)));
  };
};

export const GET_ALL_PAYMENTS_TABLE = `${ACTION_NAME}GET_ALL_PAYMENTS_TABLE`;
export const getAllPaymentsTable = (recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ALL_PAYMENTS_TABLE))
    PaymentService.getAllPaymentsTable(recordCount)
      .then(response =>
        dispatch({
          type: GET_ALL_PAYMENTS_TABLE,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_ALL_PAYMENTS_TABLE)))
      .catch(() => dispatch(fetchError(GET_ALL_PAYMENTS_TABLE)));
  };
};

export const GET_PAYMENT_DETAILS = `${ACTION_NAME}GET_PAYMENT_DETAILS`;
export const getPaymentDetails = (transactionNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PAYMENT_DETAILS))
    PaymentService.getPaymentDetails(transactionNo)
      .then(response =>
        dispatch({
          type: GET_PAYMENT_DETAILS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_PAYMENT_DETAILS)))
      .catch(() => dispatch(fetchError(GET_PAYMENT_DETAILS)));
  };
};

export const RECONCILE_PAYMENT = `${ACTION_NAME}RECONCILE_PAYMENT`;
export const reconcilePayment = (eBulkPaymentID: string, reconcileAmount: number, reconcileType: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(RECONCILE_PAYMENT))
    PaymentService.reconcilePayment(eBulkPaymentID, reconcileAmount, reconcileType)
      .then(() => dispatch(fetchSuccess(RECONCILE_PAYMENT)))
      .catch(() => dispatch(fetchError(RECONCILE_PAYMENT)));
  };
};

export const APPROVE_PAYMENT = `${ACTION_NAME}APPROVE_PAYMENT`;
export const approvePayment = (eBulkPaymentID: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(APPROVE_PAYMENT))
    PaymentService.approvePayment(eBulkPaymentID)
      .then(() => dispatch(fetchSuccess(APPROVE_PAYMENT)))
      .catch(() => dispatch(fetchError(APPROVE_PAYMENT)));
  };
};

export const DECLINE_PAYMENT = `${ACTION_NAME}DECLINE_PAYMENT`;
export const declinePayment = (eBulkPaymentID: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(DECLINE_PAYMENT))
    PaymentService.declinePayment(eBulkPaymentID)
      .then(() => dispatch(fetchSuccess(DECLINE_PAYMENT)))
      .catch(() => dispatch(fetchError(DECLINE_PAYMENT)));
  };
};

export const GET_ADMIN_PAYMENT_DETAILS_EXCEL = `${ACTION_NAME}GET_ADMIN_PAYMENT_DETAILS_EXCEL`;
export const getAdminPaymentDetailsExcel = (transactionNo: string, fileName: string = 'Payment Details Report.xlsx') => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ADMIN_PAYMENT_DETAILS_EXCEL))
    PaymentService.getAdminPaymentDetailsExcel(transactionNo)
      .then((response: any) => {
        handleBlobFileDownload(response, fileName);
      })
      .then(() => dispatch(fetchSuccess(GET_ADMIN_PAYMENT_DETAILS_EXCEL)))
      .catch(() => dispatch(fetchError(GET_ADMIN_PAYMENT_DETAILS_EXCEL)));
  };
};

export const GET_FPX_REQUERYDATA = `${ACTION_NAME}GET_FPX_REQUERYDATA`;
export const getFPXRequeryData = (merchantTranId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_FPX_REQUERYDATA));
    PaymentService.getFPXRequeryData(merchantTranId)
      .then(response =>
        dispatch({
          type: GET_FPX_REQUERYDATA,
          payload: {
            ...response,
            merchantTransactionId: merchantTranId,
          }
        })
      )
      .then(() => dispatch(fetchSuccess(GET_FPX_REQUERYDATA)))
      .catch(() => dispatch(fetchError(GET_FPX_REQUERYDATA)));
  };
};

export const SET_ISQUERYING = `${ACTION_NAME}SET_ISQUERYING`;
export const setIsQuerying = (isQuerying:boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: `${ACTION_NAME}SET_ISQUERYING`,
      payload: isQuerying
    });
  };
};

export const PERFORM_FPX_REQUERY = `${ACTION_NAME}PERFORM_FPX_REQUERY`;
export const PERFORM_FPX_REQUERY_FAILED = `${ACTION_NAME}PERFORM_FPX_REQUERY_FAILED`;
export const performFPXRequery = (merchantTranId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(PERFORM_FPX_REQUERY));
    PaymentService.performManualRequest(merchantTranId)
      .then(response =>
        dispatch({
          type: PERFORM_FPX_REQUERY,
          payload: {
            ...response,
            merchantTransactionId: merchantTranId,
          }
        })
      )
      .then(() => dispatch(fetchSuccess(PERFORM_FPX_REQUERY)))
      .catch((response) => {
        dispatch({
          type: PERFORM_FPX_REQUERY_FAILED,
          payload: response,
        });
        dispatch(fetchError(PERFORM_FPX_REQUERY));
      });
  };
};