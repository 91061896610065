import _ from "lodash";

export const BusinessTypeName = {
  PRIVATE: "Private",
  GOVERNMENT: "Government",
}

export const BusinessType = {
  Government: {
    id: 1,
    key: BusinessTypeName.GOVERNMENT,
    value: "modules.bulkAccount.registration.government",
  },
  Private: {
    id: 2,
    key: BusinessTypeName.PRIVATE,
    value: "modules.bulkAccount.registration.private",
  },
};

export const BusinessTypeAsKey = _.mapKeys(BusinessType, (value:any) => value.key);

export const BusinessTypeAsId = _.mapKeys(BusinessType, (value:any) => value.id);

export const businessTypeHelper = (businessType: number) => {
  if (!businessType) return "-";
  if (businessType === BusinessTypeAsKey[BusinessTypeName.GOVERNMENT].id)
    return BusinessTypeName.GOVERNMENT;
  else if (businessType === BusinessTypeAsKey[BusinessTypeName.PRIVATE].id)
    return BusinessTypeName.PRIVATE;

  return "-";
};
