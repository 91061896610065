import _ from "lodash";
import { B2B_API_INSTANCE, B2B_API_BLOB_INSTANCE } from "./index";

const SERVICE_VERSION: number = 1;

const BILL_CONTROLLER_NAME: string = `/billpayment-svc/api/v${SERVICE_VERSION}/Bills`;
const PAYMENT_CONTROLLER_NAME: string = `/billpayment-svc/api/v${SERVICE_VERSION}/Payment`;
const PAYMENTS_CONTROLLER_NAME: string = `/payments-svc/api/v${SERVICE_VERSION}/Payment`;

// bill
export const getOutstandingBillsCard = (bulkId: string) => {
  return B2B_API_INSTANCE.post(`${BILL_CONTROLLER_NAME}/GetOutstandingBillsCard/${bulkId}`);
};

export const getOutstandingCharges = (bulkId: string, recordCount: number) => {
  return B2B_API_INSTANCE.post(`${BILL_CONTROLLER_NAME}/GetOutstandingCharges/${bulkId}`, { recordCount });
};

export const getOutstandingBills = (bulkId: string, recordCount: number, selectedGroupIds: string[]) => {
  const postBody: any = {
    recordCount,
  };
  if (!_.isEmpty(selectedGroupIds)) {
    postBody["groupIds"] = selectedGroupIds;
  }
  return B2B_API_INSTANCE.post(`${BILL_CONTROLLER_NAME}/GetOutstandingBills/${bulkId}`, postBody);
};

export const getBillHistory = (bulkId: string, recordCount: number) => {
  return B2B_API_INSTANCE.post(`${BILL_CONTROLLER_NAME}/GetBillHistory/${bulkId}`, { recordCount });
};

export const getBillsByBatch = (bulkId: string, recordCount: number) => {
  return B2B_API_INSTANCE.post(`${BILL_CONTROLLER_NAME}/GetBillsByBatch/${bulkId}`, { recordCount });
};

export const searchBills = (bulkId: string, startDate: string, endDate: string, criteria: string, searchString: string, recordCount: number) => {
  return B2B_API_INSTANCE.post(`${BILL_CONTROLLER_NAME}/Search/${bulkId}`, { startDate, endDate, criteria, searchString, recordCount });
};

// B2B_API_BLOB_INSTANCE
export const getExcelBillReport = (collectiveContractAccountNo: string, isCurrent: boolean = false, isTotal: boolean = true) => {
  return B2B_API_BLOB_INSTANCE.get(`${BILL_CONTROLLER_NAME}/CreateExcelReportBill?collectiveContractAccountNo=${collectiveContractAccountNo}&isCurrent=${isCurrent}&isTotal=${isTotal}`);
};

export const downloadAllFiles = (bulkId: string, batchIds: string, extractDates: string) => {
  return B2B_API_BLOB_INSTANCE.get(`${BILL_CONTROLLER_NAME}/DownloadAllFiles?bulkCA=${bulkId}&batchIds=${batchIds}&extractDates=${extractDates}`);
};

export const downloadPDF = (caNo: string, invoiceNo: string) => {
  return B2B_API_BLOB_INSTANCE.get(`${BILL_CONTROLLER_NAME}/PrintPDF2?caNo=${caNo}&invoiceNo=${invoiceNo}`);
};

// payment
export const getBankList = () => {
  return B2B_API_INSTANCE.get(`${PAYMENT_CONTROLLER_NAME}/GetBankList`);
};

export const getVirtualAccountNo = (bulkId: string) => {
  return B2B_API_INSTANCE.get(`${PAYMENT_CONTROLLER_NAME}/GetTNBVirtualAccountNo${!_.isEmpty(bulkId) ? "?bulkId=" + bulkId : ""}`);
};

export const getPaymentMethods = () => {
  return B2B_API_INSTANCE.get(`${PAYMENT_CONTROLLER_NAME}/GetPaymentMethods`);
};

export const getElectricityAccount = (transactionNo: string, reconcileAmount: number) => {
  return B2B_API_INSTANCE.get(`${PAYMENT_CONTROLLER_NAME}/GetElectricityAccount?transactionNo=${transactionNo}&reconcileAmount=${reconcileAmount}`);
};

export const makePayment = (paymentObject: object) => {
  return B2B_API_INSTANCE.post(`${PAYMENT_CONTROLLER_NAME}/MakePayment`, paymentObject);
};

// payments
export const getTransactionsCards = (bulkId: string) => {
  return B2B_API_INSTANCE.post(`${PAYMENTS_CONTROLLER_NAME}/GetTransactionsCards?bulkId=${bulkId}`);
};

export const getPaymentTransactions = (bulkId: string, recordCount: number) => {
  return B2B_API_INSTANCE.post(`${PAYMENTS_CONTROLLER_NAME}/GetPaymentTransactions?bulkId=${bulkId}`, { recordCount });
};

export const getPaymentDetails = (transactionNo: string) => {
  return B2B_API_INSTANCE.get(`${PAYMENTS_CONTROLLER_NAME}/GetPaymentDetails?transactionNo=${transactionNo}`);
};

export const getPaymentActivity = (transactionNo: string) => {
  return B2B_API_INSTANCE.get(`${PAYMENTS_CONTROLLER_NAME}/GetPaymentActivity?transactionNo=${transactionNo}`);
};

export const makeReconciliationPaymentInsufficient = (transactionNo: string, bankId: string, paymentMode: string, bulkChequeEFTNumber: string) => {
  return B2B_API_INSTANCE.post(`${PAYMENTS_CONTROLLER_NAME}/MakeReconciliationPaymentInsufficient`, {
    transactionNo,
    bankId,
    paymentMode,
    bulkChequeEFTNumber
  });
};

export const makeExcessiveReconciliationPayment = (transactionNo: string, invoiceNo: string) => {
  return B2B_API_INSTANCE.post(`${PAYMENTS_CONTROLLER_NAME}/MakeExcessiveReconciliationPayment`, {
    invoiceNo,
    transactionNo
  });
};