import { Dispatch } from "redux";

import * as LogService from "src/services/logService";
import { fetchStart, fetchSuccess, fetchError } from "./fetchAction";

export const ACTION_NAME: string = "LOG_";

export const GET_NOTIFICATIONS = `${ACTION_NAME}GET_NOTIFICATIONS`;
export const getNotifications = (startDate: string, endDate: string, transactionNo?: string, accountNo?: string, lastId?: string, requestCount?: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_NOTIFICATIONS));
    LogService.getNotifications(startDate, endDate, transactionNo, accountNo, lastId, requestCount)
      .then((response) =>
        dispatch({
          type: GET_NOTIFICATIONS,
          payload: { 
            items: response,
            lastId 
          },
        })
      )
      .then(() => dispatch(fetchSuccess(GET_NOTIFICATIONS)))
      .catch(() => dispatch(fetchError(GET_NOTIFICATIONS)));
  };
};

export const READ_NOTIFICATIONS = `${ACTION_NAME}READ_NOTIFICATIONS`;
export const readNotifications = (ids: number[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(READ_NOTIFICATIONS));
    LogService.readNotifications(ids)
      .then((response) =>
        dispatch({
          type: READ_NOTIFICATIONS,
          payload: ids,
        })
      )
      .then(() => dispatch(fetchSuccess(READ_NOTIFICATIONS)))
      .catch(() => dispatch(fetchError(READ_NOTIFICATIONS)));
  };
};

export const DELETE_NOTIFICATIONS = `${ACTION_NAME}DELETE_NOTIFICATIONS`;
export const deleteNotifications = (ids: number[]) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(READ_NOTIFICATIONS));
    LogService.deleteNotifications(ids)
      .then((response) =>
        dispatch({
          type: DELETE_NOTIFICATIONS,
          payload: ids,
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_NOTIFICATIONS)))
      .catch(() => dispatch(fetchError(DELETE_NOTIFICATIONS)));
  };
};

export const GET_LITENOTIFICATIONS = `${ACTION_NAME}GET_LITENOTIFICATIONS`;
export const getLiteNotifications = (count: number = 3) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_LITENOTIFICATIONS));
    LogService.getLiteNotifications(count)
      .then((response) =>
        dispatch({
          type: GET_LITENOTIFICATIONS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_LITENOTIFICATIONS)))
      .catch(() => dispatch(fetchError(GET_LITENOTIFICATIONS)));
  };
};

export const GET_ACTIVITYLOGS = `${ACTION_NAME}GET_ACTIVITYLOGS`;
export const getActivityLogs = (startDate: string, endDate: string, transactionNo?: string, username?: string, lastId?: string, requestCount?: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ACTIVITYLOGS));
    LogService.getActivityLogs(startDate, endDate, transactionNo, username, lastId, requestCount)
      .then((response) =>
        dispatch({
          type: GET_ACTIVITYLOGS,
          payload: { 
            items: response,
            lastId 
          },
        })
      )
      .then(() => dispatch(fetchSuccess(GET_ACTIVITYLOGS)))
      .catch(() => dispatch(fetchError(GET_ACTIVITYLOGS)));
  };
};