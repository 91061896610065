import { B2B_API_INSTANCE } from "./index";

const SERVICE_VERSION: number = 1;

const BILL_CONTROLLER_NAME: string = `/billpayment-svc/api/v${SERVICE_VERSION}/Bills`;
const PAYMENTS_CONTROLLER_NAME: string = `/payments-svc/api/v${SERVICE_VERSION}/Payment`;
const ACCOUNT_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/BulkAccount`;

export const getTop3Cards = (bulkId: string) => {
  return B2B_API_INSTANCE.post(`${BILL_CONTROLLER_NAME}/GetTop3Cards/${bulkId}`);
};

export const getBillDataForGraph = (bulkId: string) => {
  return B2B_API_INSTANCE.post(`${BILL_CONTROLLER_NAME}/GetBillDataForGraph/${bulkId}`);
};

export const getPaymentDataForGraph = (bulkId: string) => {
  return B2B_API_INSTANCE.post(`${PAYMENTS_CONTROLLER_NAME}/GetPaymentDataForGraph/${bulkId}`);
};

export const getLatestBillSummary = (bulkId: string) => {
  return B2B_API_INSTANCE.post(`${BILL_CONTROLLER_NAME}/GetLatestBillSummary/${bulkId}`);
};

export const getAccountCards = (bulkId: string) => {
  return B2B_API_INSTANCE.post(`${ACCOUNT_CONTROLLER_NAME}/GetAccountCards/${bulkId}`);
};
