import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import NEW_IMAGE from "src/assets/images/NEWimage.png";

interface IModals {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  modalsTitle?: string;
  modalsBody?: string;
  modalsSize?: "small" | "medium" | "large" | "extra large";
  verticalCenter?: boolean;
  imageCenter?: string;
  imageWidth?: string;
  newImage?: boolean;
  newImageTop?: string;
  animation?: boolean;
  closeWhenClickOutside?: boolean;
  closeUsingKeyboard?: boolean;
  scrollable?: boolean;
  closeButton?: boolean;
  customClassName?: string;
  childBody?: JSX.Element;
  childFooter?: JSX.Element;
  customModalImage?: JSX.Element;
}

const ModalStyle = styled(({ newImageTop, imageWidth, ...props }) => (
  <Modal {...props} />
)) <IModals>`
  .modal__NEWImage {
    top: ${(props) => (props.newImageTop ? props.newImageTop : "23px")};
  }
  .modal__image {
    width: ${(props) => (props.imageWidth ? props.imageWidth : "50%")};
  }
`;

export const Modals = ({
  show,
  setShow,
  modalsTitle,
  modalsBody,
  modalsSize,
  verticalCenter,
  imageCenter,
  imageWidth,
  newImage,
  newImageTop,
  animation,
  closeWhenClickOutside,
  closeUsingKeyboard,
  scrollable,
  closeButton,
  customClassName,
  childBody,
  childFooter,
  customModalImage,
  ...props
}: IModals) => {
  const modalSize =
    modalsSize === "small"
      ? "sm"
      : modalsSize === "large"
        ? "lg"
        : modalsSize === "extra large"
          ? "xl"
          : undefined;

  return (
    <ModalStyle
      show={show}
      onHide={() => setShow(!show)}
      size={modalSize}
      centered={verticalCenter}
      animation={animation}
      backdrop={closeWhenClickOutside ? "false" : "static"}
      keyboard={closeUsingKeyboard}
      scrollable={scrollable}
      newImageTop={newImageTop}
      imageWidth={imageWidth}
      className={`customModal ` + customClassName}
      {...props}
    >
      <Modal.Header closeButton={closeButton}>
        {newImage && <img src={NEW_IMAGE} alt="" className="modal__NEWImage" />}
        {customModalImage}
        {imageCenter && (
          <img src={imageCenter} alt="" className="modal__image" />
        )}
        <Modal.Title className="pad-bottom-1">{modalsTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pad-0">
        {modalsBody}
        {childBody}
      </Modal.Body>
      {childFooter && <Modal.Footer>{childFooter}</Modal.Footer>}
    </ModalStyle>
  );
};
