import { B2B_API_INSTANCE } from "./index";

const SERVICE_VERSION: number = 1;
const USER_CONTROLLER_NAME: string = `/user-svc/api/v${SERVICE_VERSION}/User`;

export const getManageUsers = (recordCount: number, bulkId: string) => {
  return B2B_API_INSTANCE.post(`${USER_CONTROLLER_NAME}/GetManageUsersTable/${bulkId}`, { recordCount });
};

export const getManageUsersCards = (bulkId: string) => {
  return B2B_API_INSTANCE.post(`${USER_CONTROLLER_NAME}/GetManageUsersCards/${bulkId}`);
};

export const addNewUser = (newUser: object) => {
  return B2B_API_INSTANCE.post(`${USER_CONTROLLER_NAME}/AddBulkUser`, newUser);
};

export const updateBulkUser = (userId: string, nickname: string, role: string) => {
  const postBody = {
    userId,
    nickname,
    role,
  };
  return B2B_API_INSTANCE.post(`${USER_CONTROLLER_NAME}/UpdateBulkUser`, postBody);
};

export const deleteBulkUser = (userId: string) => {
  return B2B_API_INSTANCE.post(`${USER_CONTROLLER_NAME}/DeleteBulkUser`, { userId });
};

export const transferMasterRole = (userId: string, nickname: string) => {
  const postBody = {
    userId,
    nickname,
  };
  return B2B_API_INSTANCE.post(`${USER_CONTROLLER_NAME}/TransferMasterBulkUser`, postBody);
};
