import _ from "lodash";
import { Row } from "react-table";

import { PAYMENT_REQUIRED_STATUS_AS_NAME, PAYMENT_REQUIRED_STATUS_NAME, PAYMENT_REQUIRED_STATUS_TYPES } from "../constants/paymentRequiredStatusType";
import { differenceByDays } from "./datetimeHelper";

export const paymentRequiredStatusHelper = (status: string, extractionDateTime: string, totalAmountPayable: number) => {
  if (totalAmountPayable <= 0) {
    return PAYMENT_REQUIRED_STATUS_AS_NAME[PAYMENT_REQUIRED_STATUS_NAME.NO_ACTION_REQUIRED].code;
  }
  if (status !== PAYMENT_REQUIRED_STATUS_AS_NAME[PAYMENT_REQUIRED_STATUS_NAME.PAYMENTREQUIRED].code) return status;

  if (!extractionDateTime) return "-";

  if (differenceByDays(new Date(), extractionDateTime) < 15) {
    // green
    return PAYMENT_REQUIRED_STATUS_AS_NAME[PAYMENT_REQUIRED_STATUS_NAME.PAYMENTREQUIRED_GREEN].code;
  }
  if (differenceByDays(new Date(), extractionDateTime) < 30) {
    // orange
    return PAYMENT_REQUIRED_STATUS_AS_NAME[PAYMENT_REQUIRED_STATUS_NAME.PAYMENTREQUIRED_ORANGE].code;
  }
  // red
  return PAYMENT_REQUIRED_STATUS_AS_NAME[PAYMENT_REQUIRED_STATUS_NAME.PAYMENTREQUIRED_RED].code;
};

export const customSortPaymentStatus: any = (rowA: Row, rowB: Row, id: string, desc: boolean) => {
  const paymentStatusA = _.get(PAYMENT_REQUIRED_STATUS_TYPES, `${rowA.values[id]}.sortSequence`);
  const paymentStatusB = _.get(PAYMENT_REQUIRED_STATUS_TYPES, `${rowB.values[id]}.sortSequence`);
  if (paymentStatusA > paymentStatusB) return 1;
  if (paymentStatusB > paymentStatusA) return -1;
  return 0;
};
