const State = {
  malaysia: [
    {
      key: "johor",
      value: "components.stateSelection.johor",
    },
    {
      key: "kedah",
      value: "components.stateSelection.kedah",
    },
    {
      key: "kelantan",
      value: "components.stateSelection.kelantan",
    },
    {
      key: "malacca",
      value: "components.stateSelection.malacca",
    },
    {
      key: "sembilan",
      value: "components.stateSelection.sembilan",
    },
    {
      key: "pahang",
      value: "components.stateSelection.pahang",
    },
    {
      key: "perak",
      value: "components.stateSelection.perak",
    },
    {
      key: "sabah",
      value: "components.stateSelection.sabah",
    },
    {
      key: "sarawak",
      value: "components.stateSelection.sarawak",
    },
    {
      key: "selangor",
      value: "components.stateSelection.selangor",
    },
    {
      key: "terengganu",
      value: "components.stateSelection.terengganu",
    },
    {
      key: "kl",
      value: "components.stateSelection.kl",
    },
    {
      key: "labuan",
      value: "components.stateSelection.labuan",
    },
    {
      key: "putrajaya",
      value: "components.stateSelection.putrajaya",
    },
    {
      key: "penang",
      value: "components.stateSelection.penang",
    },
    {
      key: "perlis",
      value: "components.stateSelection.perlis",
    },
  ],
  malaysiaAccount: [
    {
      code: "JOH",
      value: "components.accountStateSelection.johor",
    },
    {
      code: "KED",
      value: "components.accountStateSelection.kedah",
    },
    {
      code: "KEL",
      value: "components.accountStateSelection.kelantan",
    },
    {
      code: "KUL",
      value: "components.accountStateSelection.kl",
    },
    {
      code: "MEL",
      value: "components.accountStateSelection.melaka",
    },
    {
      code: "NSE",
      value: "components.accountStateSelection.sembilan",
    },
    {
      code: "PAH",
      value: "components.accountStateSelection.pahang",
    },
    {
      code: "PEL",
      value: "components.accountStateSelection.perlis",
    },
    {
      code: "PER",
      value: "components.accountStateSelection.perak",
    },
    {
      code: "PIN",
      value: "components.accountStateSelection.pinang",
    },
    {
      code: "PJY",
      value: "components.accountStateSelection.pjy",
    },
    {
      code: "SEL",
      value: "components.accountStateSelection.selangor",
    },
    {
      code: "TRE",
      value: "components.accountStateSelection.terengganu",
    },
  ],
};

export default State;