import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import _ from "lodash";
//Components
import Button from "../button";
import Modals from "../modals";
// Images
import { ReactComponent as EXCLAMATION_ICON } from "src/assets/images/icons/icon-exclamation.svg";
// Helper
import { twoNumbersFormatter } from "src/helpers/numberHelper";

interface IButton {
  actionName: string;
  label: string;
  callback: () => void;
  mode: "primary" | "secondary";
}

interface ITImeoutModal {
  allowedIdleMinutes: number;
  buttons?: Array<IButton>;
  timeoutCallback: () => void;
  closeButton?: boolean;
  leftTimeoutMinutes?: number;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TimeoutModal: React.FC<ITImeoutModal> = ({
  allowedIdleMinutes,
  buttons,
  timeoutCallback,
  closeButton = false,
  leftTimeoutMinutes = 2,
  showModal,
  setShowModal,
}) => {
  const t = useTranslation();

  const [leftTime, setLeftTime] = useState(leftTimeoutMinutes * 60);

  const onClickValueHandler = (button: any) => {
    button.callback && button.callback();
  };

  const renderButtons = () => {
    let buttonsToRender = buttons;
    return (
      buttonsToRender && (
        <div className="timeoutModal__buttonContainer mar-top-2 flex justify-center">
          {_.map(buttonsToRender, (button, i) => {
            return (
              <div className={`timeoutModal__buttons`} key={i}>
                <Button
                  label={button.label}
                  size="large"
                  mode={button.mode}
                  onClick={() => onClickValueHandler(button)}
                  className={
                    `timeoutModal__button timeoutModal__button--` +
                    button.actionName
                  }
                />
              </div>
            );
          })}
        </div>
      )
    );
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (!showModal) {
      setLeftTime(leftTimeoutMinutes * 60);
      return;
    }

    if (_.gt(leftTime, 0)) {
      intervalId = setInterval(() => {
        setLeftTime((preLeftTime) => preLeftTime - 1);
      }, 1000);
    } else {
      timeoutCallback();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [showModal, leftTime, setLeftTime, timeoutCallback, leftTimeoutMinutes]);

  return (
    <Modals
      show={showModal}
      setShow={setShowModal}
      modalsSize="large"
      verticalCenter={true}
      closeWhenClickOutside={false}
      closeButton={closeButton}
      customClassName="timeoutModal"
      customModalImage={
        <EXCLAMATION_ICON className="timeoutModal__timeoutIcon" />
      }
      childBody={
        <>
          <div className="timeoutModal__modalTitle pad-bottom-1">
            {t("components.timeoutModal.modalTitle")}
          </div>
          <div className="timeoutModal__modalBody">
            {t("components.timeoutModal.modalBody1")} {allowedIdleMinutes}{" "}
            {t("components.timeoutModal.modalBody2")}{" "}
            {twoNumbersFormatter(Math.floor(leftTime / 60))}:
            {twoNumbersFormatter(leftTime % 60)}.
          </div>
          {renderButtons()}
        </>
      }
    />
  );
};
