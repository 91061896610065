import { B2B_API_INSTANCE, B2B_API_BLOB_INSTANCE } from "../index";

const SERVICE_VERSION: number = 1;

const ACCOUNT_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/Admin`;
const BULK_ACCOUNT_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/BulkAccount`;
const BULKCHILDACCOUNT_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/BulkChildAccount`;
const BULK_REGISTRATION_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/BulkRegistration`;

export const searchAccount = (searchBy: string, searchValue: string, recordCount: number) => {
  const postBody = {
    criteria: searchBy,
    searchString: searchValue,
    recordCount,
  };
  return B2B_API_INSTANCE.post(`${BULK_ACCOUNT_CONTROLLER_NAME}/SearchElectricityAccounts`, postBody);
};

export const getBulkAccountsCards = () => {
  return B2B_API_INSTANCE.post(`${BULK_ACCOUNT_CONTROLLER_NAME}/GetBulkAccountsCards`);
};

export const getBulkAccountsTable = (recordCount: number) => {
  return B2B_API_INSTANCE.post(`${BULK_ACCOUNT_CONTROLLER_NAME}/GetBulkAccountsTable`, { recordCount });
};

export const getUsers = () => {
  return B2B_API_INSTANCE.get(`${ACCOUNT_CONTROLLER_NAME}/getUsers`);
};

export const getRegistrationTable = (recordCount: number) => {
  return B2B_API_INSTANCE.post(`${BULK_REGISTRATION_CONTROLLER_NAME}/BulkUserRegistrationTable`, { recordCount });
};

export const getCustomerRegistrationData = (registrationId: string) => {
  return B2B_API_INSTANCE.get(`${BULK_REGISTRATION_CONTROLLER_NAME}/GetRegistrationApplicationByID/${registrationId}`);
};

export const getCustomerRegistrationRecord = (registrationId: string) => {
  return B2B_API_INSTANCE.get(`${BULK_REGISTRATION_CONTROLLER_NAME}/GetRegistrationRecord/${registrationId}`);
};

export const getRegistrationActionLogTable = (registrationId: string) => {
  return B2B_API_INSTANCE.get(`${BULK_REGISTRATION_CONTROLLER_NAME}/RegistrationRemarkLog/${registrationId}`);
};

export const getElectricityAccountsCard = (bulkId: string) => {
  return B2B_API_INSTANCE.post(`${BULK_ACCOUNT_CONTROLLER_NAME}/GetElectricityAccountsCard/${bulkId}`);
};

export const downloadDeclarationForm = (registrationId: string) => {
  return B2B_API_BLOB_INSTANCE.get(`${BULK_REGISTRATION_CONTROLLER_NAME}/DownloadDeclarationForm/${registrationId}`);
};

export const updateBulkRegistrationStatus = (registrationId: string, remark: string, action: string) => {
  const postBody = {
    remark,
    registrationId,
    action,
  };
  return B2B_API_INSTANCE.post(`${BULK_REGISTRATION_CONTROLLER_NAME}/UpdateBulkAccountRegistrationStatus`, postBody);
};

export const getBulkChildAccountError = (recordCount:number) => {
  return B2B_API_INSTANCE.get(`${BULKCHILDACCOUNT_CONTROLLER_NAME}/GetBulkChildAccountErrorForAdmin?recordCount=${recordCount}`);
}