import { useEffect } from "react";
import styled from "styled-components";

import * as theme from "src/constants/theming";
import { ReactComponent as ICON_CHECK } from "src/assets/images/icons/icon-check-rounded.svg";
import { ReactComponent as ICON_CLOSE_ROUNDED } from "src/assets/images/icons/icon-close-rounded.svg";
import { ReactComponent as ICON_EXCLAMATION } from "src/assets/images/icons/icon-exclamation.svg";
import { ReactComponent as ICON_CLOSE } from "src/assets/images/icons/icon-close.svg";

interface INotification {
  icon: "cross" | "tick" | "exclamation";
  textValue: string | JSX.Element | JSX.Element[];
  closeButton?: boolean;
  timeout?: number;
  show: boolean;
  setShow?: (value: boolean) => void;
  isOverlay?: boolean;
  overlayTop?: number;
  overlayLeft?: number;
  overlayRight?: number;
  customClass?: string;
}

const NotificationStyle = styled.div<INotification>`
  .notification {
    position: ${(props) => props.isOverlay && "absolute"};
    top: ${(props) => props.overlayTop && props.overlayTop + "px"};
    left: ${(props) => props.overlayLeft && props.overlayLeft + "px"};
    right: ${(props) => props.overlayRight && props.overlayRight + "px"};

    &__textValue {
      border-left-color: ${(props) =>
      props.icon === "tick"
        ? theme.defaultTheme["color-positive"]
        : theme.defaultTheme["color-error-lightBackground2"]};
    }
  }
`;

export const Notification = ({
  icon,
  textValue,
  closeButton,
  timeout,
  show,
  setShow,
  isOverlay,
  overlayTop,
  overlayLeft,
  overlayRight,
  customClass = '',
  ...props
}: INotification) => {

  let overlayTopValue = undefined;
  let overlayLeftValue = undefined;
  let overlayRightValue = undefined;
  if (isOverlay) {
    if (overlayTop) {
      overlayTopValue = overlayTop;
    } else {
      overlayTopValue = 0;
    }
    if (overlayLeft) {
      overlayLeftValue = overlayLeft;
    } else {
      overlayLeftValue = 0;
    }
    if (overlayRight) {
      overlayRightValue = overlayRight;
    } else {
      overlayRightValue = 0;
    }
  }

  let iconImage: JSX.Element;
  if (icon === "exclamation") {
    iconImage = <ICON_EXCLAMATION className="notification__icon notification__icon--exclamation" />;
  } else if (icon === "tick") {
    iconImage = <ICON_CHECK className="notification__icon notification__icon--check" />;
  } else {
    iconImage = <ICON_CLOSE_ROUNDED className="notification__icon notification__icon--close" />;
  }

  const onChangeValueHandler = () => {
    setShow && setShow(false);
  };

  useEffect(() => {
    let timer1: NodeJS.Timeout;
    if (timeout && show) {
      timer1 = setTimeout(() => {
        setShow && setShow(false);
      }, timeout * 1000);
    }

    return () => {
      if (timer1) {
        clearTimeout(timer1);
      }
    };
  }, [timeout, setShow, show]);

  return (
    <NotificationStyle
      icon={icon}
      textValue={textValue}
      show={show}
      setShow={setShow}
      isOverlay={isOverlay}
      overlayTop={overlayTopValue}
      overlayLeft={overlayLeftValue}
      overlayRight={overlayRightValue}
      {...props}
    >
      <div className={`notification ${customClass} ${!show ? "fadeOut" : ""}`}>
        <div className={`notification__item`}>
          <div className={`notification__iconContainer`}>
            {iconImage}
          </div>
          <div
            className={`notification__textValue pad-top-1 pad-bottom-1 pad-left-3_25 pad-right-2_5`}
          >
            {textValue}
          </div>
          <div className={`notification__closeButtonContainer`}>
            {closeButton && (
              <ICON_CLOSE className="notification__closeButton mar-top-1 mar-right-1" onClick={onChangeValueHandler} />
            )}
          </div>
        </div>
      </div>
    </NotificationStyle>
  );
};
