import { t } from "react-multi-lang";
import _ from 'lodash';

import * as accountActions from 'src/redux/actions/admin/accountAction';
import { IReducerAction, IReducer, IPayloadObj } from '../_commonInterface';
// Helpers
import { parseYearMonthDayToDayMonthYear, toDayMonthYear, toTimeDayMonthYear } from 'src/helpers/datetimeHelper';
import { STATUSTYPE_AS_NAME, STATUS_NAME } from 'src/constants/status';
import { getRegistrationStatus } from 'src/constants/registrationStatus';
import { businessTypeHelper } from 'src/constants/businessType';
import { getRegistrationUserType } from 'src/constants/registrationUserType';
import State from 'src/constants/state';
import { pendingCAStatusCode } from "src/constants/pendingCAStatusCode";

type NullableNumberString = number | string | null;

interface IBulkAccounts {
  totalBulkAccounts: NullableNumberString,
  totalElectricityAccounts: NullableNumberString,
  totalOutstandingBulkAccounts: NullableNumberString,
  bulkAccountsTable: object[],
  bulkAccountsCount: number,
  pendingCA: object[];
  pendingCACount: number;
}

interface ICustomerRegistrationBulkData {
  companyDetails: object,
  mailingAddress: object,
  primaryContact: object,
  secondaryContact: object,
}

interface ICustomerRegistration {
  customerRegistrationData: object,
  actionLogTable: object[],
  customerRegistrationBulkData: ICustomerRegistrationBulkData,
  childAccountsTable: object[],
}

interface IRegistration {
  registrationTable: object[],
  registrationCount: number,
  customerRegistration: ICustomerRegistration,
}

interface IAddAccounts {
  autoDetectedTable: object[];
  manualAddTable: object[];
  uploadAccounts: object[];
  validatedAccount: {
    accountNo: string;
    errorType: string;
  };
  noOfSuccessAccount: number;
}

interface IPendingCA {
  pendingAccTable: object[];
  pendingAccCount: number;
  validatedAccount: {
    accountNo: string;
    errorType: string;
  };
}

interface IManageAccounts {
  bulkAccountDetails: object,
  accountDetails: object;
  topCards: object,
  electricityAccountsTable: object[],
  addAccounts: IAddAccounts;
  pendingCA: IPendingCA;
}

interface IAccountReducer {
  myProfile: object,
  impersonateBulkAccountNo?: string,
  electricityAccount: object,
  bulkAccounts: IBulkAccounts,
  usersSummary: {
    totalUsers: NullableNumberString,
    activeUsers: NullableNumberString,
    inactiveUsers: NullableNumberString
  },
  registration: IRegistration,
  manageAccounts: IManageAccounts,
}

const defaultState: IAccountReducer = {
  myProfile: {},
  impersonateBulkAccountNo: '',
  electricityAccount: {
    searchResult: [],
    searchCount: 0,
  },
  bulkAccounts: {
    totalBulkAccounts: '-',
    totalElectricityAccounts: '-',
    totalOutstandingBulkAccounts: '-',
    bulkAccountsTable: [],
    bulkAccountsCount: 0,
    pendingCA: [],
    pendingCACount: 0
  },
  usersSummary: {
    totalUsers: '-',
    activeUsers: '-',
    inactiveUsers: '-'
  },
  registration: {
    registrationTable: [],
    registrationCount: 0,
    customerRegistration: {
      customerRegistrationData: {
        applicationId: "",
        businessType: "",
        status: "",
        userType: "",
        submittedOn: "",
      },
      customerRegistrationBulkData: {
        companyDetails: {
          companyName: "",
          registrationNo: "",
          mobileNo: "",
        },
        mailingAddress: {
          unitFloor: "",
          buildingName: "",
          streetName: "",
          city: "",
          postalCode: "",
          state: "",
        },
        primaryContact: {
          name: "",
          emailAddress: "",
          mobileNo: "",
          jobTitle: "",
          departmentName: "",
        },
        secondaryContact: {
          name: "",
          emailAddress: "",
          mobileNo: "",
          jobTitle: "",
          departmentName: "",
        },
      },
      actionLogTable: [],
      childAccountsTable: [],
    },
  },
  manageAccounts: {
    bulkAccountDetails: {},
    accountDetails: {},
    topCards: {},
    electricityAccountsTable: [],
    addAccounts: {
      autoDetectedTable: [],
      manualAddTable: [],
      uploadAccounts: [],
      validatedAccount: {
        accountNo: "",
        errorType: "",
      },
      noOfSuccessAccount: 0,
    },
    pendingCA: {
      pendingAccTable: [],
      pendingAccCount: 0,
      validatedAccount: {
        accountNo: "",
        errorType: "",
      },
    },
  },
}

const REDUCERS: IReducer = {
  [accountActions.GET_ADMIN_USER_PROFILE]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      myProfile: {
        ...state.myProfile,
        ...payload,
        lastLoginDateTime: _.isNull(payload.lastLoginDateTime) ? "-" : payload.lastLoginDateTime + "Z",
      },
    }
  },
  [accountActions.GET_ADMIN_USER_PROFILE_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      impersonateBulkAccountNo: '',
    }
  },
  [accountActions.UPDATE_IMPERSONATE_BULK_ACCOUNT_NO]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      impersonateBulkAccountNo: payload,
    }
  },
  [accountActions.SEARCH_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { account, count } = payload;
    _.forEach(account, s => {
      _.set(s, "accountNo", _.get(s, "accountNumber"));
      _.set(s, "referenceId", _.get(s, "referenceID"));
      _.set(s, "bulkId", _.get(s, "collectiveContractAccountNo"));
      _.set(s, "premisesAddress", _.get(s, "premisesAddresss"));
      _.set(s, "status", _.toLower(s.status) === "active" ? STATUSTYPE_AS_NAME[STATUS_NAME.ACTIVE].code : STATUSTYPE_AS_NAME[STATUS_NAME.INACTIVE].code);
    });
    return {
      ...state,
      electricityAccount: {
        ...state.electricityAccount,
        searchResult: [...account],
        searchCount: _.isNull(count) ? _.get(account, 'length') : count,
      }
    }
  },
  [accountActions.GET_BULK_ACCOUNTS_CARDS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      bulkAccounts: {
        ...state.bulkAccounts,
        totalBulkAccounts: _.get(payload, 'totalBulkAccounts') ?? '-',
        totalElectricityAccounts: _.get(payload, 'totalElectricityAccounts') ?? '-',
        totalOutstandingBulkAccounts: _.get(payload, 'totalBulkAccountsWithOutstandingCharges') ?? '-',
      }
    };
  },
  [accountActions.GET_BULK_ACCOUNTS_TABLE]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accounts, count } = payload;
    _.forEach(accounts, b => {
      b.companyName ?? _.set(b, "companyName", "-");
      b.collectiveContractAccountNo ?? _.set(b, "collectiveContractAccountNo", "-");
      _.set(b, "lastBillDate", b.lastBillDate ? parseYearMonthDayToDayMonthYear(b.lastBillDate) : "-");
      _.set(b, "lastPaymentDate", b.lastPaymentDate ? parseYearMonthDayToDayMonthYear(b.lastPaymentDate) : "-");
      _.set(b, "totalUnpaidBills", b.totalUnpaidBills ?? "-");
      _.set(b, "latestTotalAmount", b.latestTotalAmount ?? "-");
      _.set(b, "latestCurrentCharges", b.latestCurrentCharges ?? "-");
      _.set(b, "latestOutstandingCharges", b.latestOutstandingCharges ?? "-");
      _.set(b, "totalElectricityAccounts", b.totalElectricityAccounts ?? "-");
      _.set(b, "totalUsers", b.totalUsers ?? "-");
      _.set(b, "businessType", b.businessType ? businessTypeHelper(b.businessType) : undefined);
    });
    return {
      ...state,
      bulkAccounts: {
        ...state.bulkAccounts,
        bulkAccountsTable: [...accounts],
        bulkAccountsCount: _.isNull(count) ? _.get(accounts, 'length') : count,
      }
    };
  },
  [accountActions.ADMIN_USER_TUTORIAL_COMPLETED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const tutorials =_.filter(_.get(state.myProfile, "tutorials"), (t: any) => t.userTutorialId !== payload);

    return {
      ...state,
      myProfile: {
        ...state.myProfile,
        tutorials,
      }
    }
  },
  [accountActions.GET_USERS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    let totalUsers: string | number = '-';
    let activeUsers: string | number = '-';
    let inactiveUsers: string | number = '-';
    if (!_.isNull(payload)) {
      totalUsers = _.get(payload, 'totalUsers');
      activeUsers = _.get(payload, 'activeUsers');
      inactiveUsers = _.get(payload, 'inactiveUsers');
    }
    return {
      ...state,
      userSummary: {
        totalUsers,
        activeUsers,
        inactiveUsers
      }
    }
  },
  [accountActions.GET_REGISTRATION_TABLE]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accounts, count } = payload;
    _.forEach(accounts, d => {
      _.set(d, "registrationId", d.registrationID ? d.registrationID : "-");
      _.set(d, "applicationId", d.registrationID ? d.registrationID.slice(0, 8) : "-");
      _.set(d, "applicationDate", d.applicationDate ? d.applicationDate + "Z" : "-");
      _.set(d, "status", d.statusCode ? getRegistrationStatus(d.statusCode) : "-");
      _.set(d, "businessType", d.businessTypeID ? businessTypeHelper(d.businessTypeID) : "-");
      _.set(d, "totalAccounts", d.totalAccounts ?? "-");

      delete d["registrationID"];
    });
    return {
      ...state,
      registration: {
        ...state.registration,
        registrationTable: [...accounts],
        registrationCount: _.isNull(count) ? _.get(accounts, 'length') : count,
      }
    }
  },
  [accountActions.GET_CUSTOMER_REGISTRATION_DATA]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const customerRegistrationData = {
      applicationId: payload.applicationId ? payload.applicationId : "-",
      businessType: payload.businessType ? businessTypeHelper(payload.businessType) : "-",
      status: payload.statusCode ? getRegistrationStatus(payload.statusCode) : "-",
      userType: payload.userType ? getRegistrationUserType(payload.userType) : "-",
      submittedOn: payload.submittedDate ? toTimeDayMonthYear(`${payload.submittedDate}Z`) : "-",

    };
    return {
      ...state,
      registration: {
        ...state.registration,
        customerRegistration: {
          ...state.registration.customerRegistration,
          customerRegistrationData: customerRegistrationData,
        }
      }
    }
  },
  [accountActions.GET_CUSTOMER_REGISTRATION_RECORD]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { bulkUserRegistration, bulkUserRegistrationDetails } = payload;

    const customerRegistrationBulkData = {
      companyDetails: {
        companyName: bulkUserRegistration.companyName ? bulkUserRegistration.companyName : "-",
        registrationNo: bulkUserRegistration.rocNumber ? bulkUserRegistration.rocNumber : "-",
        mobileNo: bulkUserRegistration.companyLandline ? bulkUserRegistration.companyLandline : "-",
      },
      mailingAddress: {
        unitFloor: bulkUserRegistration.unitFloorNo ? bulkUserRegistration.unitFloorNo : "-",
        buildingName: bulkUserRegistration.buildingName ? bulkUserRegistration.buildingName : "-",
        streetName: bulkUserRegistration.streetName ? bulkUserRegistration.streetName : "-",
        city: bulkUserRegistration.city ? bulkUserRegistration.city : "-",
        postalCode: bulkUserRegistration.postalCode ? bulkUserRegistration.postalCode : "-",
        state: bulkUserRegistration.state ? bulkUserRegistration.state : "-",
      },
      primaryContact: {
        name: bulkUserRegistration.primaryName ? bulkUserRegistration.primaryName : "-",
        emailAddress: bulkUserRegistration.primaryEmail ? bulkUserRegistration.primaryEmail : "-",
        mobileNo: bulkUserRegistration.primaryMobileNo ? bulkUserRegistration.primaryMobileNo : "-",
        jobTitle: bulkUserRegistration.primaryJobTitle ? bulkUserRegistration.primaryJobTitle : "-",
        departmentName: bulkUserRegistration.primaryDepartment ? bulkUserRegistration.primaryDepartment : "-",
      },
      secondaryContact: {
        name: bulkUserRegistration.secondaryName ? bulkUserRegistration.secondaryName : "-",
        emailAddress: bulkUserRegistration.secondaryEmail ? bulkUserRegistration.secondaryEmail : "-",
        mobileNo: bulkUserRegistration.secondaryMobileNo ? bulkUserRegistration.secondaryMobileNo : "-",
        jobTitle: bulkUserRegistration.secondaryJobTitle ? bulkUserRegistration.secondaryJobTitle : "-",
        departmentName: bulkUserRegistration.secondaryDepartment ? bulkUserRegistration.secondaryDepartment : "-",
      },
      bulkId: bulkUserRegistration.bulkId,
      applicationId: bulkUserRegistration.applicationId ? bulkUserRegistration.applicationId : "-",
      businessType: bulkUserRegistration.businessType ? businessTypeHelper(bulkUserRegistration.businessType) : "-",
      status: bulkUserRegistration.statusCode ? getRegistrationStatus(bulkUserRegistration.statusCode) : "-",
      userType: bulkUserRegistration.userType ? getRegistrationUserType(bulkUserRegistration.userType) : "-",
      submittedOn: bulkUserRegistration.submittedDate ? toTimeDayMonthYear(`${bulkUserRegistration.submittedDate}Z`) : "-",
    };
    return {
      ...state,
      registration: {
        ...state.registration,
        customerRegistration: {
          ...state.registration.customerRegistration,
          customerRegistrationBulkData: customerRegistrationBulkData,
          childAccountsTable: [...bulkUserRegistrationDetails],
        }
      }
    }
  },
  [accountActions.GET_REGISTRATION_ACTION_LOG_TABLE]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accounts } = payload;
    _.forEach(accounts, d => {
      _.set(d, "submittedDate", d.submittedDate ? toTimeDayMonthYear(d.submittedDate + "Z") : "-");
    });
    const sortedAccounts = _.orderBy(accounts, a => new Date(a.submittedDate), "desc");
    return {
      ...state,
      registration: {
        ...state.registration,
        customerRegistration: {
          ...state.registration.customerRegistration,
          actionLogTable: [...sortedAccounts],
        }
      }
    }
  },
  [accountActions.GET_ELECTRICITY_ACCOUNTS_CARD]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        topCards: {
          totalAccounts: _.get(payload, "totalAccounts") ?? "-",
          activeAccounts: _.get(payload, "activeAccounts") ?? "-",
          inactiveAccounts: _.get(payload, "inactiveAccounts") ?? "-",
        }
      }
    }
  },
  [accountActions.GET_PROFILE_FOR_BULK_ID]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const mailState = _.get(_.find(State.malaysiaAccount, (s:any) => t(s.value) === _.get(payload, "state")), "code");
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        bulkAccountDetails: {
          bulkId: _.get(payload, "collectiveContractAccountNo"),
          companyName: _.get(payload, "name"),
          registrationNo: _.get(payload, "registrationNo"),
          companyLandline: _.get(payload, "companyLandline"),
          createdDate: _.get(payload, "createdDate") ? toDayMonthYear(payload.createdDate) : null,
          primaryName: _.get(payload, "userName"),
          primaryEmail: _.get(payload, "email"),
          primaryMobile: _.get(payload, "mobileNo"),
          primaryJobTitle: _.get(payload, "jobTitle"),
          primaryDepartment: _.get(payload, "departmentName"),
          secondaryName: _.get(payload, "secondary_UserName"),
          secondaryEmail: _.get(payload, "secondary_Email"),
          secondaryMobile: _.get(payload, "secondary_MobileNo"),
          secondaryJobTitle: _.get(payload, "secondary_JobTitle"),
          secondaryDepartment: _.get(payload, "secondary_DepartmentName"),
          mailingAddr1: _.get(payload, "mailingAddr1"),
          mailingAddr2: _.get(payload, "mailingAddr2"),
          mailingAddr3: _.get(payload, "mailingAddr3"),
          unitFloor: _.get(payload, "unitNo"),
          building: _.get(payload, "building"),
          street: _.get(payload, "street"),
          city: _.get(payload, "city"),
          postalCode: _.get(payload, "postcode"),
          state: mailState,
          businessType: _.get(payload, "businessTypeId") ? businessTypeHelper(payload.businessTypeId) : undefined,
        },
      }
    }
  },
  [accountActions.EDIT_BULK_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const mailState = _.get(_.find(State.malaysiaAccount, (s:any) => t(s.value) === _.get(payload, "state")), "code");
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        bulkAccountDetails: {
          bulkId: _.get(payload, "collectiveContractAccountNo"),
          companyName: _.get(payload, "name"),
          registrationNo: _.get(payload, "registrationNo"),
          companyLandline: _.get(payload, "companyLandline"),
          createdDate: _.get(payload, "registeredDate") ? toDayMonthYear(payload.registeredDate) : null,
          primaryName: _.get(payload, "userName"),
          primaryEmail: _.get(payload, "email"),
          primaryMobile: _.get(payload, "mobileNo"),
          primaryJobTitle: _.get(payload, "jobTitle"),
          primaryDepartment: _.get(payload, "departmentName"),
          secondaryName: _.get(payload, "secondary_UserName"),
          secondaryEmail: _.get(payload, "secondary_Email"),
          secondaryMobile: _.get(payload, "secondary_MobileNo"),
          secondaryJobTitle: _.get(payload, "secondary_JobTitle"),
          secondaryDepartment: _.get(payload, "secondary_DepartmentName"),
          mailingAddr1: _.get(payload, "mailingAddr1"),
          mailingAddr2: _.get(payload, "mailingAddr2"),
          mailingAddr3: _.get(payload, "mailingAddr3"),
          unitFloor: _.get(payload, "unitNo"),
          building: _.get(payload, "building"),
          street: _.get(payload, "street"),
          city: _.get(payload, "city"),
          postalCode: _.get(payload, "postcode"),
          state: mailState,
        },
      }
    }
  },
  [accountActions.EDIT_BULK_ACCOUNT_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        errorTopic: _.get(payload, "errorTopic"),
        errorMessage: _.get(payload, "errorMessage"),
      }
    }
  },
  [accountActions.GET_ACCOUNT_FOR_BULK_ID]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { collectiveChildAccounts } = payload;
    _.forEach(collectiveChildAccounts, c => {
      _.set(c, "accountNo", _.get(c, "accountNumber"));
      _.set(c, "sector", _.get(c, "sectorId"));
      _.set(c, "companyName", _.get(c, "accountDescription"));
      /*
        Status:
        true = Active
        false = Inactive
      */
      _.set(c, "activeStatus", c.status ? STATUSTYPE_AS_NAME[STATUS_NAME.ACTIVE].code : STATUSTYPE_AS_NAME[STATUS_NAME.INACTIVE].code);
      delete c["accountNumber"];
      delete c["accountDescription"];
      delete c["status"];
      delete c["businessArea"];
      delete c["groupName"];
      delete c["groupId"];
      delete c["sectorId"];
    });
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        electricityAccountsTable: collectiveChildAccounts,
      }
    }
  },
  [accountActions.REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        noOfRemovedAccounts: payload,
      }
    }
  },
  [accountActions.REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        errorTopic: _.get(payload, "errorTopic"),
        errorMessage: _.get(payload, "errorMessage"),
      }
    }
  },
  [accountActions.EDIT_ELECTRICITY_ACCOUNTS_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        errorTopic: _.get(payload, "errorTopic"),
        errorMessage: _.get(payload, "errorMessage"),
      }
    }
  },
  [accountActions.GET_ELECTRICITY_ACCOUNT_DETAILS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        accountDetails: {
          accountNo: _.get(payload, "accountNumber"),
          referenceId: _.get(payload, "referenceId"),
          sectorId: _.get(payload, "sectorId"),
          accountStatus: payload.status ? STATUSTYPE_AS_NAME[STATUS_NAME.ACTIVE].code : STATUSTYPE_AS_NAME[STATUS_NAME.INACTIVE].code,
          companyName: _.get(payload, "accountDescription"),
          premisesAddress: _.get(payload, "address"),
          state: _.get(payload, "state"),
          bulkId: _.get(payload, "bulkId"),
        }
      }
    }
  },
  [accountActions.UPDATE_MANUAL_ACCOUNT_IN_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          manualAddTable: payload,
        },
      }
    };
  },
  [accountActions.SET_SELECTED_AUTO_DETECTED_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountNo, isSelect } = payload;

    const targetRow = _.find(state.manageAccounts.addAccounts.autoDetectedTable, a => _.get(a, 'accountNo') === accountNo);
    if (targetRow)
      _.set(targetRow, "isSelected", isSelect);

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          autoDetectedTable: [...state.manageAccounts.addAccounts.autoDetectedTable],
        }
      }
    };
  },
  [accountActions.UPDATE_AUTO_DETECTED_TABLE]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountNo, referenceId } = payload;
    const autoDetectedTable = _.get(state.manageAccounts.addAccounts, "autoDetectedTable", []);
    const targetIndex = _.findIndex(autoDetectedTable, (m:any) => m.accountNo === accountNo);
    _.set(state.manageAccounts.addAccounts, `autoDetectedTable[${targetIndex}].referenceId`, referenceId);

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          autoDetectedTable: _.get(state.manageAccounts.addAccounts, "autoDetectedTable"),
        }
      }
    }
  },
  [accountActions.CLEAR_MANUAL_ACCOUNTS_IN_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          manualAddTable: [],
        }
      }
    }
  },
  [accountActions.CLEAR_AUTO_ACCOUNTS_IN_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          autoDetectedTable: [],
        }
      }
    }
  },
  [accountActions.UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const uploadAccounts = _.map(payload, p => {
      if (_.findIndex(state.manageAccounts.addAccounts.autoDetectedTable, (a:any) => a.accountNo === p.accountNumber) > -1)
        return {
          accountNo: p.accountNumber,
          referenceId: p.referenceID,
          accountNoHasError: true,
          accountNoErrorTypes: ["modules.bulkAccount.registration.errorOfDuplicate", ...p.errorTypeList],
          accountNoIsLoading: false
        };
      else
        return {
          accountNo: p.accountNumber,
          referenceId: p.referenceID,
          accountNoHasError: !_.isEmpty(p.errorType),
          accountNoErrorTypes: p.errorTypeList,
          accountNoIsLoading: false,
        }
    });
    
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          uploadAccounts: uploadAccounts,
        }
      }
    }
  },
  [accountActions.GET_CUSTOMER_REGISTRATION_DATA_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      registration: {
        ...state.registration,
        customerRegistration: {
          ...state.registration.customerRegistration,
          customerRegistrationErrorMessage: _.get(payload, "errorMessage"),
        }
      }
    }
  },
  [accountActions.UPDATE_BULK_REGISTRATION_STATUS_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      registration: {
        ...state.registration,
        customerRegistration: {
          ...state.registration.customerRegistration,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        }
      }
    }
  },
  [accountActions.VALIDATE_BULK_CHILD_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountNumber, errorTypeList } = payload;
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          validatedAccount: {
            accountNo: accountNumber,
            errorType: errorTypeList,
          }
        }
      }
    }
  },
  [accountActions.ADD_ELECTRICITY_ACCOUNTS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          noOfSuccessAccount: payload,
        }
      }
    }
  },
  [accountActions.ADD_ELECTRICITY_ACCOUNTS_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        }
      }
    }
  },
  [accountActions.UPDATE_ERRORACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const {uniqueId, propertyName, propertyValue} = payload;
    const pendingAccTable = _.get(state.manageAccounts.pendingCA, "pendingAccTable", []);
    const targetIndex = _.findIndex(pendingAccTable, (e:any) => e.uniqueId === uniqueId);

    if (targetIndex > -1) {
      _.set(state.manageAccounts.pendingCA, `pendingAccTable[${targetIndex}].${propertyName}`, propertyValue);
    }

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          pendingAccTable: _.get(state.manageAccounts.pendingCA, "pendingAccTable"),
        },
      }
    }
  },
  [accountActions.SET_SELECTED_ERRORACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { uniqueId, isSelect } = payload;
    const pendingAccTable = _.get(state.manageAccounts.pendingCA, "pendingAccTable", []);

    const targetRow = _.find(pendingAccTable, a => _.get(a, 'uniqueId') === uniqueId);
    if (targetRow)
      _.set(targetRow, "isSelected", isSelect);

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          pendingAccTable: _.get(state.manageAccounts.pendingCA, "pendingAccTable"),
        },
      }
    }
  },
  [accountActions.GET_PENDING_CA]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { GetBulkChildAccountDetailsErrorModelDetails, count } = payload;

    const pendingCATable = _.forEach(GetBulkChildAccountDetailsErrorModelDetails, p => {
      _.set(p, "accountNoHasError", !!p.errorType && p.statusCode === pendingCAStatusCode.TAGGING);
      _.set(p, "status", p.errorType ? "error" : "pending");
      _.set(p, "uniqueId", _.uniqueId());
    });
    
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          pendingAccTable: [...pendingCATable],
          pendingAccCount: _.isNull(count) ? _.get(pendingCATable, 'length') : count,
        },
      }
    }
  },
  [accountActions.REMOVE_ACCOUNT_FROM_PENDING_CA]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        },
      }
    }
  },
  [accountActions.SUBMIT_PENDING_CA]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          noOfSuccessAccount: payload,
        },
      }
    }
  },
  [accountActions.REMOVE_ACCOUNT_FROM_PENDING_CA_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        }
      }
    }
  },
  [accountActions.SUBMIT_PENDING_CA_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        }
      }
    }
  },
  [accountActions.VALIDATE_BULK_CA_IN_PENDING_CA]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountNumber, errorTypeList } = payload;
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          validatedAccount: {
            accountNo: accountNumber,
            errorType: errorTypeList,
          }
        }
      }
    }
  },
  [accountActions.GET_AUTO_SUGGESTIVE_IN_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accounts } = payload;
    
    const existingAccount = _.get(state, "manageAccounts.addAccounts.autoDetectedTable", []);
    const existingSelectedAccount = _.filter(existingAccount, (a:any) => a.isSelected === true);
    _.forEach(accounts, (a:any) => {
      if (_.findIndex(existingSelectedAccount, (ea:any) => a.accountNo === ea.accountNo) > -1)
        _.set(a, "isSelected", true);
    });

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          autoDetectedTable: [...accounts],
        }
      }
    }
  },
  [accountActions.UPDATE_USER_PROFILE_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      myProfile: {
        ...state.myProfile,
        errorTopic: _.get(payload, 'errorTopic'),
        errorMessage: _.get(payload, 'errorMessage'),
      }
    };
  },
  [accountActions.GET_BULKCHILDACCOUNTERROR]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const bulkCAs = _.get(payload, 'getBulkChildAccountErrorForAdminDetailModel', []);
    
    return {
      ...state,
      bulkAccounts: {
        ...state.bulkAccounts,
        pendingCA: _.map(bulkCAs, (b:any) => ({
          ...b,
          status: b.errorType ? "error" : "pending"
        })),
        pendingCACount: _.get(payload, 'count', 0),
      }
    };
  },
}

const adminAccountReducer = (state: IAccountReducer = defaultState, { type, payload, error }: IReducerAction = { type: '' }) => {
  if (!type)
    return state;

  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
}
export default adminAccountReducer;
export const REDUCER_ACOUNT = 'adminAccountReducer'
