import _ from "lodash";

export const PAYMENT_STATUS_NAME = {
  PAYMENTREQUIRED: "paymentRequired",
  PENDINGAPPROVAL: "pendingApproval",
  PROCESSING: "processing",
  PAID: "paid",
  FAILED: "failed",
  DECLINED: "declined",
  OVERPAYPENDING: "overpayPending",
  INSUFFICIENTPENDING: "insufficientPending",
  PAYMENTINITIATED: "paymentInitiated",
  FPXPENDING: "fpxPending",
  FPXDECLINED: "fpxDeclined",
}

export const PAYMENT_STATUS_TYPES = {
  "1": {
    code: "1",
    name: PAYMENT_STATUS_NAME.PAYMENTREQUIRED,
    label: "components.filter.paymentRequired",
    sortSequence: 7,
  },
  "2": {
    code: "2",
    name: PAYMENT_STATUS_NAME.PENDINGAPPROVAL,
    label: "components.filter.pendingApproval",
    sortSequence: 11,
  },
  "3": {
    code: "3",
    name: PAYMENT_STATUS_NAME.PROCESSING,
    label: "components.filter.processing",
    sortSequence: 15,
  },
  "4": {
    code: "4",
    name: PAYMENT_STATUS_NAME.PAID,
    label: "components.filter.paid",
    sortSequence: 5,
  },
  "5": {
    code: "5",
    name: PAYMENT_STATUS_NAME.FAILED,
    label: "components.filter.failed",
    sortSequence: 2,
  },
  "6": {
    code: "6",
    name: PAYMENT_STATUS_NAME.DECLINED,
    label: "components.filter.declined",
    sortSequence: 1,
  },
  "7": {
    code: "7",
    name: PAYMENT_STATUS_NAME.OVERPAYPENDING,
    label: "components.filter.overpayPending",
    sortSequence: 14,
  },
  "8": {
    code: "8",
    name: PAYMENT_STATUS_NAME.INSUFFICIENTPENDING,
    label: "components.filter.insufficientPending",
    sortSequence: 13,
  },
  "9": {
    code: "9",
    name: PAYMENT_STATUS_NAME.PAYMENTINITIATED,
    label: "components.filter.paymentInitiated",
    sortSequence: 6,
  },
  "10": {
    code: "10",
    name: PAYMENT_STATUS_NAME.FPXPENDING,
    label: "components.filter.fpxPending",
    sortSequence: 12,
  },
  "11": {
    code: "11",
    name: PAYMENT_STATUS_NAME.FPXDECLINED,
    label: "components.filter.fpxDeclined",
    sortSequence: 3,
  },
};

export const PAYMENTSTATUS_AS_NAME = _.mapKeys(PAYMENT_STATUS_TYPES, (value:any) => value.name);

export const PAYMENT_ACTIVITY_TYPE = {
  INITIAL_DATA: "initialData",
  PENDING_APPROVAL: "pendingApproval",
  PROCESSING: "processing",
  INSUFFICIENT_PENDING: "insufficientPending",
  INSUFFICIENT: "insufficient",
  OVERPAY: "overpay",
  OVERPAY_PENDING: "overpayPending",
};
