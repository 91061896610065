import _ from 'lodash';

import * as userActions from 'src/redux/actions/admin/userAction';
import { IReducerAction, IReducer, IPayloadObj } from '../_commonInterface';

type NullableNumberString = number | string | null;

interface IManageUsers {
  totalUsers: NullableNumberString,
  activeUsers: NullableNumberString,
  inactiveUsers: NullableNumberString,
  invitesPending: NullableNumberString
  manageUsersTable: object[],
  manageUsersCount: number,
  maxUserLimit: number,
}

interface IPreviousSearch {
  previousSearchBy: string,
  previousSearchResultValue: string,
}
interface ICustomerViewMode {
  searchResult: object[],
  previousSearch: IPreviousSearch,
}

interface IUserReducer {
  customerViewMode: ICustomerViewMode,
  adminsSummary: {
    totalUsers: NullableNumberString,
    activeUsers: NullableNumberString,
    inactiveUsers: NullableNumberString
  },
  usersSummary: {
    totalUsers: NullableNumberString,
    activeUsers: NullableNumberString,
    inactiveUsers: NullableNumberString
  },
  manageUsers: IManageUsers,
  adminManagementData: object[],
  adminManagementDataCount: number,
  userManagementData: object[],
  userManagementDataCount: number,
  adminActionEmailAddress: string,
  transferMasterRole: object,
}

const defaultState: IUserReducer = {
  customerViewMode: {
    searchResult: [],
    previousSearch: {
      previousSearchBy: '',
      previousSearchResultValue: '',
    },
  },
  adminsSummary: {
    totalUsers: '-',
    activeUsers: '-',
    inactiveUsers: '-'
  },
  usersSummary: {
    totalUsers: '-',
    activeUsers: '-',
    inactiveUsers: '-'
  },
  manageUsers: {
    totalUsers: "-",
    activeUsers: "-",
    inactiveUsers: "-",
    invitesPending: "-",
    manageUsersTable: [],
    manageUsersCount: 0,
    maxUserLimit: 0,
  },
  adminActionEmailAddress: "",
  adminManagementData: [],
  adminManagementDataCount: 0,
  userManagementData: [],
  userManagementDataCount: 0,
  transferMasterRole: {},
}

const REDUCERS: IReducer = {
  [userActions.SEARCH_CVM]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      customerViewMode: {
        ...state.customerViewMode,
        searchResult: payload,
      }
    }
  },
  [userActions.GET_USERS]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      userSummary: {
        totalUsers: _.get(payload, 'totalUsers') ?? '-',
        activeUsers: _.get(payload, 'activeUsers') ?? '-',
        inactiveUsers: _.get(payload, 'inactiveUsers') ?? '-',
      }
    }
  },
  [userActions.GET_MANAGE_USERS_CARDS]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageUsers: {
        ...state.manageUsers,
        totalUsers: _.get(payload, 'totalUser') ?? '-',
        activeUsers: _.get(payload, 'activeUser') ?? '-',
        inactiveUsers: _.get(payload, 'inactiveUser') ?? '-',
        invitesPending: _.get(payload, 'invitesPending') ?? '-',
      }
    };
  },
  [userActions.ADD_NEW_USER]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageUsers: {
        ...state.manageUsers,
        manageUsersTable: state.manageUsers.manageUsersTable.concat({
          ...payload,
          lastLoggedIn: _.get(payload, "lastLoggedIn") ? payload.lastLoggedIn + "Z" : "-",
        }),
        manageUsersCount: state.manageUsers.manageUsersCount + 1,
        newUserEmail: _.get(payload, "emailAddress"),
      }
    };
  },
  [userActions.ADD_NEW_USER_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      addUserErrorTitle: _.get(payload, 'errorTopic'),
      addUserErrorMessage: _.get(payload, 'errorMessage'),
    }
  },
  [userActions.SET_ADD_USER_ERROR]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      addUserErrorTitle: _.get(payload, "errorTopic"),
      addUserErrorMessage: _.get(payload, "errorMessage"),
    };
  },
  [userActions.GET_MANAGE_USERS]: (state: IUserReducer, { payload }: IPayloadObj) => {
    const { data, count, maxUserLimit } = payload;
    _.forEach(data, b => {
      b.username ?? _.set(b, "username", "-");
      b.emailAddress ?? _.set(b, "emailAddress", "-");
      _.set(b, "lastLoggedIn", b.lastLoggedIn ? b.lastLoggedIn + "Z" : "-");
    });
    return {
      ...state,
      manageUsers: {
        ...state.manageUsers,
        manageUsersTable: [...data],
        manageUsersCount: _.isNull(count) ? _.get(data, 'length') : count,
        maxUserLimit,
      }
    };
  },
  [userActions.UPDATE_BULK_USER]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageUsers: {
        ...state.manageUsers,
        manageUsersTable: state.manageUsers.manageUsersTable.map(
          (content: any) => content.userId === payload.userId ?
            {
              userId: payload.userId,
              username: payload.username ?? "-",
              emailAddress: payload.emailAddress ?? "-",
              nickname: payload.nickname,
              role: payload.role,
              status: payload.status,
              lastLoggedIn: payload.lastLoggedIn ? payload.lastLoggedIn + "Z" : "-",
            } : content)
      }
    };
  },
  [userActions.UPDATE_BULK_USER_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      updateUserErrorTitle: _.get(payload, 'errorTopic'),
      updateUserErrorMessage: _.get(payload, 'errorMessage'),
    }
  },
  [userActions.DELETE_BULK_USER]: (state: IUserReducer, { payload }: IPayloadObj) => {
    const result = state.manageUsers.manageUsersTable.filter((item: any) => item.userId !== payload.userId);
    return {
      ...state,
      manageUsers: {
        ...state.manageUsers,
        manageUsersTable: result,
        manageUsersCount: result.length,
        deletedUserEmail: payload.emailAddress,
      }
    };
  },
  [userActions.DELETE_BULK_USER_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      removeUserErrorTitle: _.get(payload, 'errorTopic'),
      removeUserErrorMessage: _.get(payload, 'errorMessage'),
    }
  },
  [userActions.TRANSFER_MASTER_ROLE_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      transferMasterRole: {
        ...state.transferMasterRole,
        errorTopic: _.get(payload, 'errorTopic'),
        errorMessage: _.get(payload, 'errorMessage'),
      }
    };
  },
  [userActions.GET_TNBADMINSCARDS]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      adminsSummary: {
        totalUsers: _.get(payload, 'totalUser') ?? '-',
        activeUsers: _.get(payload, 'activeUser') ?? '-',
        inactiveUsers: _.get(payload, 'inactiveUser') ?? '-',
      }
    }
  },
  [userActions.GET_TNBADMINSTABLE]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      adminManagementData: payload.data.map((u:any) => ({
        ...u,
        lastLoggedIn: _.isNull(u.lastLoggedIn) ? "-" : u.lastLoggedIn + "Z"
      })),
      adminManagementDataCount: _.isNull(payload.count) ? _.get(payload.data, 'length') : payload.count
    }
  },
  [userActions.ADD_TNBADMIN]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      adminManagementData: state.adminManagementData.concat({
        ...payload,
        lastLoggedIn: _.isNull(payload.lastLoggedIn) ? "-" : payload.lastLoggedIn + "Z"
      }),
      adminManagementDataCount: state.adminManagementDataCount + 1, 
      adminActionEmailAddress: payload.emailAddress
    }
  },
  [userActions.ADD_TNBADMIN_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      adminManagementErrorTitle: _.get(payload, 'errorTopic'),
      adminManagementErrorMessage: _.get(payload, 'errorMessage')
    }
  },
  [userActions.UPDATE_TNBADMIN]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      adminManagementData: state.adminManagementData.map(
        (u: any) => u.userId === payload.userId ?
          {
            ...payload,
            lastLoggedIn: _.isNull(payload.lastLoggedIn) ? "-" : payload.lastLoggedIn + "Z"
          } : u),
      adminActionEmailAddress: payload.emailAddress
    }
  },
  [userActions.UPDATE_TNBADMIN_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      adminManagementErrorTitle: _.get(payload, 'errorTopic'),
      adminManagementErrorMessage: _.get(payload, 'errorMessage')
    }
  },
  [userActions.DELETE_TNBADMIN]: (state: IUserReducer, { payload }: IPayloadObj) => {
    const result = state.adminManagementData.filter((item: any) => item.userId !== payload.userId)
    return {
      ...state,
      adminManagementData: result,
      adminManagementDataCount: result.length
    }
  },
  [userActions.DELETE_TNBADMIN_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      adminManagementErrorTitle: _.get(payload, 'errorTopic'),
      adminManagementErrorMessage: _.get(payload, 'errorMessage')
    }
  },
  [userActions.RESEND_ACTIVATIONTNBADMIN_FAILED]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      adminManagementErrorTitle: _.get(payload, 'errorTopic'),
      adminManagementErrorMessage: _.get(payload, 'errorMessage')
    }
  },
  [userActions.UPDATE_CVM_SEARCH_RESULT]: (state: IUserReducer, { payload }: IPayloadObj) => {
    const { searchBy, searchResultValue } = payload;
    return {
      ...state,
      customerViewMode: {
        ...state.customerViewMode,
        previousSearch: {
          ...state.customerViewMode.previousSearch,
          previousSearchBy: searchBy,
          previousSearchResultValue: searchResultValue,
        }
      }
    }
  },
  [userActions.GET_TNBUSERSCARDS]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      usersSummary: {
        totalUsers: _.get(payload, 'totalUser') ?? '-',
        activeUsers: _.get(payload, 'activeUser') ?? '-',
        inactiveUsers: _.get(payload, 'inactiveUser') ?? '-',
      }
    }
  },
  [userActions.GET_TNBUSERSTABLE]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      userManagementData: payload.getUserTableDetailModels.map((u:any) => ({
        ...u,
        activationString: _.isNull(u.activationStatus) ? "-" : u.activationStatus ? "true" : "false",
        lastLoggedIn: _.isNull(u.lastLoggedIn) ? "-" : u.lastLoggedIn + "Z",
        lastSentActivationEmail:  _.isNull(u.lastSentActivationEmail) ? "-" : u.lastSentActivationEmail + "Z",
      })),
      userManagementDataCount: _.isNull(payload.count) ? _.get(payload.getUserTableDetailModels, 'length') : payload.count
    }
  },
  [userActions.DELETE_TNBUSER]: (state: IUserReducer, { payload }: IPayloadObj) => {
    const result = state.userManagementData.filter((item: any) => item.userId !== payload.userId)
    return {
      ...state,
      userManagementData: result,
      userManagementDataCount: result.length
    }
  },
  [userActions.RESEND_ACTIVATIONTNBUSER]: (state: IUserReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      userManagementData: state.userManagementData.map(
        (u: any) => u.userId === payload.userId ?
          {
            ...u,
            lastSentActivationEmail:  _.isNull(payload.lastSentActivationEmail) ? "-" : payload.lastSentActivationEmail + "Z",
          } : u),
    }
  }
}

const adminUserReducer = (state: IUserReducer = defaultState, { type, payload, error }: IReducerAction = { type: '' }) => {
  if (!type)
    return state;

  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
}
export default adminUserReducer;
export const REDUCER_ACOUNT = 'adminUserReducer'
