import React from "react"
import { Navigate, RouteObject } from 'react-router-dom';
import _ from 'lodash';

import { store } from "src/redux/redux";
export interface AdditionalProps {
  additionalProp: string;
}

export interface RouteObjectWithAccessRoles extends RouteObject {
  accessRoles?: string[];
}

//#region Private Methods
const userCanAccess = (userRoles: string[], accessRole: string[]): boolean => {
  return (
    _.isEmpty(accessRole) ||
    (_.isArray(accessRole)
      ? !!_.intersection(accessRole, userRoles).length
      : _.includes(userRoles, accessRole))
  )
}

const userHasAllAccess = (userRoles: string[], accessRole: string[]): boolean => {
  return (
    !_.isEmpty(userRoles) && 
      !_.isEmpty(accessRole) && 
      _.intersection(accessRole, userRoles).length === accessRole.length
  )
}

//#endregion

export const checkUserProfileAccessWithRoles = (accessRoles: string[] = []) => {
  const state = store.getState();
  const sessionScopes = _.get(state, 'sessionReducer.userRolesForScope');

  return userCanAccess(sessionScopes, accessRoles);
}

export const checkUserProfileAccessHasAllRoles = (accessRoles: string[] = []) => {
  const state = store.getState();
  const sessionScopes = _.get(state, 'sessionReducer.userRolesForScope');

  return userHasAllAccess(sessionScopes, accessRoles);
}

const WithAuthRole = <P extends AdditionalProps>(WrappedComponent: React.ComponentType<P>, accessRoles?: string[]): React.ComponentType<Omit<P, 'additionalProp'>> => {
  const additionalProp = {};
  return (props): JSX.Element => {

    const state = store.getState();
    const { accessToken, userRolesForScope } = state.sessionReducer;

    const currentPathname = _.get(document, 'location.pathname');
    const isAdminRoute = _.startsWith(currentPathname, '/admin');

    if (!accessToken)
      return isAdminRoute ? <Navigate to={`/admin/login?redirect=${currentPathname}`} /> : <Navigate to={`/?redirect=${currentPathname}`} />;

    const isUserCanAccess = userCanAccess(userRolesForScope, accessRoles || []);

    if (!isUserCanAccess)
      return <Navigate to="/unauthorized" />;

    return <WrappedComponent
      additionalProp={additionalProp}
      {...props as any}
    />
  };
};

export default WithAuthRole;