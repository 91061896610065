import React from "react";

import UserSessionHandler from 'src/containers/views/_common/userSessionHandler';

export interface AdditionalProps {
  additionalProp: string;
}

const WithSessionHandler = <P extends AdditionalProps>(WrappedComponent: React.ComponentType<P>): React.ComponentType<Omit<P, 'additionalProp'>> => {
  const additionalProp = {};
  return (props): JSX.Element => {
    return (
      <>
        <WrappedComponent
          additionalProp={additionalProp}
          {...props as any}
        />
        <UserSessionHandler />
      </>
    )
  };
};

export default WithSessionHandler;