import _ from 'lodash';
import * as paymentActions from 'src/redux/actions/admin/paymentAction';
import { IReducerAction, IReducer, IPayloadObj } from '../_commonInterface';
import { businessTypeHelper } from 'src/constants/businessType';

type NullableNumberString = number | string | null;
type NullableBoolean = boolean | null;

interface IPendingApproval {
  totalAmount: NullableNumberString;
  pendingApproval: NullableNumberString;
  totalInvoices: NullableNumberString;
}

interface IAllPayments {
  totalAmountPaid: NullableNumberString;
  pendingApproval: NullableNumberString;
  paymentsInProcess: NullableNumberString;
  unsuccessfulPayments: NullableNumberString;
  allPaymentsTable: object[];
  allPaymentsCount: number;
}

interface IAdminPaymentDetailsHead {
  companyName: NullableNumberString;
  bulkAccountNo: NullableNumberString;
  status: NullableNumberString;
  transactionNo: NullableNumberString;
  billedAmount: NullableNumberString;
  totalInvoices: NullableNumberString;
  totalElectricityAccounts: NullableNumberString;
  approvedORRejected: NullableBoolean;
  approveORRejectedBy: NullableNumberString;
  approveORRejectedDate: NullableNumberString;
  rejectedReason: NullableNumberString;
  eBulkPaymentID: NullableNumberString;
}

interface IAdminPaymentDetailsInit {
  paidOn: NullableNumberString;
  modeofPaymentDescription: NullableNumberString;
  transactionAmount: NullableNumberString;
  reconciliationAmount: NullableNumberString;
  chequeEFTNo: NullableNumberString;
  bankName: NullableNumberString;
  activityType: string;
  activityTime: string;
}

interface IAdminPaymentDetailsInsufficient {
  requestedOn: NullableNumberString;
  reconciliationAmount: NullableNumberString;
  requestedBy: NullableNumberString;
  reconcileSubmitted: NullableBoolean;
  reconciledOn: NullableNumberString;
  modeofPaymentDescriptionInsufficient: NullableNumberString;
  paidAmountInsufficient: NullableNumberString;
  chequeEFTNumber: NullableNumberString;
  bankName: NullableNumberString;
}

interface IAdminPaymentDetailsExcessive {
  requestedOn: NullableNumberString;
  reconciliationAmount: NullableNumberString;
  requestedBy: NullableNumberString;
  reconcileSubmitted: NullableBoolean;
  reconciledOn: NullableNumberString;
}

interface IAdminPaymentDetailsExcessiveAccount {
  reconciledOn: NullableNumberString;
  amountExcessive: NullableNumberString;
  contractAccountExcessive: NullableNumberString;
}

interface IAdminPaymentDetailsInvoices {
  contractAccountNo: NullableNumberString;
  referenceNo: NullableNumberString;
  collectiveInvoiceNo: NullableNumberString;
  invoiceNo: NullableNumberString;
  transactionAmount: NullableNumberString;
}

interface IPaymentReducer {
  pendingApproval: IPendingApproval;
  allPayments: IAllPayments;
  paymentDetails: {
    adminPaymentDetailsHead: IAdminPaymentDetailsHead;
    adminPaymentDetailsInit: IAdminPaymentDetailsInit;
    adminPaymentDetailsInsufficient: IAdminPaymentDetailsInsufficient[];
    adminPaymentDetailsExcessive: IAdminPaymentDetailsExcessive[];
    adminPaymentDetailsExcessiveAccount: IAdminPaymentDetailsExcessiveAccount[];
    adminPaymentDetailsInvoices: IAdminPaymentDetailsInvoices[];
  };
  pgSearchResult: {
    isQuerying: boolean;
    merchantTransactionId: string;
    customerName: string;
    transactionStatus: string;
    errorDescription: string;
    bankName: string;
    bankReference: string;
    bankTransactionDate: string;
    paymentTransactionDate: string;
    totalAmount: NullableNumberString;
    bulkAccountNo: string;
    companyName: string;
    fpxRequeryLastRunDate: string;
    totalInvoices: NullableNumberString;
    invoiceList: {
      billBatchId: string;
      accountNo: string;
      invoiceNo: string;
      amount: NullableNumberString;
    }[];
    isRequeryEnabled: boolean;
    requeryErrorMessage: string;
  };
}

const defaultState: IPaymentReducer = {
  pendingApproval: {
    totalAmount: '-',
    pendingApproval: '-',
    totalInvoices: '-',
  },
  allPayments: {
    totalAmountPaid: '-',
    pendingApproval: '-',
    paymentsInProcess: '-',
    unsuccessfulPayments: '-',
    allPaymentsTable: [],
    allPaymentsCount: 0,
  },
  paymentDetails: {
    adminPaymentDetailsHead: {
      companyName: '-',
      bulkAccountNo: '-',
      status: '-',
      transactionNo: '-',
      billedAmount: '-',
      totalInvoices: '-',
      totalElectricityAccounts: '-',
      approvedORRejected: null,
      approveORRejectedBy: '-',
      approveORRejectedDate: '-',
      rejectedReason: '-',
      eBulkPaymentID: '-',
    },
    adminPaymentDetailsInit: {
      paidOn: '-',
      modeofPaymentDescription: '-',
      transactionAmount: '-',
      reconciliationAmount: '-',
      chequeEFTNo: '-',
      bankName: '-',
      activityType: 'initialData',
      activityTime: '-',
    },
    adminPaymentDetailsInsufficient: [],
    adminPaymentDetailsExcessive: [],
    adminPaymentDetailsExcessiveAccount: [],
    adminPaymentDetailsInvoices: [],
  },
  pgSearchResult: {
    isQuerying: false,
    merchantTransactionId: "",
    customerName: '-',
    transactionStatus: '-',
    errorDescription: '-',
    bankName: '-',
    bankReference: '-',
    bankTransactionDate: '-',
    paymentTransactionDate: '-',
    totalAmount: '-',
    bulkAccountNo: '-',
    companyName: '-',
    fpxRequeryLastRunDate: '-',
    totalInvoices: '-',
    invoiceList: [],
    isRequeryEnabled: false,
    requeryErrorMessage: ''
  }
}

const REDUCERS: IReducer = {
  [paymentActions.GET_PENDING_APPROVAL_CARDS]: (state: IPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      pendingApproval: {
        ...state.pendingApproval,
        totalAmount: _.get(payload, 'totalAmount') ?? '-',
        pendingApproval: _.get(payload, 'pendingApproval') ?? '-',
        totalInvoices: _.get(payload, 'totalInvoices') ?? '-',
      }
    };
  },
  [paymentActions.GET_PENDING_APPROVAL_TABLE]: (state: IPaymentReducer, { payload }: IPayloadObj) => {
    const { payments, count } = payload;
    _.forEach(payments, p => {
      _.set(p, "paymentDate", _.isNull(p.paymentDate) ? "-" : p.paymentDate + "Z");
      _.set(p, "businessType", p.businessType ? businessTypeHelper(p.businessType) : undefined);
    });
    return {
      ...state,
      pendingApproval: {
        ...state.pendingApproval,
        pendingApprovalTable: [...payments],
        pendingApprovalCount: _.isNull(count) ? _.get(payments, 'length') : count,
      }
    };
  },
  [paymentActions.GET_ALL_PAYMENTS_CARDS]: (state: IPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      allPayments: {
        ...state.allPayments,
        totalAmountPaid: _.get(payload, 'totalAmountPaid') ?? '-',
        pendingApproval: _.get(payload, 'pendingApproval') ?? '-',
        paymentsInProcess: _.get(payload, 'paymentsInProcess') ?? '-',
        unsuccessfulPayments: _.get(payload, 'unsuccessfulPayments') ?? '-',
      }
    };
  },
  [paymentActions.GET_ALL_PAYMENTS_TABLE]: (state: IPaymentReducer, { payload }: IPayloadObj) => {
    const { payments, count } = payload;
    _.forEach(payments, p => {
      _.set(p, "paymentDate", _.isNull(p.paymentDate) ? "-" : p.paymentDate + "Z");
      _.set(p, "businessType", p.businessType ? businessTypeHelper(p.businessType) : undefined);
    });
    return {
      ...state,
      allPayments: {
        ...state.allPayments,
        allPaymentsTable: [...payments],
        allPaymentsCount: _.isNull(count) ? _.get(payments, 'length') : count,
      }
    };
  },
  [paymentActions.GET_PAYMENT_DETAILS]: (state: IPaymentReducer, { payload }: IPayloadObj) => {
    const {
      adminPaymentDetailsHead,
      adminPaymentDetailsInit,
      adminPaymentDetailsInsufficient,
      adminPaymentDetailsExcessive,
      adminPaymentDetailsExcessiveAccount,
      adminPaymentDetailsInvoices,
    } = payload;

    //#region adminPaymentDetailsInsufficient
    _.forEach(adminPaymentDetailsInsufficient, a => {
      _.set(a, "activityType", "insufficient");
      if (a.reconcileSubmitted && a.reconciledOn) {
        _.set(a, "activityTime", a.reconciledOn + "Z");
        _.set(a, "reconciledOn", a.reconciledOn + "Z");
      } else {
        _.set(a, "reconciledOn", "-");
        _.set(a, "activityTime", "-");
        if (a.requestedOn) _.set(a, "activityTime", a.requestedOn + "Z");
      }
      _.set(a, "requestedOn", _.isNull(a.requestedOn) ? "-" : a.requestedOn + "Z");
      if (_.isNull(a.reconciliationAmount)) _.set(a, "reconciliationAmount", "-");
      if (_.isNull(a.requestedBy)) _.set(a, "requestedBy", "-");
      if (_.isNull(a.reconcileSubmitted)) _.set(a, "reconcileSubmitted", false);
      if (_.isNull(a.modeofPaymentDescriptionInsufficient)) _.set(a, "modeofPaymentDescriptionInsufficient", "-");
      if (_.isNull(a.paidAmountInsufficient)) _.set(a, "paidAmountInsufficient", "-");
      if (_.isNull(a.chequeEFTNumber)) _.set(a, "chequeEFTNumber", "-");
      if (_.isNull(a.bankName)) _.set(a, "bankName", "-");
    });
    //#endregion adminPaymentDetailsInsufficient

    //#region adminPaymentDetailsExcessive
    _.forEach(adminPaymentDetailsExcessive, a => {
      _.set(a, "activityType", "overpay");
      if (a.requestedOn) {
        _.set(a, "activityTime", a.requestedOn + "Z");
        _.set(a, "requestedOn", a.requestedOn + "Z");
      } else {
        _.set(a, "requestedOn", "-");
        _.set(a, "activityTime", "-");
      }
      if (_.isNull(a.reconciliationAmount)) _.set(a, "reconciliationAmount", "-");
      if (_.isNull(a.requestedBy)) _.set(a, "requestedBy", "-");
      if (_.isNull(a.reconcileSubmitted)) _.set(a, "reconcileSubmitted", false);
      _.set(a, "reconciledOn", _.isNull(a.reconciledOn) ? "-" : a.reconciledOn + "Z");
    });
    //#endregion adminPaymentDetailsExcessive

    //#region adminPaymentDetailsExcessiveAccount
    _.forEach(adminPaymentDetailsExcessiveAccount, a => {
      _.set(a, "activityType", "overpayPending");
      if (a.reconciledOn) {
        _.set(a, "activityTime", a.reconciledOn + "Z");
        _.set(a, "reconciledOn", a.reconciledOn + "Z");
      } else {
        _.set(a, "reconciledOn", "-");
        _.set(a, "activityTime", "-");
      }
      if (_.isNull(a.amountExcessive)) _.set(a, "amountExcessive", "-");
      if (_.isNull(a.contractAccountExcessive)) _.set(a, "contractAccountExcessive", "-");
    });
    //#endregion adminPaymentDetailsExcessiveAccount

    return {
      ...state,
      paymentDetails: {
        ...state.paymentDetails,
        adminPaymentDetailsHead: {
          companyName: _.get(adminPaymentDetailsHead, 'companyName') ?? '-',
          bulkAccountNo: _.get(adminPaymentDetailsHead, 'bulkAccountNo') ?? '-',
          status: _.get(adminPaymentDetailsHead, 'status') ?? '-',
          transactionNo: _.get(adminPaymentDetailsHead, 'transactionNo') ?? '-',
          billedAmount: _.get(adminPaymentDetailsHead, 'billedAmount') ?? '-',
          totalInvoices: _.get(adminPaymentDetailsHead, 'totalInvoices') ?? '-',
          totalElectricityAccounts: _.get(adminPaymentDetailsHead, 'totalElectricityAccounts') ?? '-',
          approvedORRejected: _.isNull(adminPaymentDetailsHead) ? null : adminPaymentDetailsHead.approvedORRejected,
          approveORRejectedBy: _.get(adminPaymentDetailsHead, 'approveORRejectedBy') ?? '-',
          approveORRejectedDate: _.get(adminPaymentDetailsHead, 'approveORRejectedDate') ? adminPaymentDetailsHead.approveORRejectedDate + "Z" : "-",
          rejectedReason: _.get(adminPaymentDetailsHead, 'rejectedReason') ?? '-',
          eBulkPaymentID: _.get(adminPaymentDetailsHead, 'eBulkPaymentID') ?? '-',
        },
        adminPaymentDetailsInit: {
          ...state.paymentDetails.adminPaymentDetailsInit,
          paidOn: _.get(adminPaymentDetailsInit, 'paidOn') ? adminPaymentDetailsInit.paidOn + "Z" : '-',
          modeofPaymentDescription: _.get(adminPaymentDetailsInit, 'modeofPaymentDescription') ?? '-',
          transactionAmount: _.get(adminPaymentDetailsInit, 'transactionAmount') ?? '-',
          reconciliationAmount: _.get(adminPaymentDetailsInit, 'reconciliationAmount') ?? '-',
          chequeEFTNo: _.get(adminPaymentDetailsInit, 'chequeEFTNo') ?? '-',
          bankName: _.get(adminPaymentDetailsInit, 'bankName') ?? '-',
          activityTime: _.get(adminPaymentDetailsInit, 'paidOn') ? adminPaymentDetailsInit.paidOn + "Z" : '-',
        },
        adminPaymentDetailsInsufficient: adminPaymentDetailsInsufficient,
        adminPaymentDetailsExcessive: adminPaymentDetailsExcessive,
        adminPaymentDetailsExcessiveAccount: adminPaymentDetailsExcessiveAccount,
        adminPaymentDetailsInvoices: [...adminPaymentDetailsInvoices],
      }
    };
  },
  [paymentActions.SET_ISQUERYING]: (state: IPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      pgSearchResult: {
        ...state.pgSearchResult,
        isQuerying: payload
      }
    };
  },
  [paymentActions.GET_FPX_REQUERYDATA]: (state: IPaymentReducer, { payload }: IPayloadObj) => {
    const invoiceList = _.map(_.get(payload, "getFpxRequeryDataInvoiceModelList", []), inv => ({
      billBatchId: inv.collectiveInvoiceNo,
      accountNo: inv.contractAccount,
      invoiceNo: inv.invoiceNo,
      amount: inv.amount,
    }));
    
    return {
      ...state,
      pgSearchResult: {
        ...state.pgSearchResult,
        isQuerying: false,
        merchantTransactionId: payload.merchantTransactionId,
        customerName: _.isEmpty(payload.custName) ? "-" : payload.custName,
        transactionStatus: _.isEmpty(payload.txnStatus) ? "-" : payload.txnStatus,
        errorDescription: _.isEmpty(payload.errDesc) ? "-" : payload.errDesc,
        bankName: _.isEmpty(payload.acquirerBank) ? "-" : payload.acquirerBank,
        bankReference: _.isEmpty(payload.bankReference) ? "-" : payload.bankReference,
        bankTransactionDate: _.isEmpty(payload.bankTranDate) ? "-" : _.get(payload, "bankTranDate") + "Z",
        paymentTransactionDate: _.isEmpty(payload.tranDate) ? "-" : _.get(payload, "tranDate") + "Z",
        totalAmount: _.get(payload, "amount", "-"),
        bulkAccountNo: _.isEmpty(payload.collectiveContractAccountNo) ? "-" : payload.collectiveContractAccountNo,
        companyName: _.isEmpty(payload.collectiveAccountCompanyName) ? "-" : payload.collectiveAccountCompanyName,
        fpxRequeryLastRunDate: _.isEmpty(payload.fpxRequeryLastRunDate) ? "" : _.get(payload, "fpxRequeryLastRunDate") + "Z",
        totalInvoices: _.get(payload, "totalInvoices", "-"),
        invoiceList: invoiceList,
        isRequeryEnabled: _.get(payload, "isRequeryEnabled", false),
      }
    };
  },
  [paymentActions.PERFORM_FPX_REQUERY]: (state: IPaymentReducer, { payload }: IPayloadObj) => {
    const invoiceList = _.map(_.get(payload, "getFpxRequeryDataInvoiceModelList", []), inv => ({
      billBatchId: inv.collectiveInvoiceNo,
      accountNo: inv.contractAccount,
      invoiceNo: inv.invoiceNo,
      amount: inv.amount,
    }));
    
    return {
      ...state,
      pgSearchResult: {
        ...state.pgSearchResult,
        isQuerying: false,
        merchantTransactionId: payload.merchantTransactionId,
        customerName: _.isEmpty(payload.custName) ? "-" : payload.custName,
        transactionStatus: _.isEmpty(payload.txnStatus) ? "-" : payload.txnStatus,
        errorDescription: _.isEmpty(payload.errDesc) ? "-" : payload.errDesc,
        bankName: _.isEmpty(payload.acquirerBank) ? "-" : payload.acquirerBank,
        bankReference: _.isEmpty(payload.bankReference) ? "-" : payload.bankReference,
        bankTransactionDate: _.isEmpty(payload.bankTranDate) ? "-" : _.get(payload, "bankTranDate") + "Z",
        paymentTransactionDate: _.isEmpty(payload.tranDate) ? "-" : _.get(payload, "tranDate") + "Z",
        totalAmount: _.get(payload, "amount", "-"),
        bulkAccountNo: _.isEmpty(payload.collectiveContractAccountNo) ? "-" : payload.collectiveContractAccountNo,
        companyName: _.isEmpty(payload.collectiveAccountCompanyName) ? "-" : payload.collectiveAccountCompanyName,
        fpxRequeryLastRunDate: _.isEmpty(payload.fpxRequeryLastRunDate) ? "" : _.get(payload, "fpxRequeryLastRunDate") + "Z",
        totalInvoices: _.get(payload, "totalInvoices", "-"),
        invoiceList: invoiceList,
        isRequeryEnabled: _.get(payload, "isRequeryEnabled", false),
      }
    };
  },
  [paymentActions.PERFORM_FPX_REQUERY_FAILED]: (state: IPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      pgSearchResult: {
        ...state.pgSearchResult,
        isQuerying: false,
        requeryErrorMessage: _.get(payload, 'errorMessage')
      }
    };
  },
}

const adminPaymentReducer = (state: IPaymentReducer = defaultState, { type, payload, error }: IReducerAction = { type: '' }) => {
  if (!type)
    return state;

  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
}

export default adminPaymentReducer;
export const REDUCER_BILL_PAYMENT = 'adminPaymentReducer';
