import _ from 'lodash';

const DEFAULT_DECIMAL_PLACE: number = 2;
export enum unitTypes {
  RM = 'rm',
  WHOLE = 'whole',
};
interface IUnitDecimalPlace {
  [key: string]: number
}
const UNIT_DECIMAL_PLACE: IUnitDecimalPlace = {
  'rm': 2,
  'whole': 0,
};
const NO_DATA_SYMBOL = '-';

const isInvalidValue = (value: string | number) => _.isNull(value) || value === NO_DATA_SYMBOL;

const getDecimalPlace = (unit?: unitTypes): number => {
  let lowerUnit = _.toLower(String(unit)).replace(' ', '')
  return _.has(UNIT_DECIMAL_PLACE, lowerUnit)
    ? UNIT_DECIMAL_PLACE[lowerUnit]
    : DEFAULT_DECIMAL_PLACE
};

export const roundingValueToUnit = (value: string | number, unit: unitTypes = unitTypes.RM, presistZero: boolean = false): string => {
  if (isInvalidValue(value)) return NO_DATA_SYMBOL;

  const rounding = getDecimalPlace(unit);
  const stringValue = value + '';
  const roundedValue =
    Math.round(parseFloat((parseFloat(stringValue) * Math.pow(10, rounding)).toFixed(rounding + 2))) / Math.pow(10, rounding)
  return presistZero ? roundedValue.toFixed(rounding) : roundedValue.toString();
};

export const numberWithCommas = (value: string | number, unit: unitTypes = unitTypes.RM, label: string = '', showNegative: boolean = true, negativeFront: boolean = true) => {
  if (isInvalidValue(value)) return NO_DATA_SYMBOL;

  const rounding = getDecimalPlace(unit);
  let roundValue = roundingValueToUnit(value, unit);
  let number = parseFloat(roundValue);

  if (value < 0 && label && negativeFront) {
    label = "-" + label;
    number = number * -1; 
  } else if (value < 0 && !showNegative) {
    number = number * -1;
  }

  return label + number.toLocaleString('en-US', { minimumFractionDigits: rounding });
}

export const twoNumbersFormatter = (value: string | number) => {
  if (isInvalidValue(value)) return NO_DATA_SYMBOL;

  const numberValue = parseInt(value + '');

  if (numberValue < 10) {
    return '0' + numberValue;
  }
  return '' + numberValue;
}
