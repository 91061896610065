import styled from 'styled-components';
import { Spinner } from "react-bootstrap";

import * as theme from 'src/constants/theming';

interface IButton {
  mode: 'primary' | 'secondary' | 'ghost';
  colorTheme?: 'light' | 'dark';
  size?: 'small' | 'large' | 'larger';
  label?: string | JSX.Element;
  customClass?: string[];
  [key: string]: any;
  onClick?: () => void;
  hasLoadingSpinner?: boolean;
  customSpinnerClass?: string[];
  disabled?: boolean;
}

const defaultTheme = 'light';
const Input = styled.button<IButton>`
  width: 100%;
  cursor: pointer;
  font-size: ${props => props.size === 'large' || props.size === 'larger' ? '16px' : '14px'};
  font-weight: 300;
  border: 1px solid ${props => getBorderColor(props.mode, props.colorTheme ?? defaultTheme)};
  border-radius:  ${props => props.size === 'larger' ? '1.5em' : '1.25em'};
  padding: ${props => props.size === 'larger' ? '12px 16px' : '5px 15px'};
  background: ${props => getBackgroundColor(props.mode, props.colorTheme ?? defaultTheme)};
  color: ${props => getFontColor(props.mode, props.colorTheme ?? defaultTheme)};

  &:hover {
    color: ${props => getFontColor(props.mode, props.colorTheme ?? defaultTheme, 'hover')};
    background: ${props => getBackgroundColor(props.mode, props.colorTheme ?? defaultTheme, 'hover')};
    border-color: ${props => getBorderColor(props.mode, props.colorTheme ?? defaultTheme, 'hover')}; 
  }
  
  &:disabled {
    cursor: not-allowed;
    color: ${props => getFontColor(props.mode, props.colorTheme ?? defaultTheme, props.hasLoadingSpinner ? 'default' : 'disabled')};
    background: ${props => getBackgroundColor(props.mode, props.colorTheme ?? defaultTheme, props.hasLoadingSpinner ? 'default' : 'disabled')};
    border-color: ${props => getBorderColor(props.mode, props.colorTheme ?? defaultTheme, props.hasLoadingSpinner ? 'default' : 'disabled')}; 
  }
  .button__spinner {
    vertical-align: middle;
    .spinner-border {
      width: 24px;
      height: 24px;
    }
  }
`;

interface IState {
  [key: string]: string;
  default: string;
  hover: string;
  disabled: string;
}
interface IColorTheme {
  [key: string]: IState;
  light: IState;
  dark: IState;
}
interface IStyleMatrix {
  [key: string]: IColorTheme;
  primary: IColorTheme;
  secondary: IColorTheme;
  ghost: IColorTheme;
}

const getFontColor = (mode: string, colorTheme: string, state = 'default') => {
  const matrix: IStyleMatrix = {
    primary: {
      light: {
        default: theme.defaultTheme['color-neutral8'],
        hover: theme.defaultTheme['color-neutral8'],
        disabled: theme.defaultTheme['color-neutral3']
      },
      dark: {
        default: theme.defaultTheme['color-primary1'],
        hover: theme.defaultTheme['color-neutral8'],
        disabled: theme.defaultTheme['color-neutral3']
      }
    },
    secondary: {
      light: {
        default: theme.defaultTheme['color-primary5'],
        hover: theme.defaultTheme['color-primary4'],
        disabled: theme.defaultTheme['color-neutral5']
      },
      dark: {
        default: theme.defaultTheme['color-neutral8'],
        hover: theme.defaultTheme['color-neutral8'],
        disabled: theme.defaultTheme['color-neutral10']
      }
    },
    ghost: {
      light: {
        default: theme.defaultTheme['color-primary5'],
        hover: theme.defaultTheme['color-primary4'],
        disabled: theme.defaultTheme['color-neutral6']
      },
      dark: {
        default: theme.defaultTheme['color-neutral8'],
        hover: theme.defaultTheme['color-secondary1'],
        disabled: theme.defaultTheme['color-neutral6']
      }
    }
  }

  return matrix[mode][colorTheme][state];
}

const getBorderColor = (mode: string, colorTheme: string, state = 'default') => {
  const matrix: IStyleMatrix = {
    primary: {
      light: {
        default: theme.defaultTheme['color-primary5'],
        hover: theme.defaultTheme['color-primary4'],
        disabled: theme.defaultTheme['color-neutral6']
      },
      dark: {
        default: theme.defaultTheme['color-neutral8'],
        hover: theme.defaultTheme['color-primary5'],
        disabled: theme.defaultTheme['color-neutral6']
      }
    },
    secondary: {
      light: {
        default: theme.defaultTheme['color-primary5'],
        hover: theme.defaultTheme['color-primary4'],
        disabled: theme.defaultTheme['color-neutral5']
      },
      dark: {
        default: theme.defaultTheme['color-neutral8'],
        hover: theme.defaultTheme['color-neutral8'],
        disabled: theme.defaultTheme['color-neutral10']
      }
    },
    ghost: {
      light: {
        default: 'transparent',
        hover: 'transparent',
        disabled: 'transparent'
      },
      dark: {
        default: 'transparent',
        hover: 'transparent',
        disabled: 'transparent'
      }
    }
  }

  return matrix[mode][colorTheme][state];
}

const getBackgroundColor = (mode: string, colorTheme: string, state = 'default') => {
  const matrix: IStyleMatrix = {
    primary: {
      light: {
        default: theme.defaultTheme['color-primary5'],
        hover: theme.defaultTheme['color-primary4'],
        disabled: theme.defaultTheme['color-neutral6']
      },
      dark: {
        default: theme.defaultTheme['color-neutral8'],
        hover: theme.defaultTheme['color-primary5'],
        disabled: theme.defaultTheme['color-neutral6']
      }
    },
    secondary: {
      light: {
        default: 'transparent',
        hover: theme.defaultTheme['color-neutral6'],
        disabled: 'transparent'
      },
      dark: {
        default: 'transparent',
        hover: theme.defaultTheme['color-neutral10'],
        disabled: 'transparent'
      }
    },
    ghost: {
      light: {
        default: 'transparent',
        hover: 'transparent',
        disabled: 'transparent'
      },
      dark: {
        default: 'transparent',
        hover: 'transparent',
        disabled: 'transparent'
      }
    }
  }

  return matrix[mode][colorTheme][state];
}

export const Button = ({
  mode = 'primary',
  colorTheme = 'light',
  size = 'large',
  label,
  customClass = [],
  hasLoadingSpinner = false,
  customSpinnerClass = [],
  disabled,
  ...props
}: IButton) => {
  return (
    <Input
      type="button"
      size={size}
      hasLoadingSpinner={hasLoadingSpinner}
      mode={mode}
      colorTheme={colorTheme}
      className={`flex center justify-center ${['button', ...customClass].join(' ')}`}
      {...props}
      disabled={disabled || hasLoadingSpinner}
    >
      {hasLoadingSpinner && <span className={['pad-left-0 pad-right-1 text-align__right button__spinner', ...customSpinnerClass].join(' ')}><Spinner animation="border" /></span>}
      {label}
    </Input>
  );
};
