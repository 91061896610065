import { Dispatch } from "redux";

import * as UserService from "src/services/userService";
import { fetchStart, fetchSuccess, fetchError } from "./fetchAction";

export const ACTION_NAME: string = "USER_";

export const GET_MANAGE_USERS_CARDS = `${ACTION_NAME}GET_MANAGE_USERS_CARDS`;
export const getManageUsersCards = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_MANAGE_USERS_CARDS))
    UserService.getManageUsersCards(bulkId)
      .then(response =>
        dispatch({
          type: GET_MANAGE_USERS_CARDS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_MANAGE_USERS_CARDS)))
      .catch(() => dispatch(fetchError(GET_MANAGE_USERS_CARDS)));
  };
};

export const GET_MANAGEUSERS = `${ACTION_NAME}GET_MANAGEUSERS`;
export const getManageUsers = (recordCount: number, bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_MANAGEUSERS))
    UserService.getManageUsers(recordCount, bulkId)
      .then(response =>
        dispatch({
          type: GET_MANAGEUSERS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_MANAGEUSERS)))
      .catch(() => dispatch(fetchError(GET_MANAGEUSERS)));
  };
};

export const ADD_NEW_USER_FAILED = `${ACTION_NAME}ADD_NEW_USER_FAILED`;
export const ADD_NEW_USER = `${ACTION_NAME}ADD_NEW_USER`;
export const addNewUser = (newUser:object) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(ADD_NEW_USER))
    UserService.addNewUser(newUser)
      .then(response =>
        dispatch({
          type: ADD_NEW_USER,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(ADD_NEW_USER)))
      .catch((response) => {
        dispatch({
          type: ADD_NEW_USER_FAILED,
          payload: response,
        });
        dispatch(fetchError(ADD_NEW_USER));
      });
  };
};

export const SET_ADD_USER_ERROR = `${ACTION_NAME}SET_ADD_USER_ERROR`;
export const setAddUserError = (errorTopic: string, errorMessage: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_ADD_USER_ERROR,
      payload: {
        errorTopic,
        errorMessage,
      }
    });
  };
};

export const UPDATE_BULK_USER_FAILED = `${ACTION_NAME}UPDATE_BULK_USER_FAILED`;
export const UPDATE_BULK_USER = `${ACTION_NAME}UPDATE_BULK_USER`;
export const updateBulkUser = (userId: string, nickname: string, role: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(UPDATE_BULK_USER))
    UserService.updateBulkUser(userId, nickname, role)
      .then(response =>
        dispatch({
          type: UPDATE_BULK_USER,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(UPDATE_BULK_USER)))
      .catch((response) => {
        dispatch({
          type: UPDATE_BULK_USER_FAILED,
          payload: response,
        });
        dispatch(fetchError(UPDATE_BULK_USER));
      });
  };
};

export const DELETE_BULK_USER_FAILED = `${ACTION_NAME}DELETE_BULK_USER_FAILED`;
export const DELETE_BULK_USER = `${ACTION_NAME}DELETE_BULK_USER`;
export const deleteBulkUser = (userId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(DELETE_BULK_USER))
    UserService.deleteBulkUser(userId)
      .then(response =>
        dispatch({
          type: DELETE_BULK_USER,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_BULK_USER)))
      .catch((response) => {
        dispatch({
          type: DELETE_BULK_USER_FAILED,
          payload: response,
        });
        dispatch(fetchError(DELETE_BULK_USER));
      });
  };
};

export const TRANSFER_MASTER_ROLE_FAILED = `${ACTION_NAME}TRANSFER_MASTER_ROLE_FAILED`;
export const TRANSFER_MASTER_ROLE = `${ACTION_NAME}TRANSFER_MASTER_ROLE`;
export const transferMasterRole = (userId: string, nickname: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(TRANSFER_MASTER_ROLE))
    UserService.transferMasterRole(userId, nickname)
      .then(response =>
        dispatch({
          type: TRANSFER_MASTER_ROLE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(TRANSFER_MASTER_ROLE)))
      .catch((response) => {
        dispatch({
          type: TRANSFER_MASTER_ROLE_FAILED,
          payload: response,
        });
        dispatch(fetchError(TRANSFER_MASTER_ROLE));
      });
  };
};
