import { Dispatch } from "redux";

import * as HomeService from "src/services/homeService";
import { fetchStart, fetchSuccess, fetchError } from "./fetchAction";

export const ACTION_NAME: string = "HOME_";

export const GET_TOP_3_CARDS = `${ACTION_NAME}GET_TOP_3_CARDS`;
export const getTop3Cards = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_TOP_3_CARDS));
    HomeService.getTop3Cards(bulkId)
      .then((response) =>
        dispatch({
          type: GET_TOP_3_CARDS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_TOP_3_CARDS)))
      .catch(() => dispatch(fetchError(GET_TOP_3_CARDS)));
  };
};

export const GET_BILL_DATA_FOR_GRAPH = `${ACTION_NAME}GET_BILL_DATA_FOR_GRAPH`;
export const getBillDataForGraph = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_BILL_DATA_FOR_GRAPH));
    HomeService.getBillDataForGraph(bulkId)
      .then((response) =>
        dispatch({
          type: GET_BILL_DATA_FOR_GRAPH,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_BILL_DATA_FOR_GRAPH)))
      .catch(() => dispatch(fetchError(GET_BILL_DATA_FOR_GRAPH)));
  };
};

export const GET_PAYMENT_DATA_FOR_GRAPH = `${ACTION_NAME}GET_PAYMENT_DATA_FOR_GRAPH`;
export const getPaymentDataForGraph = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PAYMENT_DATA_FOR_GRAPH));
    HomeService.getPaymentDataForGraph(bulkId)
      .then((response) =>
        dispatch({
          type: GET_PAYMENT_DATA_FOR_GRAPH,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_PAYMENT_DATA_FOR_GRAPH)))
      .catch(() => dispatch(fetchError(GET_PAYMENT_DATA_FOR_GRAPH)));
  };
};

export const GET_LATEST_BILL_SUMMARY = `${ACTION_NAME}GET_LATEST_BILL_SUMMARY`;
export const getLatestBillSummary = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_LATEST_BILL_SUMMARY));
    HomeService.getLatestBillSummary(bulkId)
      .then((response) =>
        dispatch({
          type: GET_LATEST_BILL_SUMMARY,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_LATEST_BILL_SUMMARY)))
      .catch(() => dispatch(fetchError(GET_LATEST_BILL_SUMMARY)));
  };
};

export const GET_ACCOUNT_CARDS = `${ACTION_NAME}GET_ACCOUNT_CARDS`;
export const getAccountCards = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ACCOUNT_CARDS));
    HomeService.getAccountCards(bulkId)
      .then((response) =>
        dispatch({
          type: GET_ACCOUNT_CARDS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_ACCOUNT_CARDS)))
      .catch(() => dispatch(fetchError(GET_ACCOUNT_CARDS)));
  };
};

export const SET_GUIDANCESTEP = `${ACTION_NAME}SET_GUIDANCESTEP`;
export const setGuidanceStep = (id: string, isIncrease: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: `${ACTION_NAME}SET_GUIDANCESTEP`,
      payload: {
        id,
        isIncrease
      }
    });
  }
};
export const resetGuidanceStep = (id: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: `${ACTION_NAME}SET_GUIDANCESTEP`,
      payload: {
        id,
        isReset: true
      }
    });
  }
};