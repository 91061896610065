import _ from 'lodash';
import Cookies from 'js-cookie';
import { createBrowserHistory } from "history";

import * as sessionActions from '../actions/sessionAction';
import { IReducerAction, IReducer, IPayloadObj } from './_commonInterface';
//Constants
import { ACCESS_ROLES } from 'src/constants/userRoles';
import { AUTH_COOKIE_DOMAIN, AUTH_COOKIE_DOMAIN_SECURE } from 'src/constants/envVariables';

//#region Interfaces
interface ISessionReducer {
  id: string | null,
  userEmail: string | null,
  accessToken: string | null,
  refreshToken: string | null,
  isAdmin: boolean,
  userRolesForScope: string[] | null,
  loginAttemptCounter: number | null,
  isUserPasswordLocked: boolean,
  isNonActivatedUser: boolean,
  isTokenGetThroughLogin: boolean,
  afterLoginCookieToGenerate?: ICookieToGenerate[],
  isPasswordResetRequired: boolean,
}

interface ICookieToGenerate {
  name: string,
  value: string,
}

//#endregion

//#region Private

const storeTokens = (jwtToken: string, refreshToken: string): void => {
  const cookieDomain = _.isEmpty(AUTH_COOKIE_DOMAIN) ? undefined : AUTH_COOKIE_DOMAIN;
  clearTokens();
  Cookies.set('access_token', jwtToken, { expires: 1, domain: cookieDomain, secure: AUTH_COOKIE_DOMAIN_SECURE });
  Cookies.set('refresh_token', refreshToken, { expires: 1, domain: cookieDomain, secure: AUTH_COOKIE_DOMAIN_SECURE });
}
const clearTokens = (): void => {
  const cookieDomain = _.isEmpty(AUTH_COOKIE_DOMAIN) ? undefined : AUTH_COOKIE_DOMAIN;
  Cookies.remove('access_token', { expires: 1, domain: cookieDomain });
  Cookies.remove('refresh_token', { expires: 1, domain: cookieDomain });
}
// const storeCookies = (cookieToGenerate?: ICookieToGenerate[]): void => {
//   if (!cookieToGenerate) return;

//   const cookieDomain = _.isEmpty(AUTH_COOKIE_DOMAIN) ? undefined : AUTH_COOKIE_DOMAIN;
//   _.each(cookieToGenerate, (item: ICookieToGenerate) => {
//     Cookies.set(item.name, item.value, { expires: 1, domain: cookieDomain, secure: AUTH_COOKIE_DOMAIN_SECURE });
//   });
// }
const clearCookies = (cookieToGenerate?: ICookieToGenerate[]): void => {
  if (!cookieToGenerate) return;

  const cookieDomain = _.isEmpty(AUTH_COOKIE_DOMAIN) ? undefined : AUTH_COOKIE_DOMAIN;
  _.each(cookieToGenerate, (item: ICookieToGenerate) => {
    Cookies.remove(item.name, { expires: 1, domain: cookieDomain });
  });
}

//#endregion

const defaultState: ISessionReducer = {
  id: '',
  userEmail: '',
  accessToken: Cookies.get('access_token') || null,
  refreshToken: Cookies.get('refresh_token') || null,
  isAdmin: false,
  userRolesForScope: [],
  loginAttemptCounter: null,
  isUserPasswordLocked: false,
  isNonActivatedUser: false,
  isTokenGetThroughLogin: false,
  afterLoginCookieToGenerate: undefined,
  isPasswordResetRequired: false,
}

const REDUCERS: IReducer = {
  [sessionActions.LOGIN_SESSION_RESET]: (state: ISessionReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      isUserPasswordLocked: false,
    };
  },
  [sessionActions.LOGIN_SESSION]: (state: ISessionReducer, { payload }: IPayloadObj) => {
    const { jwtToken, refreshToken, isPasswordResetRequired } = payload;
    // const { jwtToken, refreshToken, cookieToGenerate, isPasswordResetRequired } = payload;
    storeTokens(jwtToken, refreshToken);
    // storeCookies(cookieToGenerate);
    return {
      ...state,
      accessToken: jwtToken,
      refreshToken: refreshToken,
      loginAttemptCounter: null,
      // afterLoginCookieToGenerate: cookieToGenerate,
      isTokenGetThroughLogin: true,
      isPasswordResetRequired,
    };
  },
  [sessionActions.LOGIN_SESSION_FAILED]: (state: ISessionReducer, { payload }: IPayloadObj) => {
    const { isUserPasswordLocked, isNonActivatedUser, attempt } = payload;
    // hotfix
    // const newLoginAttemptCount = 1;
    return {
      ...state,
      loginAttemptCounter: attempt ?? null,
      isUserPasswordLocked: isUserPasswordLocked,
      isNonActivatedUser: isNonActivatedUser,
    };
  },
  [sessionActions.LOGOUT_SESSION]: (state: ISessionReducer, { payload }: IPayloadObj) => {
    const { shouldRedirect } = payload;
    const { afterLoginCookieToGenerate } = state;
    localStorage.clear();
    clearTokens();
    clearCookies(afterLoginCookieToGenerate);
    if (shouldRedirect) {
      const currentPathname = _.get(document, 'location.pathname');
      const isAdminRoute = _.startsWith(currentPathname, '/admin');
      let history = createBrowserHistory();
      history.replace(isAdminRoute ? '/admin/login' : '/');
      window.location.reload();
    }
    return {
      ...state,
      accessToken: null,
      refreshToken: null,
      loginAttemptCounter: null,
      afterLoginCookieToGenerate: undefined,
    };
  },
  [sessionActions.SET_USER_ROLES]: (state: ISessionReducer, { payload }: IPayloadObj) => {
    let userRolesForScope = null;
    if (payload.scopes) {
      userRolesForScope = payload.scopes;
    } else if (payload.registrationStatusId === 999) {
      // user registered completely, but without bulkId
      userRolesForScope = ACCESS_ROLES.USER.OVERALL_VIEW;
    }
    return {
      ...state,
      userRolesForScope: userRolesForScope,
      isAdmin: !!_.intersection(ACCESS_ROLES.ADMIN.OVERALL_VIEW, payload.scopes).length
    }
  },
  [sessionActions.SET_IS_PASSWORD_RESET_REQUIRED]: (state: ISessionReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      isPasswordResetRequired: payload,
    }
  },
  [sessionActions.REFRESH_SESSION]: (state: ISessionReducer, { payload }: IPayloadObj) => {
    const { jwtToken, refreshToken } = payload;
    storeTokens(jwtToken, refreshToken);
    return {
      ...state,
      accessToken: jwtToken,
      refreshToken: refreshToken,
    };
  },
  [sessionActions.CLEAR_SESSION]: (state: ISessionReducer, { payload }: IPayloadObj) => {
    const { afterLoginCookieToGenerate } = state;
    localStorage.clear();
    clearTokens();
    clearCookies(afterLoginCookieToGenerate);
    return {
      ...state,
      accessToken: null,
      refreshToken: null,
      afterLoginCookieToGenerate: undefined,
      isAdmin: false,
      userRolesForScope: [],
    };
  },
}

const sessionReducer = (state: ISessionReducer = defaultState, { type, payload, error }: IReducerAction = { type: '' }) => {
  if (!type)
    return state;

  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
}
export default sessionReducer;
export const REDUCER_ACOUNT = 'sessionReducer';