import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reducers from './reducers';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancers = compose;
const createStoreWithMiddleware = composeEnhancers(composeWithDevTools(applyMiddleware(promise, thunk))(createStore));
export const store = createStoreWithMiddleware(reducers)

interface IReduxProvider {
  children: React.ReactNode
}

export const ReduxProvider = ({
  children
}: IReduxProvider) => {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}
