import _ from 'lodash';
import { FETCH_START, FETCH_SUCCESS, FETCH_ERROR, FETCH_RESET } from '../actions/fetchAction';

interface IFetchReducer {
  [key: string]: string,
};

interface IReducerAction {
  type: string | null,
  payload?: any,
  error?: string,
}
interface IFetchStateProps {
  state: IFetchReducer,
  id: string,
}

const defaultState = {};

export const isFetching = ({ state = defaultState, id }: IFetchStateProps): boolean => {
  return state[id] === FETCH_START;
};
export const isSuccess = ({ state = defaultState, id }: IFetchStateProps): boolean => {
  return state[id] === FETCH_SUCCESS;
};
export const isError = ({ state = defaultState, id }: IFetchStateProps): boolean => {
  return state[id] === FETCH_ERROR;
};

const fetchReducer = (state: IFetchReducer = defaultState, { type, payload }: IReducerAction): object => {
  switch (type) {
    case FETCH_START:
    case FETCH_SUCCESS:
    case FETCH_ERROR:
      return {
        ...state,
        [payload]: type
      };
    case FETCH_RESET:
      return _.has(state, payload)
        ? {
          ...state,
          [payload]: undefined
        }
        : state;
  }
  return state;
};

export default fetchReducer;
