import { combineReducers } from 'redux'

//Page Reducer
import fetchReducer from './fetchReducer';
import sessionReducer from './sessionReducer';
import accountReducer from './accountReducer';
import homeReducer from './homeReducer';
import billPaymentReducer from './billPaymentReducer';
import userReducer from './userReducer';
import logReducer from './logReducer';

// Admin Reducer
import adminAccountReducer from './admin/accountReducer';
import adminPaymentReducer from './admin/paymentReducer';
import adminUserReducer from './admin/userReducer';

const rootReducer = combineReducers({
  fetchReducer,
  accountReducer,
  sessionReducer,
  homeReducer,
  billPaymentReducer,
  userReducer,
  logReducer,
  adminAccountReducer,
  adminPaymentReducer,
  adminUserReducer,
})

export default rootReducer
